import { ValidationErrors } from '@angular/forms';
import { AppConstants } from '@config/app.constant';

export class FormatError {
  constructor(
    private isValid: boolean,
    private controlName: string,
    private translationKey: string
  ) {}

  getError(): ValidationErrors {
    return this.isValid
      ? null
      : {
          [AppConstants.inputFieldFormatKey]: {
            [this.controlName]: this.translationKey,
          },
        };
  }
}

export const partnerFiltersKeys = {
  name: 'name',
  taxNumber: 'tax_number',
  projectOffices: 'project_offices',
};

export const candidateFilterKeys = {
  cvContent: 'cv_content',
  cityDistance: 'city_distance',
};

<div class="filter-cards-wrapper">
    <div class="d-flex justify-content-between mt-5px mb-5px">
        <p class="basic-white-bold-text">{{'side_filter.filter_title' | translate}}</p>
        <p class="delete-text basic-white-bold-text"
            (click)="resetFilter.emit()">{{'side_filter.delete_button' | translate}}
            <span *ngIf="numberOfActiveFilter > 0">({{numberOfActiveFilter}})</span>
        </p>
    </div>

    <div *ngFor="let filter of transformedFilters" [ngSwitch]="filter.data">
        <app-date-filtering
            *ngSwitchCase="filterTypes.DatePicker"
            [title]="translateKey + filter.translateKey"
            [filterKey]="filter.filterKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-date-filtering>

        <app-truefalse-filtering
            *ngSwitchCase="filterTypes.TrueFalse"
            [title]="translateKey + filter.translateKey"
            [filterKey]="filter.filterKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-truefalse-filtering>

        <app-card-filtering
            *ngSwitchCase="filterTypes.InputField"
            [title]="translateKey + filter.translateKey"
            [filterKey]="filter.filterKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-card-filtering>

        <app-dropdown-filtering
            *ngSwitchDefault
            [title]="translateKey + filter.translateKey"
            [filterKey]="filter.filterKey"
            [data]="filter.data"
            [singleDropdown]="filter.singleDropdown || false"
            [withInteger]="filter.withInteger || false"
            [withDate]="filter.withDate || false"
            (filterValueChange)="setFilter.emit($event)">
        </app-dropdown-filtering>
    </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomDirectivesModule } from '@shared/modules/custom-directives/custom-directives.module';
import { SharedModule } from '@shared/shared.module';
import { SwitchModule } from '@shared/modules/switch/switch.module';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { TippyModule } from '@ngneat/helipopper';
import { InlineSVGModule } from 'ng-inline-svg';
import { HintModule } from '@shared/modules/hint/hint.module';
import { TableComponent } from './components/table/table.component';
import { SmallImageDatatableCellComponent } from './components/small-image-table-cell/small-image-datatable-cell.component';
import { CustomCellDirective } from './directives/custom-cell.directive';
import { CheckboxTableCellComponent } from './components/checkbox-table-cell/checkbox-table-cell.component';
import { CheckboxTableHeaderComponent } from './components/checkbox-table-header/checkbox-table-header.component';
import { CustomHeaderDirective } from './directives/custom-header.directive';
import { SwitchTableCellComponent } from './components/switch-table-cell/switch-table-cell.component';
import { MultiplicityTableCellComponent } from './components/multiplicity-table-cell/multiplicity-table-cell.component';
import { TooltipTableCellComponent } from './components/tooltip-table-cell/tooltip-table-cell.component';
import { IconTableCellComponent } from './components/icon-table-cell/icon-table-cell.component';
import { IconTableCellInsideComponent } from './components/icon-table-cell/icon-table-cell-inside/icon-table-cell-inside.component';
import { DataTableRowListenerDirective } from './directives/data-table-row-listener.directive';
import { OpenInNewTabDirective } from './directives/open-in-new-tab.directive';

@NgModule({
  declarations: [
    TableComponent,
    SmallImageDatatableCellComponent,
    CustomCellDirective,
    CheckboxTableCellComponent,
    CheckboxTableHeaderComponent,
    CustomHeaderDirective,
    SwitchTableCellComponent,
    MultiplicityTableCellComponent,
    TooltipTableCellComponent,
    IconTableCellComponent,
    IconTableCellInsideComponent,
    DataTableRowListenerDirective,
    OpenInNewTabDirective,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    CustomDirectivesModule,
    SharedModule,
    SwitchModule,
    CustomPipesModule,
    TippyModule,
    InlineSVGModule,
    HintModule,
  ],
  exports: [
    TableComponent,
    SmallImageDatatableCellComponent,
    CustomCellDirective,
    CheckboxTableCellComponent,
    CheckboxTableHeaderComponent,
    CustomHeaderDirective,
    SwitchTableCellComponent,
    MultiplicityTableCellComponent,
    TooltipTableCellComponent,
    IconTableCellComponent,
    OpenInNewTabDirective,
  ],
})
export class TableModule {}

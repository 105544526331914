export const candidateActionTypes = {
  editCandidate: '[CandidateListItem detail] - edit candidate',
  navigateBack: '[CandidateListItem header] - navigate back to candidate list',
  openDocumentModal: '[CandidateListItem detail] - open document upload modal',
  saveDocument: '[Document] - save/upload document',
  updateDocumentList: '[CandidateListItem detail] - update/refresh document list',
  updateDetail: '[CandidateListItem detail] - update/refresh candidate details',
  candidateUpdated: '[Candidate Create Update Modal] - create/update candidate',
  bulkEditCandidate: '[Candidate bulk edit] - candidate bulk edit',
  openPositionAttachModal: '[Candidate detail] - open modal to attach a position to a candidate',
  attachToPosition: '[Candidate detail] - attach a position to a candidate',
  detachPosition: '[Candidate detail] - detach a position from candidate',
  refreshPositionList: '[Candidate detail] - refresh position list',
  updatePositionStatus: '[Candidate detail] - update position status',
  rollbackPositionStatus: '[Candidate detail] - rollback position status',
  positionUpdateSuccess: '[Candidate detail] - position update was successful',
  refreshHistoryPage: '[Candidate detail] - refresh history page',
  requestAttachedPositions:
    '[Candidate detail] - request candidates position list in document upload modal',
  hideGDPRSumIcons: '[Candidate detail] - hide GDPR sum icons',
};

import { camelCase, includes, isPlainObject, keys, uniq } from 'lodash-es';

export const commonErrors = {
  parentIsInactive: 'validation_errors.parent_is_inactive_error',
  projectIsInactive: 'validation_errors.parent_is_inactive_error',
  incorrectDate: 'validation_errors.incorrect_date',
  imageSizeInvalid: 'validation_errors.image_size_invalid',
  couldNotDeleteBecauseHasPosition: 'validation_errors.could_not_delete_because_has_position',
  providerTypeAutomataExists: 'validation_errors.provider_type_automata_exists',
  positionInactive: 'validation_errors.position_inactive',
  cantParseDocument: 'validation_errors.cant_parse_document',
  cantSaveParsedDocument: 'validation_errors.cant_save_parsed_document',
  filterInputInvalid: 'validation_errors.filter_input_invalid',
  filterCvInputInvalid: 'validation_errors.filter_cv_input_invalid',
  baseTermExists: 'validation_errors.base_term_exists',
};

function traverseObject(object: any, accumulatedKeys: string[]): void {
  keys(object).forEach((k) => {
    accumulatedKeys.push(k);
    if (typeof object[k] === 'object') {
      traverseObject(object[k], accumulatedKeys);
    }
  });
}

export function getErrorTranslationKey(
  errors: string[],
  fallbackErrorMessage = 'validation_errors.general_error'
): string | undefined {
  let errorsArray = errors ?? [];

  if (typeof errors === 'string') {
    errorsArray = [errors];
  }

  const accumulatedKeys = [];
  if (isPlainObject(errors)) {
    traverseObject(errors, accumulatedKeys);
    errorsArray = accumulatedKeys;
  }

  errorsArray = uniq(errorsArray).map((key) => camelCase(key));

  const foundError = keys(commonErrors).find((key) => includes(errorsArray, key));

  if (!foundError && typeof fallbackErrorMessage === 'string') {
    return fallbackErrorMessage;
  }

  return commonErrors[foundError];
}

export function hasCommonError(errorKeys: string[] | string) {
  const errors: string[] = (Array.isArray(errorKeys) ? errorKeys : [errorKeys])
    .map((error: string) => camelCase(error))
    .filter((key: string) => typeof key === 'string');

  return keys(commonErrors).some((key: string) => includes(errors, key));
}

import { Component, OnInit, Optional, Self } from '@angular/core';
import { KeyValue } from '@shared/classes/KeyValue';
import { NgControl } from '@angular/forms';
import { FormInputBaseComponent } from '@shared/modules/text-field/form-input-base.component';

@Component({
  selector: 'app-yes-no-select',
  templateUrl: './yes-no-select.component.html',
  styleUrls: ['./yes-no-select.component.scss'],
})
export class YesNoSelectComponent extends FormInputBaseComponent implements OnInit {
  options: KeyValue<boolean>[] = [];

  constructor(@Self() @Optional() private ngControl: NgControl) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.options = [
      {
        key: this.translate.instant('common.yes'),
        value: true,
      },
      {
        key: this.translate.instant('common.no'),
        value: false,
      },
    ];
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }
}

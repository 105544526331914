<div class="card-filtering-container">
    <div class="d-flex align-items-center">
        <div class="d-inline-flex">
            <app-checkbox
                checkboxColor="red"
                [formControl]="checkBox"
                [checked]="isChecked"
                [isDisabled]="isDisabled"
                (isChecked)="onCheckboxChanged($event)">
            </app-checkbox>
            <p class="card-filter-title">{{title | translate}}</p>
        </div>
    </div>

    <div [ngStyle]="{'display': isOpen ? 'block' : 'none'}">
        <app-select
          #dropDown
            placeholder=""
            [options]="transformedData"
            [customClass]="['custom-ng-select', 'ng-select-without-tags']"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="withInteger"
            [bindLabel]="'name'"
            [bindValue]="''"
            [searchable]="true"
            [virtualScroll]="true"
            [searchFn]="customSearchFn"
            [formControl]="selectedItems">
            <app-card-filtering-label #labelTemplate></app-card-filtering-label>
            <app-card-filtering-option #optionsTemplate></app-card-filtering-option>
        </app-select>
    </div>

    <div class="filter-tags-wrapper">
        <div *ngFor="let selectedItem of selectedItem | async">
            <app-filter-tag
              #filterTag
                [id]="selectedItem.id"
                [value]="selectedItem.name"
                (deleteItem)="deleteItem($event)">
            </app-filter-tag>
        </div>
    </div>

    <div *ngIf="withDate">
        <app-date-interval-picker
            [hintText]="hintText"
            [isOpen]="isOpen"
            [dateIntervalPicker]="dateIntervalPicker">
        </app-date-interval-picker>
    </div>

    <div *ngIf="withInteger">
        <app-distance-range-filter
            (isChecked)="isChecked = $event"
            (resetCity)="onResetCity()"
            [data]="transformedData"
            [isOpen]="isOpen"
            [filterKey]="filterKey"
            [valueEmitter]="outsideEmitter"
            [distanceRangeGroup]="distanceRangeGroup">
        </app-distance-range-filter>
    </div>
</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderResolver } from '@shared/modules/header/resolvers/header.resolver';
import { InlineSVGModule } from 'ng-inline-svg';
import { CustomDirectivesModule } from '@shared/modules/custom-directives/custom-directives.module';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { TippyModule } from '@ngneat/helipopper';
import { HintModule } from '@shared/modules/hint/hint.module';
import { HeaderComponent } from './header.component';
import { SharedModule } from '../../shared.module';
import { InjectHeaderDirective } from './directives/inject-header.directive';
import { MainPageHeaderComponent } from './components/main-page-header/main-page-header.component';
import { PartnerDetailHeaderComponent } from './components/partner-detail-header/partner-detail-header.component';
import { CandidateDetailHeaderComponent } from './components/candidate-detail-header/candidate-detail-header.component';
import { PositionDetailHeaderComponent } from './components/position-detail-header/position-detail-header.component';
import { UserProfileHeaderComponent } from './components/user-profile-header/user-profile-header.component';
import { NotificationHeaderComponent } from './components/notification-header/notification-header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    InjectHeaderDirective,
    MainPageHeaderComponent,
    PartnerDetailHeaderComponent,
    CandidateDetailHeaderComponent,
    PositionDetailHeaderComponent,
    UserProfileHeaderComponent,
    NotificationHeaderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    InlineSVGModule,
    CustomDirectivesModule,
    CustomPipesModule,
    TippyModule,
    HintModule,
  ],
  exports: [HeaderComponent, CandidateDetailHeaderComponent],
  providers: [HeaderResolver],
})
export class HeaderModule {}

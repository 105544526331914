<button
  [ngClass]="{
   'transparent': isTransparent
   }"
  type="button"
  class="d-flex justify-content-center align-items-center p-0 m-0"
  (click)="buttonClick.emit()"
  [disabled]="disabled"
  [inlineSVG]="iconUrl">
</button>

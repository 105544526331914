import { Injectable, Injector } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  candidateInitialState,
  CandidateState,
} from '@shared/modules/event-bus/state/candidates/candidate.state';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { CandidateListItem } from '@pages/candidates/classes/CandidateListItem';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';
import filterActions from '@shared/modules/filtering/actions/filter.actions';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ComponentType } from '@angular/cdk/overlay';
import { merge, Observable, of, throwError } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { headerActionTypes } from '@shared/modules/header/actions/header.action-types';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mergeAll,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppConstants } from '@config/app.constant';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateApiService } from '@pages/candidates/services/candidate-api.service';
import { CandidateDto } from '@pages/candidates/classes/CandidateDto';
import { candidateActionTypes } from '@pages/candidates/actions/candidate.action-types';
import candidateActions from '@pages/candidates/actions/candidate.actions';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { CandidateDocument } from '@pages/candidates/classes/CandidateDocument';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { HistoryMessageService } from '@shared/modules/history-message/services/history-message.service';
import { historyActionTypes } from '@shared/modules/history-message/actions/history-message.action-types';
import { createEmptyListData, ListData } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { uniqBy } from 'lodash-es';
import historyMessageActions from '@shared/modules/history-message/actions/history-message.actions';
import { getStringId } from '@shared/utils/get-string-id.util';
import {
  addValidationErrorsToForm,
  parseBackendValidationErrors,
} from '@shared/utils/validaiton-error-parser.util';
import { FormGroup } from '@angular/forms';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { TableService } from '@shared/services/table.service';
import { ExportModalComponent } from '@shared/modules/mat-modal/components/export-modal/export-modal.component';
import { hasCommonError } from '@shared/utils/error-keys.util';
import { CandidatePositionMinimal } from '@pages/candidates/classes/CandidatePositionMinimal';

@Injectable({
  providedIn: 'root',
})
export class CandidatesService extends EventBusService<CandidateState> {
  historyDeleteModalId = getStringId();
  private readonly tableService: TableService;
  public readonly modalStateService: ModalStateService;
  public readonly modalService: MatModalService;

  constructor(
    public readonly candidateApiService: CandidateApiService,
    public readonly toast: ToastService,
    private filteringService: FilteringService,
    private headerService: HeaderService,
    private historyMessageService: HistoryMessageService,
    private injector: Injector
  ) {
    super(candidateInitialState);
    this.modalStateService = this.injector.get<ModalStateService>(ModalStateService);
    this.modalService = this.injector.get<MatModalService>(MatModalService);
    this.tableService = this.injector.get<TableService>(TableService);
  }

  manageCandidates(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    const headerSource$ = this.headerService.eventBus.on(headerActionTypes.newEntityButtonClick);
    const detailSource$ = this.eventBus.on(candidateActionTypes.editCandidate);

    return merge(of(headerSource$, detailSource$)).pipe(
      mergeAll(),
      switchMap(() =>
        this.modalService.openDialog({
          width: AppConstants.fullHeightModalWidth,
          data: {
            contentComponent,
          },
        })
      )
    );
  }

  getEncodedFilter(): string | undefined {
    const candidateFilters = this.getStateSnapshot().candidateFilters;
    const sortedFilters = candidateFilters ? removeBlankAttributes(candidateFilters) : {};
    let encodedFilters: string | undefined;
    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }
    return encodedFilters;
  }

  getCandidates(
    page: string,
    perPage: string,
    base64EncodedFilter?: string
  ): Observable<ListData<CandidateListItem>> {
    const encodedFilters = base64EncodedFilter ?? this.getEncodedFilter();

    return this.candidateApiService.getCandidates(page, perPage, encodedFilters).pipe(
      catchError(() => {
        return of(createEmptyListData<CandidateListItem>());
      })
    );
  }

  getCandidatesFilters(): Observable<CardFilterInterface[]> {
    return this.candidateApiService.getCandidateFilters().pipe(
      map((res: any) => {
        return Object.keys(res).map((key) => res[key]);
      })
    );
  }

  openDocumentUpload(
    candidateId: number,
    contentComponent: ComponentType<any>
  ): Observable<CandidateDocument | HttpErrorResponse> {
    return this.eventBus.on(candidateActionTypes.openDocumentModal).pipe(
      tap(() => {
        this.modalStateService.setState({
          iconUrl: 'assets/image/upload-document.svg',
          modalTitle: 'candidates.upload_document',
          isModalDataLoading: false,
        });

        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });
      }),
      switchMap(() => {
        return this.eventBus.on<FormData>(candidateActionTypes.saveDocument);
      }),
      filter((formData) => !!formData),
      tap(() => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
      }),
      switchMap((formData: FormData) => {
        return this.candidateApiService.uploadDocument(candidateId, formData).pipe(
          tap(() => {
            this.toast.showSuccess(getGeneralMessage('candidates.upload_document', true));
            this.eventBus.dispatch(candidateActions.updateDetail());
            this.eventBus.dispatch(candidateActions.updateDocumentList());
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          }),
          catchError((err: HttpErrorResponse) => {
            if (!hasCommonError(err?.error?.errors)) {
              this.toast.showError(getGeneralMessage('candidates.upload_document', false));
            }
            return of(err);
          }),
          finalize(() => this.modalStateService.setState({ isSaveButtonDisabled: false }))
        );
      })
    );
  }

  updateDocumentList(candidateId: number): Observable<CandidateDocument[]> {
    return this.eventBus.on(candidateActionTypes.updateDocumentList).pipe(
      switchMap(() => {
        return this.candidateApiService
          .getDocuments(candidateId)
          .pipe(catchError((err) => throwError(err)));
      })
    );
  }

  createCandidate(
    candidateDto: CandidateDto,
    form: FormGroup
  ): Observable<CandidateDetail | HttpErrorResponse> {
    return this.candidateApiService
      .createCandidate(candidateDto)
      .pipe(this.handleCandidateSaveSideEffects(form, 'candidates.create_candidate'));
  }

  updateCandidate(
    candidateDto: CandidateDto,
    form: FormGroup
  ): Observable<CandidateDetail | HttpErrorResponse> {
    return this.candidateApiService.updateCandidate(candidateDto).pipe(
      tap((candidateDetail: CandidateDetail) => {
        this.eventBus.dispatch(candidateActions.updateDetail(candidateDetail));
      }),
      this.handleCandidateSaveSideEffects(form, 'candidates.modify_candidate')
    );
  }

  createHistory(candidateId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.eventBus.on(historyActionTypes.saveNewHistory).pipe(
      tap(() => {
        this.historyMessageService.setState({ isSaveButtonDisabled: true });
      }),
      switchMap((message: string) => {
        return this.candidateApiService.createHistoryMessage(candidateId, message).pipe(
          tap(() => {
            this.toast.showSuccess(getGeneralMessage('candidates.create_message', true));
            this.historyMessageService.eventBus.dispatch(historyMessageActions.clearInput());
          }),
          concatMap(() => this.getFirstHistoryPage(candidateId)),
          catchError((err) => {
            this.toast.showSuccess(getGeneralMessage('candidates.create_message', false));
            return of(err);
          }),
          finalize(() => {
            this.historyMessageService.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  updateHistory(candidateId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.eventBus.on(historyActionTypes.saveEditedHistory).pipe(
      tap(() => {
        this.historyMessageService.setState({ isSaveButtonDisabled: true });
      }),
      switchMap(({ message, historyId }: { message: string; historyId: number }) => {
        return this.candidateApiService.updateHistoryMessage(candidateId, historyId, message).pipe(
          tap((updatedMessage: HistoryMessage) => {
            const { historyMessages } = this.getStateSnapshot();
            const index = historyMessages.findIndex(
              (existingMessage) => existingMessage.id === historyId
            );
            if (index > -1) {
              historyMessages[index] = updatedMessage;
            }
            this.setState({ historyMessages: uniqBy([...historyMessages], 'id') });
            this.historyMessageService.eventBus.dispatch(historyMessageActions.clearInput());
            this.toast.showSuccess(getGeneralMessage('candidates.modify_message', true));
          }),
          catchError((err) => {
            this.toast.showSuccess(getGeneralMessage('candidates.modify_message', false));
            return of(err);
          }),
          finalize(() => {
            this.historyMessageService.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  loadMoreHistory(candidateId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.eventBus.on(historyActionTypes.loadMore).pipe(
      withLatestFrom(this.select('currentHistoryPage')),
      switchMap(([_, currentPage]) => {
        return this.candidateApiService.getHistory(candidateId, currentPage + 1).pipe(
          tap((history: ListData<HistoryMessage>) => {
            const { historyMessages } = this.getStateSnapshot();
            this.toast.showSuccess('partners.load_more_history_success');
            this.setState({
              currentHistoryPage: currentPage + 1,
              allMessageCount: history.total,
              historyMessages: uniqBy([...historyMessages, ...history.data], 'id'),
            });
          })
        );
      })
    );
  }

  openDeleteHistoryModal(candidateId: number): Observable<any> {
    return this.historyMessageService.openHistoryDeleteModal(this.historyDeleteModalId).pipe(
      concatMap(({ historyMessage }) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.candidateApiService.deleteHistoryMessage(candidateId, +historyMessage.id).pipe(
          tap(() => {
            const { historyMessages, allMessageCount } = this.getStateSnapshot();
            this.setState({
              historyMessages: historyMessages.filter(
                (history) => history.id !== historyMessage.id
              ),
              allMessageCount: allMessageCount - 1,
            });
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.toast.showSuccess('partners.message_deleted_success');
          }),
          concatMap(() => {
            return this.getFirstHistoryPage(candidateId);
          }),
          catchError((err) => {
            this.toast.showError('partners.message_deleted_error');
            return of(err);
          }),
          finalize(() => this.modalStateService.setState({ isSaveButtonDisabled: false }))
        );
      })
    );
  }

  updateCandidateDetail(candidateId: number): Observable<CandidateDetail> {
    return this.eventBus.on(candidateActionTypes.updateDetail).pipe(
      switchMap((detail: CandidateDetail) => {
        if (detail) {
          this.setState({ candidateDetail: detail });
          return of(detail);
        }
        return this.candidateApiService.getCandidateById(candidateId).pipe(
          tap((candidateDetail: CandidateDetail) => {
            this.setState({ candidateDetail });
          }),
          catchError((err) => throwError(err))
        );
      }),
      concatMap((detail) => this.getFirstHistoryPage(candidateId).pipe(map(() => detail)))
    );
  }

  setFilterState(filterObj: Object) {
    const newState = {
      ...this.getStateSnapshot(),
      candidateFilters: { ...this.getStateSnapshot().candidateFilters, ...filterObj },
    };
    this.setState(newState);
  }

  setFirstLetters(): Observable<string[]> {
    return this.candidateApiService.getCandidateFirstLetters().pipe(
      tap((res: string[]) => {
        this.setState({ firstLetters: res });
        this.filteringService.eventBus.dispatch(filterActions.firstLettersLoadedAction());
      })
    );
  }

  resetFilters() {
    this.filteringService.eventBus.dispatch(filterActions.resetFiltersAction());
  }

  bulkEditCandidate(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    return this.tableService.eventBus.on(candidateActionTypes.bulkEditCandidate).pipe(
      switchMap(() =>
        this.modalService.openDialog({
          width: AppConstants.fullHeightModalNarrowWidth,
          data: {
            contentComponent,
          },
        })
      )
    );
  }

  exportTable(): Observable<any> {
    return this.headerService.exportDataTable(ExportModalComponent).pipe(
      map(() => {
        const candidateFilter = this.getStateSnapshot().candidateFilters;
        const sortedFilters = removeBlankAttributes(candidateFilter);
        let encodedFilters: string | undefined;
        if (Object.keys(sortedFilters).length !== 0) {
          encodedFilters = encodeBase64(sortedFilters);
        }
        return encodedFilters;
      }),
      switchMap((encodedFilters: string) => {
        const endpoint = this.candidateApiService.exportCandidateTable(encodedFilters);
        return this.headerService.handleExportResponse(endpoint);
      })
    );
  }

  getFirstHistoryPage(candidateId: number): Observable<ListData<HistoryMessage>> {
    const { historyMessages } = this.getStateSnapshot();
    return this.candidateApiService.getHistory(candidateId, 1).pipe(
      tap((history: ListData<HistoryMessage>) => {
        this.setState({
          historyMessages: uniqBy([...history.data, ...historyMessages], 'id'),
          allMessageCount: history.total,
        });
      })
    );
  }

  refreshHistories(candidateId: number): Observable<ListData<HistoryMessage>> {
    return this.eventBus.on(candidateActionTypes.refreshHistoryPage).pipe(
      switchMap(() => {
        return this.getFirstHistoryPage(candidateId);
      })
    );
  }

  requestCandidatePositionList(): Observable<CandidatePositionMinimal[]> {
    return this.eventBus.on(candidateActionTypes.requestAttachedPositions).pipe(
      tap(() => this.setState({ isCandidatePositionsLoadingForDocumentUpload: true })),
      switchMap((candidateId: number) => {
        return this.candidateApiService.getAllPositionsAttachedToCandidate(candidateId).pipe(
          catchError((err) => {
            this.toast.showError('candidates.attached_position_list_load_error');
            return throwError(err);
          }),
          finalize(() => this.setState({ isCandidatePositionsLoadingForDocumentUpload: false }))
        );
      })
    );
  }

  resetPositionListStateInDocumentUploadModal(): void {
    this.setState({
      isCandidatePositionsLoadedForDocumentUpload: false,
      isCandidatePositionsLoadingForDocumentUpload: false,
      candidatePositionsForDocumentUpload: [],
    });
  }

  private handleCandidateSaveSideEffects(form: FormGroup, toastText: string) {
    return (
      source: Observable<CandidateDetail | HttpErrorResponse>
    ): Observable<CandidateDetail | HttpErrorResponse> =>
      source.pipe(
        tap(() => {
          this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          this.toast.showSuccess(getGeneralMessage(toastText, true));
        }),
        catchError((err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse) {
            addValidationErrorsToForm(form, parseBackendValidationErrors(err));
          }
          this.toast.showError(getGeneralMessage(toastText, false));
          return of(err);
        }),
        finalize(() => {
          this.eventBus.dispatch(candidateActions.candidateUpdated());
          this.modalStateService.setState({ isSaveButtonDisabled: false });
        })
      );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CandidatePositionMinimal } from '@pages/candidates/classes/CandidatePositionMinimal';
import { Observable, throwError } from 'rxjs';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { catchError, take } from 'rxjs/operators';
import candidateActions from '@pages/candidates/actions/candidate.actions';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-document-position-selection',
  templateUrl: './document-position-selection.component.html',
  styleUrls: ['./document-position-selection.component.scss'],
})
export class DocumentPositionSelectionComponent implements OnInit {
  @Input() group: FormGroup;
  positions$: Observable<CandidatePositionMinimal[]>;
  isPositionListLoaded: boolean;
  isPositionListLoading$: Observable<boolean>;
  isPositionListLoadErrorOccurred = false;

  constructor(private candidateService: CandidatesService) {}

  ngOnInit(): void {
    this.isPositionListLoading$ = this.candidateService.select(
      'isCandidatePositionsLoadingForDocumentUpload'
    );
    this.positions$ = this.candidateService.select('candidatePositionsForDocumentUpload');
    this.getPositionsForDropdown();
  }

  private getPositionsForDropdown(): void {
    const {
      isCandidatePositionsLoadedForDocumentUpload,
    } = this.candidateService.getStateSnapshot();

    if (!isCandidatePositionsLoadedForDocumentUpload) {
      this.candidateService
        .requestCandidatePositionList()
        .pipe(
          take(1),
          catchError((err) => {
            this.isPositionListLoadErrorOccurred = true;
            return throwError(err);
          })
        )
        .subscribe((positions: CandidatePositionMinimal[]) => {
          this.isPositionListLoadErrorOccurred = false;
          this.candidateService.setState({
            isCandidatePositionsLoadedForDocumentUpload: true,
            candidatePositionsForDocumentUpload: positions,
          });
        });

      const { id } = this.candidateService.getStateSnapshot().candidateDetail;
      this.candidateService.eventBus.dispatch(candidateActions.requestAttachedPositions(id));
    }
  }
}

import { SumIcon, SumIconType } from '@pages/positions/classes/board/BoardCandidate';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { SumIconData } from '@pages/positions/classes/board/SumIconData';

const TRANSLATION_KEY_PREFIX = 'positions.board';

function showTooltipText(icon: SumIcon, isOkTooltipTextShown: boolean): boolean {
  return (icon.type !== SumIconType.Ok || isOkTooltipTextShown) && icon?.messages?.length > 0;
}

function normalizeTooltipText(messages: string | string[]): string[] {
  let normalizedMessages = [];

  if (typeof messages === 'string') {
    normalizedMessages = [messages];
  }

  if (Array.isArray(messages)) {
    normalizedMessages = messages;
  }

  return normalizedMessages.map((message) => `${TRANSLATION_KEY_PREFIX}.${message}`);
}

export function getSumIconData(icons: SumIcon[], isOkTooltipTextShown = false): SumIconData[] {
  if (!Array.isArray(icons)) {
    return [];
  }

  const iconTypes = {
    [SumIconType.Ok]: [HintType.Success],
    [SumIconType.Error]: [HintType.Error],
    [SumIconType.Clock]: [HintType.Clock],
    [SumIconType.RedSquare]: [HintType.RedSquare],
    [SumIconType.YellowSquare]: [HintType.YellowSquare],
    [SumIconType.GreenSquare]: [HintType.GreenSquare],
    [SumIconType.GraySquare]: [HintType.GraySquare],
  };

  return icons.map((icon: SumIcon) => {
    return {
      hintType: iconTypes[icon.type],
      tooltipTexts: showTooltipText(icon, isOkTooltipTextShown)
        ? normalizeTooltipText(icon.messages)
        : [],
    };
  });
}

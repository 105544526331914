import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { HintModule } from '@shared/modules/hint/hint.module';
import { ButtonModule } from '@shared/modules/button/button.module';
import { TextFieldLabelComponent } from '@shared/modules/form-components/text-field-label/text-field-label.component';
import { DatepickerComponent } from '@shared/modules/form-components/datepicker/datepicker.component';
import { FormFieldComponent } from '@shared/modules/form-components/form-field/form-field.component';
import { TagsAutocompleteComponent } from '@shared/modules/form-components/tags-autocomplete/tags-autocomplete.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { SpokenLanguageSelectionComponent } from '@shared/modules/form-components/spoken-language-selection/spoken-language-selection.component';
import { QuillModule } from 'ngx-quill';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ButtonComponent } from './button/button.component';
import { SelectComponent } from './select/select.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { AddTagOptionTemplateComponent } from './select/add-tag-option-template/add-tag-option-template.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { YesNoSelectComponent } from './yes-no-select/yes-no-select.component';
import { TagComponent } from './tag/tag.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { StartEndDatePickerComponent } from './start-end-date-picker/start-end-date-picker.component';

@NgModule({
  declarations: [
    SelectComponent,
    RadioGroupComponent,
    ButtonComponent,
    FormHeaderComponent,
    FormFieldComponent,
    TextFieldLabelComponent,
    DatepickerComponent,
    AddTagOptionTemplateComponent,
    FileUploadComponent,
    TextAreaComponent,
    YesNoSelectComponent,
    TagsAutocompleteComponent,
    TagComponent,
    SpokenLanguageSelectionComponent,
    TextEditorComponent,
    StartEndDatePickerComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    TranslateModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    CustomPipesModule,
    HintModule,
    ButtonModule,
    InlineSVGModule,
    QuillModule,
  ],
  exports: [
    SelectComponent,
    RadioGroupComponent,
    ButtonComponent,
    FormHeaderComponent,
    FormFieldComponent,
    TextFieldLabelComponent,
    DatepickerComponent,
    AddTagOptionTemplateComponent,
    FileUploadComponent,
    TextAreaComponent,
    YesNoSelectComponent,
    TagsAutocompleteComponent,
    SpokenLanguageSelectionComponent,
    TextEditorComponent,
    StartEndDatePickerComponent,
  ],
})
export class FormComponentsModule {}

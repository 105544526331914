import { isEmpty, isNumber, keys } from 'lodash-es';

interface FalsyValueChecker {
  allowEmptyArray: () => FalsyValueChecker;
  allowFile: () => FalsyValueChecker;
  allowEmptyObject: () => FalsyValueChecker;
  allowZero: () => FalsyValueChecker;
  allowEmptyString: () => FalsyValueChecker;
  check: () => boolean;
}

export function isNonFalsyValue(value: any): FalsyValueChecker {
  let isValid = false;

  function baseCheck(): boolean {
    return (
      (isNumber(value) && value !== 0) ||
      (typeof value === 'string' && value.length > 0) ||
      keys(value).length > 0
    );
  }

  return {
    allowEmptyArray(): FalsyValueChecker {
      isValid = isValid || (Array.isArray(value) && value.length === 0);
      return this;
    },
    allowFile(): FalsyValueChecker {
      isValid = isValid || value instanceof File;
      return this;
    },
    allowEmptyObject(): FalsyValueChecker {
      isValid = isValid || (typeof value === 'object' && isEmpty(value));
      return this;
    },
    allowZero(): FalsyValueChecker {
      isValid = isValid || value === 0;
      return this;
    },
    allowEmptyString(): FalsyValueChecker {
      isValid = isValid || value === '';
      return this;
    },
    check(): boolean {
      return isValid || baseCheck();
    },
  };
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterTypes } from '@shared/modules/filtering/classes/FilterTypes';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';

@Component({
  selector: 'app-filter-container-content',
  templateUrl: './filter-container-content.component.html',
  styleUrls: ['./filter-container-content.component.scss'],
})
export class FilterContainerContentComponent {
  transformedFilters: CardFilterInterface[] = [];
  @Input() numberOfActiveFilter = 0;
  @Input() translateKey: string;
  @Input() isLaborHireAts = false;
  @Input() set filters(filters: CardFilterInterface[]) {
    this.transformedFilters = filters.map((filter: CardFilterInterface) => {
      if (this.isLaborHireAts) {
        return filter.translateKey === 'candidate_membership_start_date'
          ? { ...filter, translateKey: 'labor_start' }
          : filter;
      }
      return filter;
    });
  }
  @Output() setFilter = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<void>();
  filterTypes = FilterTypes;
}

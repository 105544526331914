import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { KeyValue } from '@shared/classes/KeyValue';
import { AppConstants } from '@config/app.constant';

export function getPartnerFieldManagerKeyValueData(detail: PartnerDetail): KeyValue<string>[] {
  const fieldManager = detail?.fieldManager;

  if (!fieldManager) {
    return [];
  }

  return [
    {
      key: 'common.name',
      value: fieldManager?.name ?? AppConstants.noDataTranslationKey,
    },
    {
      key: 'common.email',
      value: fieldManager?.email ?? AppConstants.noDataTranslationKey,
    },
    {
      key: 'common.phone_number',
      value: fieldManager?.phone ?? AppConstants.noDataTranslationKey,
    },
  ];
}

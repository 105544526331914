import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { BulkEditTranslateHelper } from '@shared/modules/bulk-edit-bar/classes/BulkEditTranslateHelper';
import { TranslateService } from '@ngx-translate/core';
import { BulkEditService } from '@shared/modules/bulk-edit-bar/services/bulk-edit.service';
import { ApiTypeParams } from '@config/app.constant';
import { map, mergeAll, tap } from 'rxjs/operators';
import { PartnerBulkUpdate } from '@pages/partners/classes/PartnerBulkUpdate';
import { CandidateBulkUpdate } from '@pages/candidates/classes/CandidateBulkUpdate';
import { PositionBulkUpdate } from '@pages/positions/classes/PositionBulkUpdate';
import { BulkEditConfirmModalContent } from '@shared/modules/bulk-edit-bar/classes/BulkEditConfirmModalContent';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Action } from '@shared/modules/event-bus/classes/Action';
import { ATSConfigService } from '@shared/services/ats-config.service';

@UntilDestroy()
@Component({
  selector: 'app-confirm-bulk-edit-modal',
  templateUrl: './confirm-bulk-edit-modal.component.html',
  styleUrls: ['./confirm-bulk-edit-modal.component.scss'],
})
export class ConfirmBulkEditModalComponent implements OnInit, OnDestroy {
  modalText$ = new BehaviorSubject<string>('');
  updatableItems$ = new BehaviorSubject<{ name: string; selected: string }[]>([]);
  translateHelper = BulkEditTranslateHelper;
  type: ApiTypeParams;
  private confirmModalId: string;
  dismissAction: Action;
  isLaborHireATS = false;

  constructor(
    private modalStateService: ModalStateService,
    private translate: TranslateService,
    private bulkEditService: BulkEditService,
    private atsConfigService: ATSConfigService
  ) {}

  ngOnInit(): void {
    this.isLaborHireATS = this.atsConfigService.isLaborHireATS;
    this.type = this.bulkEditService.getStateSnapshot().type;
    this.modalStateService.setState({ isModalDataLoading: false });
    this.setContent(this.type).pipe(untilDestroyed(this)).subscribe();
    this.modalStateService
      .select('confirmModalId')
      .pipe(untilDestroyed(this))
      .subscribe((id) => (this.confirmModalId = id));
    this.listenActions();
  }

  private setContent(type: ApiTypeParams): Observable<any> {
    const bulkEditStateKey = this.bulkEditService.bulkEditStateHelper(type);
    return this.bulkEditService.select(bulkEditStateKey).pipe(
      map((value: any) => {
        return this.bulkEditStateConverter(type, value);
      }),
      tap((convertedState: BulkEditConfirmModalContent) => {
        const typeTranslate = this.translate.instant(`common.${type.toLocaleLowerCase()}`);
        this.modalText$.next(
          this.translate.instant('common.bulk_edit_confirm_title', {
            number: convertedState.selectedItemsLength,
            type: typeTranslate,
          })
        );
        this.updatableItems$.next(convertedState.data);
      })
    );
  }

  getTranslateKey(key: string): string {
    if (this.isLaborHireATS) {
      switch (key) {
        case 'projectId':
          return 'common.site';
        case 'subProject':
          return 'common.sub_site';
        default:
          return this.translateHelper[key];
      }
    } else {
      return this.translateHelper[key];
    }
  }

  private bulkEditStateConverter(
    type: ApiTypeParams,
    state: PartnerBulkUpdate | CandidateBulkUpdate | PositionBulkUpdate
  ): BulkEditConfirmModalContent {
    let selectedItemsLength;
    const newState = { ...state };
    switch (type) {
      case ApiTypeParams.PARTNER:
        if ('partnerIds' in newState) {
          selectedItemsLength = newState.partnerIds.length;
          delete newState.partnerIds;
        }
        break;
      case ApiTypeParams.POSITION:
        if ('positionIds' in newState) {
          selectedItemsLength = newState.positionIds.length;
          delete newState.positionIds;
        }
        break;
      case ApiTypeParams.CANDIDATE:
        if ('candidateIds' in newState) {
          selectedItemsLength = newState.candidateIds.length;
          delete newState.candidateIds;
        }
        break;
      default:
        break;
    }
    return {
      selectedItemsLength,
      data: Object.keys(newState)
        .filter((key: string) => {
          return state[key];
        })
        .map((key: string) => {
          return {
            name: key,
            selected: Array.isArray(state[key])
              ? state[key].map((value) => value?.name).join('\n')
              : state[key].name,
          };
        }),
    };
  }

  private listenActions(): void {
    merge(
      of(
        this.bulkEditService.listenBulkEditConfirmAction(),
        this.bulkEditService.listenBulkEditDismissAction()
      )
    )
      .pipe(mergeAll(), untilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.modalStateService.resetModalStateOnDestroy();
  }
}

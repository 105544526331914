import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { ComponentType } from '@angular/cdk/overlay';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { defaultModalData } from '@shared/modules/mat-modal/classes/ModalData';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { Observable } from 'rxjs';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-modal-frame',
  templateUrl: './modal-frame.component.html',
  styleUrls: ['./modal-frame.component.scss'],
})
export class ModalFrameComponent extends MatModalBaseComponent implements OnInit {
  @ViewChild('content', { static: true, read: ViewContainerRef })
  contentTemplate: ViewContainerRef;
  modalConfig = { data: defaultModalData };
  modalTypes = ModalTypes;
  contentComponent: ComponentType<any>;
  title: string;
  iconUrl: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  variant: ModalTypes;
  isSaveDisabled$: Observable<boolean>;
  isModalDataLoading: boolean;
  isHeaderShown$: Observable<boolean>;
  approveButtonText$: Observable<string>;
  cancelButtonText$: Observable<string>;

  ngOnInit(): void {
    this.listenBackdropClick();
    this.listenCloseAction();
    this.listenDataLoads();
    this.isSaveDisabled$ = this.modalStateService.select('isSaveButtonDisabled');
    this.isHeaderShown$ = this.modalStateService.select('isHeaderShown');
    this.approveButtonText$ = this.modalStateService.select('approveButtonText');
    this.cancelButtonText$ = this.modalStateService.select('cancelButtonText');

    if (this.data) {
      this.contentComponent = this.data.contentComponent;
      if (this.contentComponent && this.contentTemplate) {
        const factory = this.cfr.resolveComponentFactory(this.contentComponent);
        this.contentTemplate.createComponent(factory);
      }
      this.title = this.data.title;
      this.iconUrl = this.data.iconUrl;
      this.primaryButtonText = this.data.primaryButtonText;
      this.secondaryButtonText = this.data.secondaryButtonText;
      this.variant = this.data.variant;
    }
  }

  onSaveButtonClicked(): void {
    this.modalStateService.eventBus.dispatch(modalActions.saveButtonClickAction());
  }

  private listenBackdropClick(): void {
    this.dialogRef
      .backdropClick()
      .pipe(
        filter(() => !this.dialogRef.disableClose),
        tap(() => {
          this.modalStateService.eventBus.dispatch(modalActions.dismissButtonClick());
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private listenCloseAction(): void {
    this.modalStateService.eventBus
      .on(modalActionTypes.closeModalAction)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private listenDataLoads(): void {
    this.modalStateService
      .select('isModalDataLoading')
      .pipe(untilDestroyed(this))
      .subscribe((isLoading) => {
        this.isModalDataLoading = isLoading;
        const cdr = this.injector.get(ChangeDetectorRef);
        cdr.markForCheck();
        cdr.detectChanges();
      });
  }
}

import { Injectable, Injector } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  positionInitialState,
  PositionState,
} from '@shared/modules/event-bus/state/positions/position.state';
import { ComponentType } from '@angular/cdk/overlay';
import { merge, Observable, of } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { headerActionTypes } from '@shared/modules/header/actions/header.action-types';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mapTo,
  mergeAll,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppConstants } from '@config/app.constant';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { positionActionTypes } from '@pages/positions/actions/position.action-types';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { HttpErrorResponse } from '@angular/common/http';
import { PositionsApiService } from '@pages/positions/services/positions-api.service';
import { PositionDto } from '@pages/positions/classes/PositionDto';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { ListData } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { HistoryMessageService } from '@shared/modules/history-message/services/history-message.service';
import { getStringId } from '@shared/utils/get-string-id.util';
import { PositionListItem } from '@pages/positions/classes/PositionListItem';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';
import filterActions from '@shared/modules/filtering/actions/filter.actions';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { UpdateCandidatePositionPayload } from '@pages/positions/classes/board/UpdateCandidatePositionPayload';
import { CandidateBoardApiService } from '@pages/positions/services/candidate-board-api.service';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { TableService } from '@shared/services/table.service';
import { ExportModalComponent } from '@shared/modules/mat-modal/components/export-modal/export-modal.component';
import { tableActionTypes } from '@shared/modules/table/actions/table.action-types';
import { TogglePositionStatus } from '@pages/positions/classes/TogglePositionStatus';
import { TranslateService } from '@ngx-translate/core';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { PositionStatus } from '@pages/positions/classes/PositionStatus';
import { BoardModalTriggerPayload } from '@pages/positions/classes/board/BoardModalTriggerPayload';
import { AddCandidateToPositionPayload } from '@pages/positions/classes/board/AddCandidateToPositionPayload';
import { head, omit } from 'lodash-es';
import { MoveOrAddPositionDto } from '@pages/positions/classes/board/MoveOrAddPositionDto';
import { hasCommonError } from '@shared/utils/error-keys.util';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import positionActions from '../actions/position.actions';

@Injectable({
  providedIn: 'root',
})
export class PositionsService extends EventBusService<PositionState> {
  historyDeleteModalId = getStringId();
  private readonly modalService: MatModalService;
  private readonly modalStateService: ModalStateService;
  private readonly tableService: TableService;
  private readonly translate: TranslateService;
  private readonly authService: AuthService;

  constructor(
    private headerService: HeaderService,
    private positionApiService: PositionsApiService,
    private toast: ToastService,
    private historyMessageService: HistoryMessageService,
    private filteringService: FilteringService,
    private candidateBoardApiService: CandidateBoardApiService,
    private injector: Injector
  ) {
    super(positionInitialState);
    this.modalService = this.injector.get<MatModalService>(MatModalService);
    this.modalStateService = this.injector.get<ModalStateService>(ModalStateService);
    this.tableService = this.injector.get<TableService>(TableService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.authService = this.injector.get<AuthService>(AuthService);
  }

  managePositions(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    const headerSource$ = this.headerService.eventBus.on(headerActionTypes.newEntityButtonClick);
    const positionDetailSource$ = this.eventBus.on(positionActionTypes.editPosition);

    return merge(of(headerSource$, positionDetailSource$)).pipe(
      mergeAll(),
      switchMap(() =>
        this.modalService.openDialog({
          width: AppConstants.fullHeightModalWidth,
          data: {
            contentComponent,
          },
        })
      )
    );
  }

  createPosition(): Observable<PositionDetail | HttpErrorResponse> {
    return this.eventBus.on(positionActionTypes.createPosition).pipe(
      switchMap((dto: PositionDto) => {
        return this.positionApiService.createPosition(dto).pipe(
          tap(() => {
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.toast.showSuccess(getGeneralMessage('positions.create', true));
          }),
          catchError((err) => {
            this.toast.showError(getGeneralMessage('positions.create', false));
            return of(err);
          }),
          finalize(() => {
            this.eventBus.dispatch(positionActions.refreshPositionTable());
            this.modalStateService.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  updatePosition(): Observable<PositionDetail | HttpErrorResponse> {
    return this.eventBus.on(positionActionTypes.updatePosition).pipe(
      switchMap((dto: PositionDto) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.positionApiService.updatePosition(dto).pipe(
          concatMap((detail: PositionDetail) => {
            return this.historyMessageService
              .getFirstHistoryPage(this.positionApiService.getHistory(detail.id, 1))
              .pipe(mapTo(detail));
          }),
          tap((updatedDetail: PositionDetail) => {
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            this.eventBus.dispatch(positionActions.refreshDetails(updatedDetail));
            this.toast.showSuccess(getGeneralMessage('positions.modify', true));
          }),
          catchError((err: HttpErrorResponse) => {
            if (!hasCommonError(err?.error?.errors)) {
              this.toast.showError(getGeneralMessage('positions.modify', false));
            }
            return of(err);
          }),
          finalize(() => {
            this.modalStateService.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  createHistory(positionId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.createHistory(
      positionId,
      this.positionApiService.createHistoryMessage.bind(this.positionApiService),
      this.positionApiService.getHistory(positionId, 1)
    );
  }

  updateHistory(positionId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.updateHistory(
      positionId,
      this.positionApiService.updateHistoryMessage.bind(this.positionApiService)
    );
  }

  openDeleteHistoryModal(positionId: number): Observable<any> {
    return this.historyMessageService.deleteHistory(
      positionId,
      this.positionApiService.deleteHistoryMessage.bind(this.positionApiService),
      this.positionApiService.getHistory(positionId, 1)
    );
  }

  loadMoreHistory(positionId: number): Observable<ListData<HistoryMessage>> {
    return this.historyMessageService.loadMoreHistory(
      positionId,
      this.positionApiService.getHistory.bind(this.positionApiService)
    );
  }

  openAddCandidateToPositionModal(contentComponent: ComponentType<any>) {
    return this.eventBus.on(positionActionTypes.openModalToSearchCandidate).pipe(
      tap(() => {
        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });

        this.modalStateService.setState({
          isModalDataLoading: false,
          modalTitle: 'positions.board.add_candidate',
          iconUrl: 'assets/image/common/applicant-icon.svg',
        });
      })
    );
  }

  handleBoardActionModals() {
    return this.eventBus.on(positionActionTypes.openBoardTriggeredModal).pipe(
      tap(({ contentComponent }: BoardModalTriggerPayload) => {
        this.openBoardModal(contentComponent);
      }),
      switchMap(() => {
        return this.eventBus.on(positionActionTypes.confirmBoardModalAction);
      }),
      withLatestFrom(this.select('selectedBoardColumn'), this.select('selectedCandidate')),
      tap(
        ([moveOrAddDto, targetColumn, candidate]: [
          Partial<MoveOrAddPositionDto>,
          BoardColumn,
          BoardCandidate
        ]) => {
          this.eventBus.dispatch(
            positionActions.updateCandidatePosition({
              targetColumn,
              candidate,
              moveOrAddDto,
            })
          );
        }
      )
    );
  }

  addCandidateToPosition(positionId: number) {
    return this.eventBus.on(positionActionTypes.addCandidateToPosition).pipe(
      withLatestFrom(this.select('selectedBoardColumn')),
      switchMap(([payload, column]: [AddCandidateToPositionPayload, BoardColumn]) => {
        const dto = {
          statusId: column.id,
          statusOptionId: null,
          ...payload,
        };

        return this.candidateBoardApiService
          .addCandidateToPosition(positionId, payload.candidateId, omit(dto, 'candidateId'))
          .pipe(
            concatMap(() => this.triggerBoardRefresh(positionId)),
            tap(() => {
              this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            }),
            catchError((err: HttpErrorResponse) => {
              this.toast.showError(this.getCandidatePositionUpdateErrorMessage(err));
              return of(err);
            })
          );
      })
    );
  }

  updateCandidatePosition(positionId: number): Observable<BoardCandidate[] | HttpErrorResponse> {
    return this.eventBus.on(positionActionTypes.updateCandidatePosition).pipe(
      switchMap((payload: UpdateCandidatePositionPayload) => {
        this.setState({ isBoardLoading: true });
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.candidateBoardApiService
          .updateCandidatePosition(positionId, payload.candidate.user.id, {
            ...payload.moveOrAddDto,
            statusId: payload.targetColumn.id,
            statusOptionId: payload.moveOrAddDto?.statusOptionId || null,
          })
          .pipe(
            concatMap(() => this.triggerBoardRefresh(positionId)),
            catchError((err: HttpErrorResponse) => {
              this.toast.showError(this.getCandidatePositionUpdateErrorMessage(err));
              this.eventBus.dispatch(positionActions.restoreBoardToPreviousState());
              return of(err);
            }),
            finalize(() => {
              this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
              this.setState({ isBoardLoading: false });
            })
          );
      })
    );
  }

  setFirstLetters(): Observable<string[]> {
    return this.positionApiService.getPositionFirstLetters().pipe(
      tap((res: string[]) => {
        this.setState({ firstLetters: res });
        this.filteringService.eventBus.dispatch(filterActions.firstLettersLoadedAction());
      })
    );
  }

  setFilterState(filterObj: Object) {
    const newState = {
      ...this.getStateSnapshot(),
      positionFilters: { ...this.getStateSnapshot().positionFilters, ...filterObj },
    };
    this.setState(newState);
  }

  getEncodedFilter(): string | undefined {
    const positionFilters = this.getStateSnapshot().positionFilters;
    const sortedFilters = positionFilters ? removeBlankAttributes(positionFilters) : {};
    let encodedFilters: string | undefined;
    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }
    return encodedFilters;
  }

  getPositions(page: string, perPage: string): Observable<ListData<PositionListItem>> {
    const encodedFilters = this.getEncodedFilter();

    return this.positionApiService.getPositions(page, perPage, encodedFilters).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  getPositionFilters(): Observable<CardFilterInterface[]> {
    const isCandidateUser = this.authService.getUserRoles().includes(UserRole.Candidate);

    if (isCandidateUser) {
      return of([]);
    }

    return this.positionApiService.getPositionFilters().pipe(
      map((res: any) => {
        return Object.keys(res).map((key) => res[key]);
      })
    );
  }

  resetFilters() {
    this.filteringService.eventBus.dispatch(filterActions.resetFiltersAction());
  }

  bulkEditPositions(contentComponent: ComponentType<any>): Observable<MatDialogRef<any>> {
    return this.tableService.eventBus.on(positionActionTypes.bulkEditPosition).pipe(
      switchMap(() =>
        this.modalService.openDialog({
          width: AppConstants.fullHeightModalNarrowWidth,
          data: {
            contentComponent,
          },
        })
      )
    );
  }

  openBoardModal(contentComponent: ComponentType<any>): void {
    this.modalService.openDialog({
      width: '460px',
      data: {
        variant: ModalTypes.DynamicHeight,
        contentComponent,
      },
    });

    this.modalStateService.setState({ isModalDataLoading: false });
  }

  resetState() {
    this.setState({ ...positionInitialState });
  }

  exportTable(): Observable<any> {
    return this.headerService.exportDataTable(ExportModalComponent).pipe(
      map(() => {
        const positionFilter = this.getStateSnapshot().positionFilters;
        const sortedFilters = removeBlankAttributes(positionFilter);
        let encodedFilters: string | undefined;
        if (Object.keys(sortedFilters).length !== 0) {
          encodedFilters = encodeBase64(sortedFilters);
        }
        return encodedFilters;
      }),
      switchMap((encodedFilters: string) => {
        const endpoint = this.positionApiService.exportPositionTable(encodedFilters);
        return this.headerService.handleExportResponse(endpoint);
      })
    );
  }

  togglePositionStatus(): Observable<any> {
    const confirmId = getStringId();
    return this.tableService.eventBus.on(tableActionTypes.togglePositionStatus).pipe(
      tap((value: TogglePositionStatus) => {
        const translateKey = value.isActive
          ? 'positions.position_status_to_inactive_confirm'
          : 'positions.position_status_to_active_confirm';
        const modalText = this.translate.instant(translateKey, {
          positionName: value.positionName,
        });
        return this.modalService.openConfirmModal({
          isModalDataLoading: false,
          isHeaderShown: false,
          isSaveButtonDisabled: false,
          approveButtonText: 'common.yes',
          cancelButtonText: 'common.cancel',
          modalText,
          confirmModalId: confirmId,
        });
      }),
      switchMap((position: TogglePositionStatus) => {
        return this.waitForConfirmAction<TogglePositionStatus>(confirmId, position);
      }),
      switchMap((position: TogglePositionStatus) => {
        const toastText = 'partners.position_status_modify';
        return this.positionApiService
          .toggleStatus(
            position.id,
            position.isActive ? PositionStatus.Active : PositionStatus.Inactive
          )
          .pipe(
            tap(() => {
              this.toast.showSuccess(getGeneralMessage(toastText, true));
              this.eventBus.dispatch(positionActions.refreshPositionTable());
            }),
            catchError((err: HttpErrorResponse) => {
              return of(err);
            }),
            finalize(() => {
              this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            })
          );
      })
    );
  }

  private triggerBoardRefresh(positionId: number): Observable<BoardCandidate[]> {
    return this.candidateBoardApiService.getBoardCandidates(positionId).pipe(
      tap((boardCandidates) => {
        this.setState({ boardCandidates });
        this.eventBus.dispatch(positionActions.triggerBoardRefresh());
      })
    );
  }

  private waitForConfirmAction<T>(confirmId: string, mapToObject: T) {
    return this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
      filter((id) => id === confirmId),
      mapTo(mapToObject)
    );
  }

  private getCandidatePositionUpdateErrorMessage(err: HttpErrorResponse) {
    let errorMessage = getGeneralMessage('positions.board.modify', false);
    if (head<string>(err?.error?.errors) === 'position_outsourced_exists') {
      errorMessage = 'positions.board.is_outsourced_error';
    }
    return errorMessage;
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';
import { SharedModule } from '@shared/shared.module';
import { HttpModule } from '@shared/modules/http/http.module';
import { GlobalLoaderModule } from '@shared/modules/global-loader/global-loader.module';
import { HeaderModule } from '@shared/modules/header/header.module';
import { MatModalModule } from '@shared/modules/mat-modal/mat-modal.module';
import { HttpMultiLoaderFactory } from '@shared/utils/http-multi-loader.util';
import { VersionDisplayModule } from '@shared/modules/version-display/version-display.module';
import { SideFilterModule } from '@shared/modules/side-filter/side-filter.module';
import { BaseComponentModule } from '@shared/modules/base-component/base-component.module';
import { BrowserNotSupportedModule } from '@pages/browser-not-supported/browser-not-supported.module';
import { CookiePopupModule } from '@shared/modules/cookie-popup/cookie-popup.module';
import { SideNavigationModule } from '@shared/modules/side-navigation/side-navigation.module';
import { SetLanguageDirective } from '@shared/directives/set-language.directive';
import { NotFoundModule } from '@pages/not-found/not-found.module';
import { CookieService } from 'ngx-cookie-service';
import { AUTH_STORAGE } from '@shared/modules/auth/providers/auth-storage.injection-token';
import { authStorageFactory } from '@shared/modules/auth/factory/auth-storage.factory';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CustomDirectivesModule } from '@shared/modules/custom-directives/custom-directives.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TippyModule } from '@ngneat/helipopper';
import { tippyConfig } from '@config/tippy.config';
import { HotToastModule } from '@ngneat/hot-toast';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from '@shared/modules/table/table.module';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { QuillModule } from 'ngx-quill';
import { ApplicationInsightsErrorHandler } from '@shared/utils/app-insights/ApplicationInsightsErrorHandler';
import { ConsoleErrorHandler } from '@shared/utils/ConsoleErrorHandler';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppInitializer } from './app.initializer';
import { AppInjector } from './app.injector.service';

export function initApp(initializer: AppInitializer) {
  return () => initializer.load();
}

/** Main module, imports app wide modules, providers, bootstrap */
@NgModule({
  declarations: [AppComponent, SetLanguageDirective],
  imports: [
    HeaderModule,
    SideFilterModule,
    SideNavigationModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    NotFoundModule,
    VersionDisplayModule,
    GlobalLoaderModule,
    MatModalModule,
    BaseComponentModule,
    BrowserNotSupportedModule,
    CookiePopupModule,
    NgSelectModule,
    TableModule,
    InlineSVGModule.forRoot(),
    CustomDirectivesModule,
    UiSwitchModule.forRoot({}),
    TippyModule.forRoot(tippyConfig),
    HotToastModule.forRoot(),
    NgSelectModule,
    MatMomentDateModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpMultiLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppInitializer],
      multi: true,
    },
    {
      provide: AUTH_STORAGE,
      useFactory: authStorageFactory,
      deps: [CookieService],
    },
    {
      provide: JwtHelperService,
      useFactory: () => new JwtHelperService(),
    },
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
    { provide: ErrorHandler, useClass: ConsoleErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}

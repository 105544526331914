import { SaveUsersFilter } from '@shared/classes/users/SaveUsersFilter';

export interface FilterState {
  letter_filter: string;
  first_letters: Array<string>;
  selected_filter: string;
  filter: SaveUsersFilter;
  isFilterLoaded: boolean;
}

export const filterInitialState: FilterState = {
  letter_filter: null,
  first_letters: [],
  selected_filter: '',
  filter: undefined,
  isFilterLoaded: false,
};

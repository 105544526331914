import { PositionCardTableRow } from '@pages/partners/classes/PositionCardTableRow';
import { ProjectPosition } from '@pages/partners/classes/ProjectPosition';
import { head, isEmpty } from 'lodash-es';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { AppConstants } from '@config/app.constant';
import { Contact } from '@pages/partners/classes/Contact';
import { Specialization } from '@pages/candidates/classes/Specialization';

export function mapPositionToTableRow(position: ProjectPosition): PositionCardTableRow {
  return {
    id: position.id,
    projectManager: head<Contact>(position.projectManagers)?.name,
    name: position?.name ?? '',
    nameIconUrl: `${environment.assetUrl}/${head<Specialization>(position.specializations)?.icon}`,
    status: position?.status,
    code: position?.code ?? '',
    date: !isEmpty(position.createdAt)
      ? moment(position.createdAt).format(AppConstants.dateFormat)
      : '',
  };
}

import { HintTemplateTypes } from '@shared/modules/hint/classes/HintTemplateTypes';
import { TemplateRef } from '@angular/core';

export function getHintTemplateType(hint: string | string[] | TemplateRef<any>): HintTemplateTypes {
  if (!hint) {
    return undefined;
  }

  if (typeof hint === 'string') {
    return HintTemplateTypes.Text;
  }

  if (Array.isArray(hint)) {
    return HintTemplateTypes.TextList;
  }

  return HintTemplateTypes.Template;
}

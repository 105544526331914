import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { FormControl, Validators } from '@angular/forms';
import { ExportDataFormat } from '@shared/classes/ExportDataFormat';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { HeaderService } from '@shared/modules/header/services/header.service';
import headerAction from '@shared/modules/header/actions/header.actions';

@UntilDestroy()
@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent implements OnInit, OnDestroy {
  exportTypes: ExportDataFormat[];
  exportTypeControl = new FormControl(null, [Validators.required]);
  isLoading = false;

  constructor(
    private modalStateService: ModalStateService,
    private translate: TranslateService,
    private headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.exportTypes = [{ id: 1, type: 'xlsx', name: this.translate.instant('common.xlsx') }];
    this.listenControlChange();
    this.listenExportButtonAction();
  }

  listenControlChange(): void {
    merge(this.exportTypeControl.valueChanges, this.exportTypeControl.statusChanges)
      .pipe(
        startWith(this.exportTypeControl.value),
        map(() => {
          if (this.exportTypeControl.status === 'PENDING') {
            return true;
          }

          return this.exportTypeControl.invalid;
        }),
        untilDestroyed(this)
      )
      .subscribe((isSaveButtonDisabled: boolean) => {
        this.modalStateService.setState({ isSaveButtonDisabled });
      });
  }

  listenExportButtonAction(): void {
    this.modalStateService.eventBus
      .on(modalActionTypes.saveButtonClick)
      .pipe(
        tap(() => {
          this.modalStateService.setState({
            isSaveButtonDisabled: true,
          });
          this.isLoading = true;
          this.headerService.eventBus.dispatch(
            headerAction.exportData(this.exportTypeControl.value)
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.modalStateService.resetModalStateOnDestroy();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ParamArray } from '@shared/modules/http/classes/ParamArray';

/** Http Service Layer */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  /** generate url for backend call */
  generateFullUrl(url: string): string {
    let normalizedUrl = url;
    if (url.startsWith('/')) {
      normalizedUrl = url.substring(1);
    }

    return `${environment.apiUrl}/${environment.backendVersion}/${normalizedUrl}`;
  }

  /** generate url query params for backend call */
  generateUrlQueryParams(
    urlParams: Record<string, string> = null,
    paramArray: ParamArray<any> = null
  ): HttpParams {
    let params = new HttpParams();

    if (urlParams) {
      Object.keys(urlParams).forEach((property: string) => {
        if (property in urlParams) {
          params = params.append(property, urlParams[property]);
        }
      });
    }

    if (paramArray && Array.isArray(paramArray.value)) {
      paramArray.value.forEach((value) => {
        params = params.append(`${paramArray.key}[]`, value);
      });
    }

    return params;
  }

  get(
    url,
    urlParams: Record<string, string> = null,
    paramArray: ParamArray<any> = null
  ): Observable<any> {
    return this.http.get(this.generateFullUrl(url), {
      params: this.generateUrlQueryParams(urlParams, paramArray),
    });
  }

  getFile(
    url,
    urlParams: Record<string, string> = null,
    paramArray: ParamArray<any> = null
  ): Observable<any> {
    return this.http.get(this.generateFullUrl(url), {
      params: this.generateUrlQueryParams(urlParams, paramArray),
      responseType: 'blob',
      observe: 'response',
    });
  }

  post(url, data): Observable<any> {
    return this.http.post(this.generateFullUrl(url), data);
  }

  put(url, data): Observable<any> {
    return this.http.put(this.generateFullUrl(url), data);
  }

  patch(url, data): Observable<any> {
    return this.http.patch(this.generateFullUrl(url), data);
  }

  delete(url): Observable<any> {
    return this.http.delete(this.generateFullUrl(url));
  }
}

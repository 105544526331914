import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateWithParamsPipe } from '@shared/modules/custom-pipes/pipes/translate-with-params.pipe';
import { AppFirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { TimeagoPipe } from './pipes/timeago.pipe';
import { AtsBasedTextPipe } from './pipes/ats-based-text.pipe';
import { HasPermissionPipe } from './pipes/has-permission.pipe';

@NgModule({
  declarations: [
    TranslateWithParamsPipe,
    AppFirstLetterUppercasePipe,
    FormatDatePipe,
    TimeagoPipe,
    AtsBasedTextPipe,
    HasPermissionPipe,
  ],
  exports: [
    TranslateWithParamsPipe,
    AppFirstLetterUppercasePipe,
    FormatDatePipe,
    TimeagoPipe,
    AtsBasedTextPipe,
    HasPermissionPipe,
  ],
  imports: [CommonModule],
})
export class CustomPipesModule {}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formatDate } from '@shared/utils/format-date.util';

@Component({
  selector: 'app-date-interval-picker',
  templateUrl: './date-interval-picker.component.html',
  styleUrls: ['./date-interval-picker.component.scss'],
})
export class DateIntervalPickerComponent {
  @Input() isOpen: boolean;
  @Input() dateIntervalPicker: FormGroup;
  @Input() hintText: string;

  get tagValue() {
    return `${formatDate(this.dateIntervalPicker.get('start').value)} - ${formatDate(
      this.dateIntervalPicker.get('end').value
    )}`;
  }

  deleteItem() {
    this.dateIntervalPicker.reset();
  }
}

import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { TippyPlacement } from '@shared/classes/TippyPlacement';
import { HintTemplateTypes } from '@shared/modules/hint/classes/HintTemplateTypes';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-hint-base',
  template: '',
  styles: [],
})
export class HintBaseComponent implements OnInit {
  @Input() filePath: string;
  @Input() placement: TippyPlacement = 'right-start';
  @Input() hintText: string | string[] | TemplateRef<any>;
  @Input() variation = 'hint';
  hintTemplateType = HintTemplateTypes.TextList;
  hintTemplateTypes = HintTemplateTypes;
  appendTo: HTMLElement;

  constructor(
    protected modalService: MatModalService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    if (this.modalService.isModalOpened) {
      this.appendTo = this.document.querySelector('.cdk-overlay-container');
    } else {
      this.appendTo = this.document.body;
    }
  }
}

import { catchError, delay, filter, map, switchMap, take } from 'rxjs/operators';

import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { AppConfig } from '@config/app.config';
import { HttpService } from '@shared/modules/http/http.service';
import { EndpointsConfig } from '@config/endpoints.config';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { AuthService } from '../auth/services/auth.service';
import { GlobalLoaderService } from '../global-loader/services/global-loader.service';
import { version } from '../../../../../package.json';
import modalActions from '../mat-modal/actions/modal.actions';

@Injectable()
export class WsInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private loader: GlobalLoaderService,
    private injector: Injector,
    private translate: TranslateService,
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.injector.get<AuthService>(AuthService);
    const modalStateService = this.injector.get<ModalStateService>(ModalStateService);

    let requestClone = req.clone();
    const apiCall = requestClone.urlWithParams.includes(environment.apiUrl);

    if (!apiCall || req.url.includes('assets')) {
      return next.handle(req);
    }

    if (apiCall) {
      requestClone = this.setCommonHeaders(requestClone);
    }

    return next.handle(requestClone).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          const skippedUrls = [EndpointsConfig.refresh, EndpointsConfig.login];
          if (!skippedUrls.some((route) => requestClone.url.includes(route))) {
            return this.handle401Error(requestClone, next);
          }

          // unsuccessful refresh call
          modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          auth.logout(true);
        }

        return throwError(error);
      })
    );
  }

  setCommonHeaders(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: this.getHeaders(),
      withCredentials: true,
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const auth = this.injector.get<AuthService>(AuthService);

    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next.handle(this.setCommonHeaders(request));
        })
      );
    }

    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    return of(null).pipe(
      delay(300),
      map(() => auth.authStorage.readCSRFToken()),
      switchMap((csrf: string) => {
        const headersCopy = { ...this.getHeaders(), 'x-csrf-token': csrf };
        return this.httpClient
          .post<any>(
            this.httpService.generateFullUrl(EndpointsConfig.refresh),
            {},
            { headers: headersCopy }
          )
          .pipe(
            switchMap(() => {
              this.isRefreshing = false;
              this.refreshTokenSubject.next('token created');
              return next.handle(this.setCommonHeaders(request));
            }),
            catchError((err) => {
              return throwError(err);
            })
          );
      })
    );
  }

  private getHeaders(): { [key: string]: string } {
    const auth = this.injector.get<AuthService>(AuthService);

    let headers: { [key: string]: string } = {
      'Accept-Language': this.translate.currentLang || AppConfig.defaultLanguage,
      'x-client-version': version,
      'x-client-env': environment.name,
      'x-request-id': uuidv4(),
    };

    const CSRFToken = auth.authStorage.readCSRFToken();

    if (environment.authStorageStrategy === 'cookie' && CSRFToken) {
      headers = { ...headers, 'x-csrf-token': auth.authStorage.readCSRFToken() };
    }

    return headers;
  }
}

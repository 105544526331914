import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { candidateFilterKeys, partnerFiltersKeys } from '@config/filter.config';
import { AppConstants } from '@config/app.constant';
import { CardFilteringBaseComponent } from '@shared/modules/filtering/components/card-filtering-base/card-filtering-base.component';
import { TranslateService } from '@ngx-translate/core';
import { HintType } from '@shared/modules/hint/classes/HintType';

@UntilDestroy()
@Component({
  selector: 'app-card-filtering',
  templateUrl: './card-filtering.component.html',
  styleUrls: ['./card-filtering.component.scss'],
})
export class CardFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() savedFilter$: Observable<Object>;
  hintTypes = HintType;
  search = new FormControl();
  hintText: string;
  constructor(
    readonly eRef: ElementRef,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService
  ) {
    super(eRef, filteringService, translate);
  }

  ngOnInit() {
    this.setValidatorsAndHintsForGivenFilterKeys();
    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls(false);
      });
    return super.ngOnInit();
  }

  ngAfterViewInit() {
    this.listenSearchFieldChange();
    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((items: string | number[] | undefined) => {
        this.setValueForControls(items);
      });
  }

  ngOnDestroy() {
    this.resetFormControls(true);
    return super.ngOnDestroy();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;
    if (isChecked) {
      this.filterValueChange.emit({ [this.filterKey]: this.search.value });
    } else {
      this.filterValueChange.emit({ [this.filterKey]: '' });
    }
  }

  private setValidatorsAndHintsForGivenFilterKeys(): void {
    const keysToValidate = [partnerFiltersKeys.taxNumber, candidateFilterKeys.cvContent];

    if (keysToValidate.includes(this.filterKey)) {
      this.search.setValidators(Validators.minLength(AppConstants.minInputLength));
      this.hintText = this.translate.instant('hints.min_length', {
        char: AppConstants.minInputLength,
      });
    }
  }

  private listenSearchFieldChange(): void {
    this.search.valueChanges
      .pipe(
        filter(() => this.search.valid),
        debounceTime(600),
        tap((value: string) => {
          this.search.patchValue(value, { emitEvent: false });
          this.emitForFilterValueChange(value);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setValueForControls(valueOfControl: any): void {
    if (typeof valueOfControl !== 'undefined') {
      this.search.setValue(valueOfControl, { emitEvent: false });
      this.emitForFilterValueChange(valueOfControl);
    } else {
      this.resetFormControls(false);
    }
  }

  private emitForFilterValueChange(value: string) {
    if (this.search.valid && !(value === null || value === '')) {
      this.isDisabled = false;
      this.isChecked = true;
      this.filterValueChange.emit({ [this.filterKey]: value });
      this.isOpen = true;
    } else {
      this.isDisabled = true;
      this.isChecked = false;
      this.filterValueChange.emit({ [this.filterKey]: '' });
    }
  }

  private resetFormControls(emitEvent: boolean): void {
    this.search.patchValue('', { emitEvent });
    this.emitForFilterValueChange('');
  }
}

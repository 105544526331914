import { UserPermission } from '@shared/modules/auth/classes/UserPermission';

export interface AppHeaderState {
  filterCount: number;
  title: string;
  createButtonActionPermissions: UserPermission[];
}

export const headerInitialState: AppHeaderState = {
  filterCount: 0,
  title: '',
  createButtonActionPermissions: [],
};

export const filterActionTypes = {
  filterByLetter: '[ABC Filter] - filter by letter button click',
  resetFilters: '[Card Filter] - reset filters',
  manageFilter: '[Manage Filter] - manage filters',
  deleteFilter: '[Manage Filter] - delete filter',
  filterLoadedOrPretend: '[Filters] - got filters and loaded OR pretend to already loaded',
  firstLettersLoaded: '[ABC Filter] - first letters arrived',
  setFirstLetter: '[ABC Filter] - set selected first letter',
  toggleSideFilter: '[Side Filter] - toggle side filter',
};

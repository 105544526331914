import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  AppHeaderState,
  headerInitialState,
} from '@shared/modules/event-bus/state/app-header/app-header.state';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { headerActionTypes } from '@shared/modules/header/actions/header.action-types';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ComponentType } from '@angular/cdk/overlay';
import { saveAs } from 'file-saver';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HeaderService extends EventBusService<AppHeaderState> {
  constructor(
    private toast: ToastService,
    private modalStateService: ModalStateService,
    private modalService: MatModalService
  ) {
    super(headerInitialState);
  }

  exportDataTable(contentComponent: ComponentType<any>): Observable<any> {
    return this.eventBus.on(headerActionTypes.exportTableButtonClick).pipe(
      switchMap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          cancelButtonText: 'common.dismiss',
          approveButtonText: 'common.export',
          isSaveButtonDisabled: true,
          approveButtonStyle: 'basic',
          modalTitle: 'common.data_export',
          iconUrl: 'assets/image/export-icon.svg',
          confirmModalId: '',
        });
        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });
        return this.eventBus.on(headerActionTypes.exportData);
      })
    );
  }

  handleExportResponse(endpoint: Observable<any>): Observable<any> {
    const message = `common.export`;
    return endpoint.pipe(
      tap((resp: HttpResponse<any>) => {
        saveAs(resp.body, this.getFileName(resp.headers));
        this.toast.showSuccess(getGeneralMessage(message, true));
      }),
      catchError((err) => {
        this.toast.showError(getGeneralMessage(message, false));
        return of(err);
      }),
      finalize(() => this.modalStateService.eventBus.dispatch(modalActions.closeModalAction()))
    );
  }

  private getFileName(headers: HttpHeaders): string {
    console.log(headers.keys());

    if (headers?.get('content-disposition')) {
      return headers.get('content-disposition').split('=')[1];
    }

    if (headers?.get('Content-Disposition')) {
      return headers.get('Content-Disposition').split('=')[1];
    }

    return 'unknown_file.xlsx';
  }
}

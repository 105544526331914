import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardFilteringBaseComponent } from '@shared/modules/filtering/components/card-filtering-base/card-filtering-base.component';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, from, merge, Observable, of } from 'rxjs';
import { delay, filter, map, mergeAll, pairwise, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DateInterval } from '@shared/classes/DateInterval';
import { DateIntervalWithIds } from '@shared/classes/DateIntervalWithIds';
import lodashLast from 'lodash-es/last';
import {
  CityDistanceFilter,
  CityDistanceFilterFormGroupObj,
} from '@shared/modules/filtering/classes/CityDistanceFilter';
import { CityMinimal } from '@shared/classes/City';

interface StatusChangePayload {
  id: number;
  name: string;
}

type IntervalChangePayload = {
  [key in 'start' | 'end']: string;
};

type FilterPayload = StatusChangePayload[] | IntervalChangePayload;

@UntilDestroy()
@Component({
  selector: 'app-dropdown-filtering',
  templateUrl: './dropdown-filtering.component.html',
  styleUrls: ['./dropdown-filtering.component.scss'],
})
export class DropdownFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, OnDestroy {
  @Input() singleDropdown = false;
  @Input() withDate = false;
  @Input() withInteger = false;
  selectedItems = new FormControl();
  selected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  idOfSelectedItems: (number | string)[];
  dateIntervalPicker: FormGroup;
  distanceRangeGroup: FormGroup;
  dropDownGroup: FormGroup;
  hintText = '';
  outsideEmitter = new EventEmitter<void>();
  selectedItemWithInteger$: Observable<CityMinimal[]> = of([]);

  constructor(
    readonly eRef: ElementRef,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super(eRef, filteringService, translate);
    this.dropDownGroup = this.fb.group({
      selectedItems: [],
    });
  }

  get selectedItem(): Observable<{ id?: number; name: string }[]> {
    if (this.withInteger) {
      return this.selectedItemWithInteger$;
    }

    return this.selected.asObservable();
  }

  ngOnInit() {
    if (this.withDate) {
      this.dateIntervalPicker = new FormGroup({
        start: new FormControl('', [Validators.required]),
        end: new FormControl('', [Validators.required]),
      });
    }

    if (this.withInteger) {
      this.distanceRangeGroup = new FormGroup({
        id: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        intValue: new FormControl(undefined, [Validators.required, Validators.min(0)]),
      });

      this.selectedItemWithInteger$ = this.getCityRangeValueChanges();
    }

    this.listenDropDownAndDateChange();

    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls();
      });

    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((items: number[] | undefined) => {
        this.setValueForControls(items);
      });

    this.selected.pipe(delay(200), untilDestroyed(this)).subscribe((values) => {
      this.isOpen = values?.length > 0;
    });

    return super.ngOnInit();
  }

  ngOnDestroy() {
    this.resetFormControls();
    return super.ngOnDestroy();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;
    if (isChecked && this.withInteger) {
      this.filterValueChange.emit(this.getFilterStateWithDistanceRange());
    } else if (isChecked) {
      this.filterValueChange.emit(
        this.withDate ? this.getFilterStateWithDate() : { [this.filterKey]: this.idOfSelectedItems }
      );
    } else {
      this.filterValueChange.emit({ [this.filterKey]: undefined });
    }
  }

  onResetCity(): void {
    this.distanceRangeGroup.reset();
    this.filterValueChange.emit({ [this.filterKey]: undefined });
    this.isChecked = false;
    this.isOpen = false;
  }

  customSearchFn(term: string, item: any): boolean {
    const arrayOfTerm = term.toLocaleLowerCase().split(' ');
    const arrayOfItem = item.name.split(' ');
    return (
      arrayOfItem.some((i: string) => {
        return arrayOfTerm.some((t: string) => {
          return i.toLocaleLowerCase().startsWith(t);
        });
      }) || false
    );
  }

  deleteItem(id: number) {
    const newSelectedItems = this.selectedItems.value.filter((item) => {
      return item.id !== id;
    });
    this.selectedItems.patchValue(this.withInteger ? [] : newSelectedItems);

    if (this.withInteger) {
      this.filterValueChange.emit({
        [this.filterKey]: undefined,
      });
    }

    this.isOpen = newSelectedItems.length > 0;
  }

  private getCityRangeValueChanges(): Observable<CityMinimal[]> {
    return this.distanceRangeGroup.valueChanges.pipe(
      pairwise(),
      filter(
        ([prev, current]: [CityDistanceFilterFormGroupObj, CityDistanceFilterFormGroupObj]) =>
          prev?.name !== current?.name
      ),
      tap(() => {
        const lastSelectedCity = lodashLast(this.selectedItems.value);
        this.selectedItems.patchValue(lastSelectedCity ? [lastSelectedCity] : [], {
          emitEvent: false,
        });
      }),
      map(() => {
        const {
          id,
          name,
        } = this.distanceRangeGroup.getRawValue() as CityDistanceFilterFormGroupObj;

        return name ? [{ id, name }] : [];
      })
    );
  }

  private listenDropDownAndDateChange(): void {
    const valueChanges = [this.selectedItems.valueChanges, this.outsideEmitter.asObservable()];

    if (this.withDate) {
      valueChanges.push(this.dateIntervalPicker.valueChanges);
    }

    merge(from(valueChanges))
      .pipe(
        mergeAll(),
        tap((data: FilterPayload) => {
          if (!this.withInteger && (!data || Array.isArray(data))) {
            this.setIntervalControlStates(data as StatusChangePayload[]);
          }

          if (this.withInteger && data) {
            const city = lodashLast(data as StatusChangePayload[]);
            this.setDateRangeCity(city?.id, city?.name);
          }
        }),
        filter((value: FilterPayload) => this.checkValueToEmit(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.withInteger) {
          const intValue = this.distanceRangeGroup.get('intValue').value;
          this.emitIntegerValue({
            id: +this.distanceRangeGroup.get('id').value,
            intValue,
          });
        } else if (this.withDate) {
          this.emitWithDate(this.selectedItems.value, this.dateIntervalPicker.getRawValue());
        } else {
          this.emitWithoutDate(this.selectedItems.value);
        }
      });
  }

  private setDateRangeCity(id: number, name: string): void {
    this.isDisabled = true;
    this.distanceRangeGroup.reset({ id, name, intValue: null });
  }

  private checkValueToEmit(value: StatusChangePayload[] | IntervalChangePayload): boolean {
    if (value && 'start' in value && 'end' in value) {
      if ((value.start && !value.end) || (!value.start && value.end)) {
        this.isOpen = true;
        return false;
      }
    }

    if (this.withInteger) {
      this.isChecked = this.distanceRangeGroup.valid;
      this.isDisabled = this.distanceRangeGroup.invalid;

      if (this.distanceRangeGroup.get('intValue').pristine) {
        return this.distanceRangeGroup.valid;
      }
    }

    return true;
  }

  private emitIntegerValue(value: CityDistanceFilter): void {
    this.isChecked = this.distanceRangeGroup.valid;
    this.filterValueChange.emit({
      [this.filterKey]: this.distanceRangeGroup.valid ? value : undefined,
    });
  }

  private emitWithDate(
    selectedItems: Record<'id' | 'name', number | string>[],
    dateIntervalPicker: DateInterval
  ) {
    this.selected.next(selectedItems);
    this.idOfSelectedItems = this.mapSelectedItemIds(selectedItems);
    let isObjectsValuesAreEmpty = true;
    Object.keys(dateIntervalPicker).forEach((key) => {
      if (dateIntervalPicker[key]) {
        isObjectsValuesAreEmpty = false;
      }
    });
    if ((!selectedItems || selectedItems.length <= 0) && isObjectsValuesAreEmpty) {
      this.isDisabled = true;
      this.isChecked = false;
      this.filterValueChange.emit({ [this.filterKey]: undefined });
    } else {
      this.isDisabled = false;
      this.isChecked = true;
      const values = this.getFilterStateWithDate();
      this.filterValueChange.emit(values);
    }
  }

  private mapSelectedItemIds(
    selectedItems: Record<'id' | 'name', number | string>[]
  ): (number | string)[] {
    if (!selectedItems) {
      return [];
    }

    return selectedItems.map((value) => {
      if (Number.isNaN(+value.id)) {
        return value.id;
      }

      return +value.id;
    });
  }

  private emitWithoutDate(selectedItems: Record<'id' | 'name', number | string>[]) {
    let idOfArray;
    if (!(selectedItems == null || selectedItems.length <= 0)) {
      this.isDisabled = false;
      this.isChecked = true;
      idOfArray = this.mapSelectedItemIds(selectedItems);
      this.idOfSelectedItems = idOfArray;
    } else {
      this.isDisabled = true;
      this.isChecked = false;
    }
    this.selected.next(selectedItems);
    this.filterValueChange.emit({ [this.filterKey]: idOfArray });
  }

  private getDateInterval(dateIntervalPicker: DateInterval): DateInterval {
    if (dateIntervalPicker.start && dateIntervalPicker.end) {
      return dateIntervalPicker;
    }
    return {
      start: '',
      end: '',
    };
  }

  private getFilterStateWithDate(): Record<string, DateIntervalWithIds> {
    return {
      [this.filterKey]: {
        ...this.getDateInterval(this.dateIntervalPicker.getRawValue()),
        id: this.idOfSelectedItems,
      },
    };
  }

  private getFilterStateWithDistanceRange() {
    return {
      [this.filterKey]: {
        id: +this.distanceRangeGroup.get('id').value,
        intValue: +this.distanceRangeGroup.get('intValue').value,
      },
    };
  }

  private setValueForControls(valuesOfControls: any): void {
    if (typeof valuesOfControls !== 'undefined') {
      const sortedItems = this.sortSelectedFromExisting(
        this.withDate ? valuesOfControls.id : valuesOfControls
      );
      this.selectedItems.setValue(sortedItems);
      if (this.withDate) {
        delete valuesOfControls.id;
        this.dateIntervalPicker.setValue(valuesOfControls);
      }

      this.cdr.detectChanges();
    } else {
      this.resetFormControls();
    }
  }

  private sortSelectedFromExisting(arrayOfIds: number[]): Object[] {
    let ids = arrayOfIds;

    if (!Array.isArray(arrayOfIds)) {
      ids = [arrayOfIds];
    }

    return this.transformedData.filter((value) => ids.includes(value.id));
  }

  private resetFormControls(): void {
    this.selectedItems.reset();
    if (this.withDate) {
      this.dateIntervalPicker.reset();
    }
  }

  private setIntervalControlStates(statuses: StatusChangePayload[]) {
    const options = { emitEvent: false, onlySelf: true };

    if (this.withDate && this.dateIntervalPicker) {
      const controls = [this.dateIntervalPicker.get('start'), this.dateIntervalPicker.get('end')];

      if (!Array.isArray(statuses) || statuses.length === 0) {
        this.disableAndResetIntervals(controls, options);
      } else {
        this.enableIntervals(controls, options);
      }
    }

    this.cdr.detectChanges();
  }

  private disableAndResetIntervals(
    controls: AbstractControl[],
    options: { emitEvent: boolean; onlySelf: boolean }
  ) {
    controls.forEach((control) => {
      control.disable(options);
      control.reset(null, options);
    });
    this.hintText = 'candidates.status_select_required_hint';
  }

  private enableIntervals(
    controls: AbstractControl[],
    options: { emitEvent: boolean; onlySelf: boolean }
  ) {
    controls.forEach((control) => {
      control.enable(options);
    });
    this.hintText = '';
  }
}

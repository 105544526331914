import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { positionActionTypes } from '@pages/positions/actions/position.action-types';
import { PositionDto } from '@pages/positions/classes/PositionDto';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { AdvertisementDto } from '@pages/positions/classes/advertisements/AdvertisementDto';
import { AdvertisementDetail } from '@pages/positions/classes/advertisements/AdvertisementDetail';
import { AdvertisementProvider } from '@pages/positions/classes/advertisements/AdvertisementProvider';
import { AdvertisementProviderCardTableRow } from '@pages/positions/classes/advertisements/AdvertisementProviderCardTableRow';
import { AdvertisementImage } from '@pages/positions/classes/advertisements/AdvertisementImage';
import { UpdateCandidatePositionPayload } from '@pages/positions/classes/board/UpdateCandidatePositionPayload';
import { BoardModalTriggerPayload } from '@pages/positions/classes/board/BoardModalTriggerPayload';
import { AddCandidateToPositionPayload } from '@pages/positions/classes/board/AddCandidateToPositionPayload';
import { MoveOrAddPositionDto } from '@pages/positions/classes/board/MoveOrAddPositionDto';

export default {
  createPosition: (positionDto: PositionDto) =>
    createAction(positionActionTypes.createPosition, positionDto),
  updatePosition: (positionDto: PositionDto) =>
    createAction(positionActionTypes.updatePosition, positionDto),
  editPosition: () => createAction(positionActionTypes.editPosition),
  refreshDetails: (positionDetail: PositionDetail) =>
    createAction(positionActionTypes.refreshDetails, positionDetail),
  editAdvertisement: () => createAction(positionActionTypes.editAdvertisement),
  updateAdvertisement: (advertisementDto: AdvertisementDto) =>
    createAction(positionActionTypes.updateAdvertisement, advertisementDto),
  refreshAdvertisementDetail: (advertisementDetail: AdvertisementDetail) =>
    createAction(positionActionTypes.refreshAdvertisementDetail, advertisementDetail),
  openAddProvider: () => createAction(positionActionTypes.openAddProvider),
  createProvider: (provider: AdvertisementProvider) =>
    createAction(positionActionTypes.createProvider, provider),
  updateProvider: (provider: AdvertisementProvider) =>
    createAction(positionActionTypes.updateProvider, provider),
  updateProviderStatus: (provider: AdvertisementProviderCardTableRow) =>
    createAction(positionActionTypes.updateProviderStatus, provider),
  deleteProvider: () => createAction(positionActionTypes.deleteProvider),
  uploadImage: (image: File) => createAction(positionActionTypes.uploadImage, image),
  deleteImage: (image: AdvertisementImage) => createAction(positionActionTypes.deleteImage, image),
  refreshImageList: () => createAction(positionActionTypes.refreshImageList),
  refreshProviderList: () => createAction(positionActionTypes.refreshProviderList),
  openModalToUpdateProvider: (provider?: AdvertisementProviderCardTableRow) =>
    createAction(positionActionTypes.openModalToUpdateProvider, provider),
  openModalToUploadImage: () => createAction(positionActionTypes.openModalToUploadImage),
  openModalToSearchCandidate: () => createAction(positionActionTypes.openModalToSearchCandidate),
  openBoardTriggeredModal: (payload: BoardModalTriggerPayload) =>
    createAction(positionActionTypes.openBoardTriggeredModal, payload),
  updateCandidatePosition: (payload: UpdateCandidatePositionPayload) =>
    createAction(positionActionTypes.updateCandidatePosition, payload),
  restoreBoardToPreviousState: () => createAction(positionActionTypes.restoreBoardToPreviousState),
  confirmBoardModalAction: (payload: Partial<MoveOrAddPositionDto>) =>
    createAction(positionActionTypes.confirmBoardModalAction, payload),
  dismissRejectCandidate: () => createAction(positionActionTypes.dismissRejectCandidate),
  addCandidateToPosition: (payload: AddCandidateToPositionPayload) =>
    createAction(positionActionTypes.addCandidateToPosition, payload),
  triggerBoardRefresh: () => createAction(positionActionTypes.triggerBoardRefresh),
  navigateToCandidateDetail: (payload: { candidateId: number }) =>
    createAction(positionActionTypes.navigateToCandidateDetail, payload),
  bulkEditPosition: () => createAction(positionActionTypes.bulkEditPosition),
  refreshPositionTable: () => createAction(positionActionTypes.refreshPositionTable),
};

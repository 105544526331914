import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { TranslateService } from '@ngx-translate/core';
import { BulkEditService } from '@shared/modules/bulk-edit-bar/services/bulk-edit.service';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import bulkEditActions from '@shared/modules/bulk-edit-bar/actions/bulk-edit.actions';

@UntilDestroy()
@Component({
  selector: 'app-finalized-bulk-edit-modal',
  templateUrl: './finalized-bulk-edit-modal.component.html',
  styleUrls: ['./finalized-bulk-edit-modal.component.scss'],
})
export class FinalizedBulkEditModalComponent implements OnInit, OnDestroy {
  content$ = new BehaviorSubject<{ type: string; successful: number; unsuccessful: number }>({
    type: '',
    successful: 0,
    unsuccessful: 0,
  });
  private readonly bulkEditService: BulkEditService;
  type: string;
  successful: number;
  unsuccessful: number;

  constructor(
    private modalStateService: ModalStateService,
    private translate: TranslateService,
    private injector: Injector
  ) {
    this.bulkEditService = this.injector.get<BulkEditService>(BulkEditService);
  }

  ngOnInit(): void {
    this.modalStateService.setState({ isModalDataLoading: false });
    this.initContent();
    this.listenFinishButtonClick();
  }

  private initContent(): void {
    const { type, error, selectedItems } = this.bulkEditService.getStateSnapshot();
    const bulkEditStateKey = this.bulkEditService.bulkEditStateHelper(type);
    this.bulkEditService
      .select(bulkEditStateKey)
      .pipe(
        tap(() => {
          let successful;
          let unsuccessful;
          if (typeof error === 'undefined' || !Array.isArray(error)) {
            successful = 0;
            unsuccessful = selectedItems;
          } else {
            successful = selectedItems - error.length;
            unsuccessful = error.length;
          }
          this.content$.next({
            type: `common.${type.toLocaleLowerCase()}`,
            successful,
            unsuccessful,
          });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private listenFinishButtonClick(): void {
    this.modalStateService.eventBus
      .on(modalActionTypes.saveButtonClick)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.bulkEditService.eventBus.dispatch(bulkEditActions.refreshAfterFinished());
        this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
      });
  }

  ngOnDestroy(): void {
    this.modalStateService.resetModalStateOnDestroy();
    this.bulkEditService.resetBulkEditState();
  }
}

import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/table/classes/ICustomCellComponent';
import { ProjectStatus } from '@pages/partners/classes/ProjectStatus';
import { BehaviorSubject } from 'rxjs';
import tableAction from '@shared/modules/table/actions/table.actions';
import { TableService } from '@shared/services/table.service';
import { TogglePositionStatus } from '@pages/positions/classes/TogglePositionStatus';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';

@Component({
  selector: 'app-switch-table-cell',
  templateUrl: './switch-table-cell.component.html',
  styleUrls: ['./switch-table-cell.component.scss'],
})
export class SwitchTableCellComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<any>;
  beforeChange$ = new BehaviorSubject<boolean>(false);
  statuses = ProjectStatus;
  bulkEditPermission = [UserPermission.BulkEdit];

  constructor(private tableService: TableService, private auth: AuthService) {}

  onToggleChange(value) {
    if (AuthUtils.isContainUserPermission(this.bulkEditPermission, this.auth.getUserRoles())) {
      const togglePos: TogglePositionStatus = {
        id: value.id,
        positionName: value.positionName,
        isActive: value.status === this.statuses.Active,
      };
      this.tableService.eventBus.dispatch(tableAction.togglePositionStatus(togglePos));
    }
  }
}

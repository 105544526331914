import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { getStringId } from '@shared/utils/get-string-id.util';
import { SelectComponent } from '@shared/modules/form-components/select/select.component';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { TagDto } from '@pages/positions/classes/advertisements/TagDto';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '@config/app.constant';
import { inRange } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tags-autocomplete',
  templateUrl: './tags-autocomplete.component.html',
  styleUrls: ['./tags-autocomplete.component.scss'],
})
export class TagsAutocompleteComponent implements OnInit {
  @ViewChild(SelectComponent) select: SelectComponent;
  @Input() tagArray: FormArray;
  @Input() existingTags: TagDto[];
  @Input() label = 'positions.advertisements.tags';
  addTagControl = new FormControl();
  errorToastId: string;

  constructor(private toast: ToastService, private translate: TranslateService) {}

  ngOnInit() {
    const validator = this.tagArray.validator;
    if (validator) {
      if (validator({} as AbstractControl)?.required) {
        this.listenSelectedTagChange();
        setTimeout(() => {
          this.tagArray.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }, 100);
      }
    }
  }

  onRemoveTag(index: number): void {
    this.tagArray.removeAt(index);
  }

  onTagSelected(tag: TagDto) {
    this.onAddTag(tag.name, tag.id as number);
    this.resetSelection();
  }

  onAddTag(tagName: string, id?: number): void {
    if (this.checkIsTagInvalid(tagName)) {
      let errorMessage = '';
      if (this.checkIsTagExists(tagName)) {
        errorMessage = this.translate.instant('positions.advertisements.existing_tag_error', {
          tagName,
        });
      } else if (tagName.length < AppConstants.minInputLength) {
        errorMessage = 'validation_errors.minlength';
      } else {
        errorMessage = 'validation_errors.maxlength';
      }

      const toastRef = this.toast.showError(errorMessage);

      if (this.errorToastId) {
        this.toast.removeToast(this.errorToastId);
      }

      this.errorToastId = toastRef.getToast().id;
      return;
    }

    this.tagArray.push(
      new FormGroup({
        id: new FormControl(id || getStringId()),
        name: new FormControl(tagName),
      })
    );

    this.resetSelection();
  }

  private checkIsTagInvalid(tag: string): boolean {
    return (
      this.checkIsTagExists(tag) ||
      !inRange(tag.length, AppConstants.minInputLength, AppConstants.maxInputLength + 1)
    );
  }

  private checkIsTagExists(tag: string): boolean {
    return this.tagArray.controls.some((tagGroup) => tagGroup.get('name').value === tag);
  }

  private resetSelection(): void {
    setTimeout(() => {
      this.addTagControl.patchValue(null);
      this.addTagControl.updateValueAndValidity();
      this.select.selectComponent.close();
      this.select.selectComponent.detectChanges();
    });
  }

  private listenSelectedTagChange(): void {
    this.tagArray.valueChanges
      .pipe(
        tap((tag: FormArray) => {
          this.addTagControl.setErrors(tag.length <= 0 ? { required: true } : null);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputBaseComponent } from '@shared/modules/text-field/form-input-base.component';
import { TextFieldComponent } from '@shared/modules/text-field/text-field.component';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { HintModule } from '@shared/modules/hint/hint.module';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { CustomDirectivesModule } from '@shared/modules/custom-directives/custom-directives.module';

@NgModule({
  declarations: [FormInputBaseComponent, TextFieldComponent],
  imports: [
    CommonModule,
    TranslateModule,
    InlineSVGModule,
    HintModule,
    CustomPipesModule,
    FormComponentsModule,
    CustomDirectivesModule,
  ],
  exports: [TextFieldComponent],
})
export class TextFieldModule {}

<nav class="side-navigation d-flex justify-content-between flex-column">
    <ul>
        <ng-container *ngFor="let sidenavItem of sidenavItems">
            <li *appUserHasRole="sidenavItem.roles"  routerLinkActive="link-active">
                <ng-container *ngIf="!sidenavItem.navigateTo; else hasNavigationRoute">
                    <div class="icon-container" [inlineSVG]="sidenavItem.icon"></div>
                </ng-container>
                <ng-template #hasNavigationRoute>
                    <a [routerLink]="sidenavItem.navigateTo" class="icon-container" [inlineSVG]="sidenavItem.icon"></a>
                </ng-template>
            </li>
        </ng-container>
    </ul>
    <ul class="bottom-nav">
        <li routerLinkActive="link-active">
            <div *ngIf="(me$ | async)?.hasUnreadNotification" class="notification-indicator"></div>
            <a routerLink="notifications" class="icon-container" inlineSVG="assets/image/bell-outline.svg"></a>
        </li>
        <li routerLinkActive="link-active">
            <a routerLink="users" class="icon-container">
                <img class="profile-image" [src]="(me$ | async)?.avatarThumb || 'assets/image/person-placeholder.svg'" alt="Profile icon">
            </a>
        </li>
        <li class="version-number">{{appVersion}}</li>
    </ul>
</nav>

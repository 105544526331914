import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { FilteringManageService } from '@shared/modules/filtering/services/filtering-manage.service';
import { SaveUsersFilter } from '@shared/classes/users/SaveUsersFilter';

@UntilDestroy()
@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  filterVisibilityOption = [
    { id: false, name: 'Privát', isPublic: false },
    { id: true, name: 'Nyilvános', isPublic: true },
  ];
  modalText$: Observable<string>;
  isEditMode: boolean;
  constructor(
    private modalStateService: ModalStateService,
    private filteringManageService: FilteringManageService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.modalText$ = this.modalStateService.select('modalText');
    this.isEditMode = this.modalStateService.getStateSnapshot().isEditMode;
    const { name, isPublic } = this.filteringManageService.getStateSnapshot()?.filter;
    this.initForm(this.isEditMode, { name, isPublic });
    this.listenSaveButtonClick();
    this.listenFormValueChanges();
    this.modalStateService.setState({ isModalDataLoading: false });
  }

  initForm(isEditMode: boolean, { name, isPublic }: Partial<SaveUsersFilter>) {
    this.form = this.formBuilder.group({
      filterName: new FormControl(this.isEditMode ? name : null, [Validators.required]),
      filterVisibility: new FormControl(this.isEditMode ? isPublic : null, [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  listenFormValueChanges() {
    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        debounceTime(300),
        map(() => {
          return this.form.invalid;
        }),
        untilDestroyed(this)
      )
      .subscribe((isSaveButtonDisabled: boolean) => {
        this.modalStateService.setState({ isSaveButtonDisabled });
        this.filteringManageService.setFilterManageState({
          name: this.form.get('filterName').value,
          isPublic: this.form.get('filterVisibility').value,
        });
      });
  }

  private listenSaveButtonClick(): void {
    this.filteringManageService.saveFilter(this.isEditMode).pipe(untilDestroyed(this)).subscribe();
  }
}

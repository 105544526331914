import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AppConstants } from '@config/app.constant';
import { FormatError } from '@shared/classes/FormatError';
import { UserApiService } from '@pages/users/services/user-api.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmailValidatorResponse } from '@pages/users/classes/EmailValidatorResponse';

export class FormCustomValidators {
  static isSameValidator(compareField) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.parent || !control) {
        return null;
      }

      return control.value === control.parent.get(compareField).value
        ? null
        : { isSame: control.value };
    };
  }

  static shouldBeNotSameAsOldValue(compareField) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.parent || !control) {
        return null;
      }
      return control.value === control.parent.get(compareField).value
        ? { shouldBeNotSameAsOldValue: control.value }
        : null;
    };
  }

  static emailValidator(isRequired = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';

      if (!value && !isRequired) {
        return null;
      }

      return new FormatError(
        !!value.match(AppConstants.emailRegex),
        'email',
        'common.email'
      ).getError();
    };
  }

  static taxNumberValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';
      return new FormatError(
        !!value.match(AppConstants.taxNumberRegex),
        'taxNumber',
        'common.tax_number'
      ).getError();
    };
  }

  static feorNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';
      return new FormatError(
        !!value.match(AppConstants.feorNumberRegex),
        'feorNumber',
        'positions.feor_number'
      ).getError();
    };
  }

  static projectCodeValidator(isRequired: boolean) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';

      if (!isRequired && !value) {
        return null;
      }

      return new FormatError(
        !!value.match(AppConstants.projectCode),
        'taxNumber',
        'common.project_code'
      ).getError();
    };
  }

  static zipCodeValidator(isRequired: boolean) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';

      if (!isRequired && !value) {
        return null;
      }

      return new FormatError(
        !!value.match(AppConstants.zipCodeRegex),
        'zipCode',
        'common.zip_code'
      ).getError();
    };
  }

  static phoneNumberValidator(isRequired: boolean) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';

      if (!isRequired && !value) {
        return null;
      }

      return new FormatError(
        !!value.match(AppConstants.phoneRegex),
        'phone',
        'common.phone_number'
      ).getError();
    };
  }

  static omIdentifierValidator(isRequired = true) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';

      if (!isRequired && !value) {
        return null;
      }

      return new FormatError(
        !!value.match(AppConstants.omIdentifierRegex),
        'omIdentifier',
        'common.om_identifier'
      ).getError();
    };
  }

  static pensionerIdentifierValidator(isRequired = true) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';

      if (!isRequired && !value) {
        return null;
      }

      return new FormatError(
        !!value.match(AppConstants.pensionerIdentifierRegex),
        'pensionerIdentifier',
        'common.identifier'
      ).getError();
    };
  }

  static emailExistsAsyncValidator(usersService: UserApiService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control?.value) {
        return of(null);
      }

      return usersService
        .checkEmailExists({
          email: control.value,
        })
        .pipe(
          map((result: EmailValidatorResponse) => {
            return result.isExists ? { isExists: true } : null;
          }),
          catchError(() => {
            return of({
              asyncValidationError: true,
            });
          })
        );
    };
  }

  // Csak kis és nagybetűket, valamint pont karaktert fogad fogad el
  static nameValidator() {
    return Validators.pattern('^[A-Za-záéiíóöőúüűÁEÉÍÓÖŐÚÜŰ0-9 ./-]{0,50}$');
  }

  static userNameValidator() {
    return Validators.pattern('^[0-9a-zA-Z.@]{6,30}$');
  }

  static numberValidator() {
    return Validators.pattern('^[0-9]*$');
  }

  // minden országra rendszám vizsgálat
  static plateNumberValidator() {
    return Validators.pattern('^[a-zA-Z0-9- ]+$');
  }

  static numberPositiveValidator() {
    return Validators.pattern('^[[1-9]{1}[0-9]*$');
  }

  static passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control?.value as string) || '';
      return new FormatError(
        !!value.match(AppConstants.passwordRegex),
        'password',
        'common.password'
      ).getError();
    };
  }
}

<div class="datatable-container custom-table" [ngClass]="{ 'hide-checkboxes': hideCheckboxes }">
    <ngx-datatable
        appDataTableRowListener
        [dataChange$]="dataChange$.asObservable()"
        (mouseWheelPushed)="onMouseWheelPushed($event)"
        class="datatable"
        [rows]="data"
        [columns]="columnConfigWithCustomCells"
        [columnMode]="columnMode.force"
        [scrollbarV]="true"
        [scrollbarH]="false"
        [draggable]="false"
        [virtualization]="true"
        [headerHeight]="headerHeight"
        [rowHeight]="rowHeight"
        [messages]="messages"
        [selectAllRowsOnPage]="false"
        [externalSorting]="true"
        [selectionType]="SelectionType.checkbox"
        (scroll)="onScroll($event.offsetY)"
        (activate)="onActivate($event)"
        (select)="onSelect($event)"
        (sort)="sortBy.emit($event)"
        (resize)="onResize()">
        <ng-content></ng-content>
    </ngx-datatable>
</div>

<a id="hidden-anchor" href="#" target="_blank" rel="noopener" hidden></a>


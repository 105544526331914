import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { PositionFilter } from '@pages/positions/classes/PositionFilter';
import { AdvertisementDetail } from '@pages/positions/classes/advertisements/AdvertisementDetail';
import { AdvertisementProvider } from '@pages/positions/classes/advertisements/AdvertisementProvider';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';

export interface PositionState {
  positionDetail: PositionDetail;
  isDetailLoaded: boolean;
  positionFilters: PositionFilter;
  firstLetters: string[];
  advertisementDetail: AdvertisementDetail;
  isAdvertisementDetailLoaded: boolean;
  providers: AdvertisementProvider[];
  selectedProvider: AdvertisementProvider;
  boardColumns: BoardColumn[];
  boardCandidates: BoardCandidate[];
  selectedBoardColumn: BoardColumn;
  selectedCandidate: BoardCandidate;
  selectedPositionType: PositionTypes;
  isBoardLoading: boolean;
  previousCandidateMap: Map<number, BoardCandidate[]>;
}

export const positionInitialState: PositionState = {
  positionDetail: undefined,
  isDetailLoaded: false,
  positionFilters: {
    name: '',
    code: '',
    partner_id: [],
    project_id: [],
    project_manager_id: [],
    specialization_id: [],
    tag_id: [],
    status: [],
    type: [],
    letter_filter: '',
    sortBy: [],
  },
  firstLetters: [],
  advertisementDetail: undefined,
  isAdvertisementDetailLoaded: false,
  selectedProvider: undefined,
  providers: [],
  boardColumns: [],
  boardCandidates: [],
  selectedBoardColumn: undefined,
  selectedCandidate: undefined,
  isBoardLoading: false,
  previousCandidateMap: undefined,
  selectedPositionType: undefined,
};

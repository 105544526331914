import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@shared/modules/button/button.module';
import { TextFieldModule } from '@shared/modules/text-field/text-field.module';
import { CheckboxModule } from '@shared/modules/checkbox/checkbox.module';
import { AuthModule } from './modules/auth/auth.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    ButtonModule,
    TextFieldModule,
    CheckboxModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AuthModule,
    ButtonModule,
    TextFieldModule,
    CheckboxModule,
  ],
  providers: [],
})
export class SharedModule {}

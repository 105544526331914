<ng-container [formGroup]="group">
    <app-form-field
        *ngIf="!(isPositionListLoading$ | async) && !isPositionListLoadErrorOccurred; else positionListLoading">
        <app-select
            label="candidates.attached_positions"
            placeholder="common.positions"
            [options]="positions$ | async"
            [searchable]="true"
            [virtualScroll]="true"
            bindLabel="name"
            bindValue="id"
            bindValueByPropertyNameToControl="id"
            formControlName="positionId">
            <app-document-position-selection-option-template
              #optionsTemplate>
            </app-document-position-selection-option-template>
        </app-select>
    </app-form-field>

    <ng-template #positionListLoading>
        <div class="d-flex justify-content-center mt-10px">
            <app-loading></app-loading>
        </div>
    </ng-template>
</ng-container>

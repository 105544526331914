import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { EndpointsConfig } from '@config/endpoints.config';
import { ListData } from '@shared/classes/ListData';
import { CandidateListItem } from '@pages/candidates/classes/CandidateListItem';
import { CandidateDto } from '@pages/candidates/classes/CandidateDto';
import { NormalizedCandidateDto } from '@pages/candidates/classes/NormalizedCandidateDto';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateDocument } from '@pages/candidates/classes/CandidateDocument';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { AppConstants } from '@config/app.constant';
import { CandidatePosition } from '@pages/candidates/classes/CandidatePosition';
import { CandidateBulkUpdate } from '@pages/candidates/classes/CandidateBulkUpdate';
import { CandidateBulkUpdateDto } from '@pages/candidates/classes/CandidateBulkUpdateDto';
import { BulkEditResponse } from '@shared/classes/BulkEditResponse';
import { CandidateAttachPositionDto } from '@pages/candidates/classes/CandidateAttachPositionDto';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { CandidateFilter } from '@pages/candidates/classes/CandidateFilter';
import { CandidatePositionMinimal } from '@pages/candidates/classes/CandidatePositionMinimal';

@Injectable({
  providedIn: 'root',
})
export class CandidateApiService {
  constructor(private http: HttpService, private atsConfigService: ATSConfigService) {}

  getCandidates(
    page: string,
    perPage: string,
    filter?: string
  ): Observable<ListData<CandidateListItem>> {
    const params: { [k: string]: any } = {
      page,
      perPage,
    };
    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.candidates, params);
  }

  getCandidateFirstLetters(): Observable<string[]> {
    return this.http.get(EndpointsConfig.candidatesFirstLetters);
  }

  getCandidateFilters(): Observable<CandidateFilter> {
    return this.http.get(EndpointsConfig.candidatesFilters);
  }

  createCandidate(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.post(EndpointsConfig.candidates, this.normalizeCandidateDto(candidateDto));
  }

  updateCandidate(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.put(
      EndpointsConfig.candidatesById(candidateDto.id),
      this.normalizeCandidateDto(candidateDto)
    );
  }

  getCandidateById(id: number): Observable<CandidateDetail> {
    return this.http.get(EndpointsConfig.candidatesById(id));
  }

  getDocuments(id: number): Observable<CandidateDocument[]> {
    return this.http.get(EndpointsConfig.candidateDocuments(id));
  }

  uploadDocument(candidateId: number, formData: FormData): Observable<CandidateDocument> {
    return this.http.post(EndpointsConfig.candidateDocuments(candidateId), formData);
  }

  getHistory(candidateId: number, page = 1): Observable<ListData<HistoryMessage>> {
    return this.http.get(EndpointsConfig.candidateHistory(candidateId), {
      page: page.toString(),
      perPage: AppConstants.historyMessagesPerPage.toString(),
    });
  }

  createHistoryMessage(candidateId: number, message: string): Observable<HistoryMessage> {
    return this.http.post(EndpointsConfig.candidateHistory(candidateId), { message });
  }

  updateHistoryMessage(
    candidateId: number,
    historyId: number,
    message: string
  ): Observable<HistoryMessage> {
    return this.http.put(EndpointsConfig.candidateHistoryWithId(candidateId, historyId), {
      message,
    });
  }

  deleteHistoryMessage(candidateId: number, historyId: number): Observable<unknown> {
    return this.http.delete(EndpointsConfig.candidateHistoryWithId(candidateId, historyId));
  }

  getCandidatePositions(
    candidateId: number,
    page = 1,
    perPage = AppConstants.cardTableRowsPerPage
  ): Observable<ListData<CandidatePosition>> {
    return this.http.get(EndpointsConfig.candidatePosition(candidateId), {
      page: page.toString(),
      perPage: perPage.toString(),
    });
  }

  getAllPositionsAttachedToCandidate(candidateId: number): Observable<CandidatePositionMinimal[]> {
    return this.http.get(EndpointsConfig.candidateAllPositions(candidateId));
  }

  bulkUpdateCandidate(candidateBulkUpdate: CandidateBulkUpdate): Observable<BulkEditResponse> {
    return this.http.put(
      EndpointsConfig.candidatesBulkEdit,
      this.normalizeCandidateBulkEdit(candidateBulkUpdate)
    );
  }

  attachToPosition(
    candidateId: number,
    dto: CandidateAttachPositionDto
  ): Observable<PositionDetail> {
    return this.http.post(EndpointsConfig.candidatePositionAttach(candidateId), dto);
  }

  detachPosition(candidateId: number, positionId: number): Observable<unknown> {
    return this.http.delete(EndpointsConfig.candidatePositionDetach(candidateId, positionId));
  }

  setCandidateProfileReadStatus(candidateId: number): Observable<CandidateDetail> {
    return this.http.put(EndpointsConfig.candidateSetProfileReadStatus(candidateId), {});
  }

  private normalizeCandidateBulkEdit(
    candidate: CandidateBulkUpdate
  ): Partial<CandidateBulkUpdateDto> {
    return {
      candidateIds: candidate.candidateIds,
      membershipEndDate: candidate?.membershipEndDate?.name || undefined,
      statusId: +candidate?.statusId?.id || undefined,
      statusOptionId: +candidate?.statusOptionId?.id || undefined,
      positionId: +candidate?.positionId?.id || undefined,
      subPositionId: +candidate?.subPositionId?.id || undefined,
      membershipStartDate: candidate?.membershipStartDate?.name || undefined,
      feorNumber: candidate?.feorNumber?.name || undefined,
    };
  }

  exportCandidateTable(filter: string): Observable<any> {
    let params;
    if (filter) {
      params = {
        filter,
      };
    }
    return this.http.getFile(EndpointsConfig.candidatesExport, params || null);
  }

  private normalizeCandidateDto(candidateDto: CandidateDto): NormalizedCandidateDto {
    const normalized: NormalizedCandidateDto = {
      ...candidateDto,
      cityPreferences: [],
      jobPreferences: [],
      shifts: [],
    };

    if (!normalized.id) {
      delete normalized.id;
    }

    if (!normalized.profile.id) {
      delete normalized.profile.id;
    }

    if (typeof normalized.profile.providerType.id === 'string') {
      normalized.profile.providerType.id = null;
    }

    if (!this.atsConfigService.isStudentATS) {
      normalized.profile.parents = null;
    }

    normalized.jobTypePreferences = candidateDto.jobTypePreferences.filter((jobType) => {
      return !!jobType.typeId;
    });

    normalized.languages = candidateDto.languages.filter((language) => {
      return !!language.languageId;
    });

    normalized.cityPreferences = candidateDto.cityPreferences
      .map((city) => city.id)
      .filter((value) => !!value);

    normalized.jobPreferences = candidateDto.jobPreferences
      .map((specialization) => specialization.id)
      .filter((value) => !!value);

    if (typeof candidateDto.shifts !== 'undefined') {
      normalized.shifts = candidateDto.shifts.map((shift) => +shift.id).filter((value) => !!value);
    } else {
      delete normalized.shifts;
    }

    if (Array.isArray(candidateDto.skills)) {
      normalized.skills = candidateDto.skills.map((skill) => {
        const mappedSkill = { ...skill };

        if (typeof mappedSkill.id === 'string') {
          mappedSkill.id = null;
        }

        return mappedSkill;
      });
    }

    return normalized;
  }
}

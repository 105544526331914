export const partnerActionTypes = {
  editPartner: '[Partner detail] - edit partner',
  createProject: '[Partner detail] - create project',
  editProject: '[Project detail] - edit project',
  navigateBack: '[Partner detail] - navigate back',
  toggleProjectStatus: '[Partner detail] - toggle project status',
  navigateToProject: '[Partner detail] - navigate to given project',
  deleteProject: '[Partner detail] - delete a given project',
  saveAsPartnerFilter: '[Partner filter] - save as a partner filter',
  deletePartnerFilter: '[Partner filter] - delete partner filter',
  projectUpdated: '[Project detail] - project updated',
  partnerUpdated: '[Partner detail] - partner updated',
  bulkEditPartner: '[Partner bulk edit] - partner bulk edit',
  openModalToDeletePosition: '[Project detail] - open confirm modal to delete position',
  deletePosition: '[Project detail] - delete position',
  togglePositionStatus: '[Project detail] - toggle position status',
};

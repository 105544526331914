export class EndpointsConfig {
  static login = '/auth/login/email';
  static logout = '/auth/logout';
  static refresh = '/auth/refresh';
  static resetPassword = '/auth/reset-password';
  static forgotPassword = '/auth/forgot-password';
  static setNewPassword = '/users/update-password';
  static me = '/users/me';
  static usersFilters = '/users/filters';
  static partnersFilters = '/partners/filters';
  static partnersFirstLetters = '/partners/first-letter';
  static partnersBulkEdit = '/partners/bulk-update';
  static partnersExport = '/partners/export';
  static users = '/users';
  static usersListByRole = '/users/list-by-roles';
  static usersCheckEmail = '/users/check';
  static countries = '/countries';
  static offices = '/offices';
  static partners = '/partners';
  static candidates = '/candidates';
  static candidatesFirstLetters = '/candidates/first-letter';
  static candidatesFilters = '/candidates/filters';
  static candidatesBulkEdit = '/candidates/bulk-update';
  static candidatesExport = '/candidates/export';
  static sources = '/sources';
  static jobTypes = '/job-types';
  static specializations = '/specializations';
  static languages = '/languages';
  static languageLevels = '/languages/levels';
  static documentTypes = '/candidates/document-types';
  static userAvatar = '/users/avatar';
  static employmentTypes = '/employment-types';
  static partnersDropdown = '/partners/dropdown';
  static positions = '/positions';
  static positionTypes = '/positions/types';
  static positionsFirstLetters = '/positions/first-letter';
  static positionsFilters = '/positions/filters';
  static positionsBulkEdit = '/positions/bulk-update';
  static positionsExport = '/positions/export';
  static educationTypes = '/education-types';
  static workExperiences = '/work-experiences';
  static boardColumns = '/positions/statuses';
  static tags = '/tags';
  static providerTypes = '/provider-types';
  static skills = '/skills';
  static jobPreferences = '/job-preferences';
  static shifts = '/shifts';
  static boardColumnsByPositionId = (positionId: number) => `/positions/${positionId}/statuses`;
  static positionsDropdown = (projectId: number) => `/projects/${projectId}/positions/dropdown`;
  static notifications = (userId: number) => `users/${userId}/notifications`;
  static notificationsMarkAsRead = (userId: number) =>
    `/users/${userId}/notifications/mark-as-read`;
  static reSendNotification = (userId: number) => `/users/${userId}/notifications/re-send`;
  static checkRoleChange = (userId: number) => `/users/${userId}/check-role-change`;
  static checkHasRelations = (userId: number) => `/users/${userId}/check-has-relation`;
  static updateUserRole = (userId: number) => `/users/${userId}/role`;
  static userById = (userId: number) => `/users/${userId}`;
  static boardCandidates = (positionId: number) => `/positions/${positionId}/candidates`;
  static searchBoardCandidate = (positionId: number) =>
    `/positions/${positionId}/candidates/search`;
  static moveOrAddBoardCandidate = (positionId: number, candidateId: number) =>
    `/positions/${positionId}/candidates/${candidateId}`;
  static providers = (advertisementId: number) => `/vacancies/${advertisementId}/providers`;
  static providerById = (advertisementId: number, providerId: number) =>
    `/vacancies/${advertisementId}/providers/${providerId}`;
  static positionStatus = (positionId: number) => `/positions/${positionId}/status`;
  static providerStatus = (advertisementId: number, providerId: number) =>
    `/vacancies/${advertisementId}/providers/${providerId}/status`;
  static advertisement = (positionId: number) => `/positions/${positionId}/vacancies`;
  static advertisementById = (positionId: number, advertisementId: number) =>
    `/positions/${positionId}/vacancies/${advertisementId}`;
  static advertisementImage = (advertisementId: number) =>
    `/vacancies/${advertisementId}/documents`;
  static advertisementImageById = (advertisementId: number, imageId: number) =>
    `/vacancies/${advertisementId}/documents/${imageId}`;
  static positionById = (positionId: number) => `/positions/${positionId}`;
  static positionHistory = (positionId: number) => `/positions/${positionId}/history`;
  static positionHistoryWithId = (positionId: number, historyId: number) =>
    `/positions/${positionId}/history/${historyId}`;
  static downloadCandidateDocuments = (candidateId: number, documentId: number) =>
    `/candidates/${candidateId}/document/${documentId}`;
  static candidateSetProfileReadStatus = (candidateId: number) => `candidates/${candidateId}/read`;
  static candidatePosition = (candidateId: number) => `/candidates/${candidateId}/positions`;
  static candidateAllPositions = (candidateId: number) =>
    `/candidates/${candidateId}/positions/dropdown`;
  static candidateHistory = (candidateId: number) => `/candidates/${candidateId}/history`;
  static candidateHistoryWithId = (candidateId: number, historyId: number) =>
    `/candidates/${candidateId}/history/${historyId}`;
  static candidateDocuments = (candidateId: number) => `/candidates/${candidateId}/document`;
  static candidatesById = (candidateId: number) => `/candidates/${candidateId}`;
  static candidatePositionAttach = (candidateId: number) =>
    `/candidates/${candidateId}/positions/attach`;
  static candidatePositionDetach = (candidateId: number, positionId: number) =>
    `/candidates/${candidateId}/positions/${positionId}`;
  static citySearch = (countryId: number) => `/countries/${countryId}/cities/search`;
  static cities = (countryShortName: string) => `/countries/${countryShortName}/cities`;
  static partnerById = (partnerId: number) => `/partners/${partnerId}`;
  static partnerHistory = (partnerId: number) => `/partners/${partnerId}/history`;
  static projects = (partnerId: number) => `/partners/${partnerId}/projects`;
  static projectById = (partnerId: number, projectId: number) =>
    `/partners/${partnerId}/projects/${projectId}`;
  static projectHistory = (projectId: number) => `/projects/${projectId}/history`;
  static projectPositions = (projectId: number) => `/projects/${projectId}/positions`;
  static partnerHistoryById = (partnerId: number, historyId: number) =>
    `/partners/${partnerId}/history/${historyId}`;
  static projectHistoryById = (projectId: number, historyId: number) =>
    `/projects/${projectId}/history/${historyId}`;
  static projectStatus = (partnerId: number, projectId: number) =>
    `/partners/${partnerId}/projects/${projectId}/status`;
}

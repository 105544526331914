import { PartnerBulkUpdate } from '@pages/partners/classes/PartnerBulkUpdate';
import { ApiTypeParams } from '@config/app.constant';
import { CandidateBulkUpdate } from '@pages/candidates/classes/CandidateBulkUpdate';
import { PositionBulkUpdate } from '@pages/positions/classes/PositionBulkUpdate';

export interface BulkEditState {
  type: ApiTypeParams;
  partnerBulkUpdate: PartnerBulkUpdate;
  candidateBulkUpdate: CandidateBulkUpdate;
  positionBulkUpdate: PositionBulkUpdate;
  error: any;
  selectedItems: number;
  isEditMode: boolean;
}

export const bulkEditInitiateState = {
  type: undefined,
  partnerBulkUpdate: undefined,
  candidateBulkUpdate: undefined,
  positionBulkUpdate: undefined,
  error: undefined,
  selectedItems: null,
  isEditMode: false,
};

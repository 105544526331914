import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/table/classes/ICustomCellComponent';

@Component({
  selector: 'app-tooltip-table-cell',
  templateUrl: './tooltip-table-cell.component.html',
  styleUrls: ['./tooltip-table-cell.component.scss'],
})
export class TooltipTableCellComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<any>;
}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';
import { last } from 'lodash-es';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private allHistory: string[] = [];

  constructor(public readonly router: Router, public readonly location: Location) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.allHistory.push(event.urlAfterRedirects);
      }
    });
  }

  get histories(): string[] {
    return this.allHistory;
  }

  popHistory(): void {
    this.allHistory.pop();
  }

  back(fallbackPath: string): void {
    this.allHistory.pop();
    if (this.allHistory.length > 0) {
      this.router.navigateByUrl(last(this.allHistory));
    } else {
      this.router.navigateByUrl(fallbackPath);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { filter, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-start-end-date-picker',
  templateUrl: './start-end-date-picker.component.html',
  styleUrls: ['./start-end-date-picker.component.scss'],
})
export class StartEndDatePickerComponent implements OnInit {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() isEditMode: boolean;
  @Input() minDateControl: FormControl;
  @Input() maxDateControl: FormControl;
  @Input() minDateLabel: string;
  @Input() maxDateLabel: string;

  ngOnInit(): void {
    this.minDateControl.valueChanges
      .pipe(
        startWith(this.minDateControl.value),
        filter(() => this.minDateControl.enabled),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.minDateControl.value) {
          this.maxDateControl.setValidators([Validators.required]);
          this.maxDateControl.enable();
        } else {
          this.maxDateControl.disable();
          this.maxDateControl.setValidators([]);
          this.maxDateControl.setValue(null, { emitEvent: false });
        }
        this.maxDateControl.markAsTouched();
        this.maxDateControl.updateValueAndValidity();
      });
  }
}

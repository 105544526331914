import {
  Directive,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatatableComponent } from '@swimlane/ngx-datatable';

const MOUSE_WHEEL_KEY = 1;
const MOUSE_LEFT_BUTTON_KEY = 0;
type RendererListenerFn = () => void;

@UntilDestroy()
@Directive({
  selector: '[appDataTableRowListener]',
})
export class DataTableRowListenerDirective implements OnInit, OnDestroy {
  @Input() dataChange$: Observable<void>;
  @Output() mouseWheelPushed = new EventEmitter<{ rowData: any; rowElement: HTMLElement }>();
  private listeners: RendererListenerFn[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private datatableComponent: DatatableComponent,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.datatableComponent.activate
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event.type === 'mouseenter') {
          this.listeners = [];
          const mouseDownListener = this.renderer.listen(
            event.rowElement,
            'mousedown',
            ({ button, ctrlKey }: MouseEvent) => {
              if (button === MOUSE_WHEEL_KEY || (ctrlKey && button === MOUSE_LEFT_BUTTON_KEY)) {
                this.mouseWheelPushed.emit({
                  rowElement: event.rowElement,
                  rowData: event.row,
                });
              }
            }
          );
          const listener = this.renderer.listen(event.rowElement, 'mouseleave', () => {
            mouseDownListener();
            listener();
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.listeners.forEach((listener) => listener());
  }
}

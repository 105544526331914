import { Contact } from '@pages/partners/classes/Contact';
import { KeyValue } from '@shared/classes/KeyValue';
import { AppConstants } from '@config/app.constant';

export function getPartnerContactKeyValueData(
  contacts: Contact[],
  contractDate?: string
): KeyValue<string>[] {
  const data = [];

  (contacts || []).forEach((contact, index: number) => {
    data.push(
      {
        key: 'common.name',
        value: contact?.name ?? AppConstants.noDataTranslationKey,
      },
      {
        key: 'common.email',
        value: contact?.email ?? AppConstants.noDataTranslationKey,
      },
      {
        key: 'common.phone_number',
        value: contact?.phone ?? AppConstants.noDataTranslationKey,
      }
    );

    if (contacts?.length - 1 !== index) {
      data.push({
        key: '',
        value: 'line-break',
      });
    }
  });

  if (contractDate) {
    data.push({
      key: 'partners.contract_date',
      value: contractDate,
    });
  }

  return data;
}

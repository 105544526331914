import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { EventBus } from '@shared/modules/event-bus/classes/EventBus';
import { isEqual } from 'lodash-es';

@Injectable()
export abstract class EventBusService<T> {
  private state$: BehaviorSubject<T>;
  readonly eventBus: EventBus;

  protected constructor(initialState?: T) {
    this.state$ = new BehaviorSubject<T>(initialState);
    this.eventBus = new EventBus();
  }

  getState(): Observable<T> {
    return this.state$.asObservable();
  }

  getStateSnapshot(): T {
    return this.state$.getValue();
  }

  select<K extends keyof T>(key: K): Observable<T[K]> {
    return this.getState().pipe(pluck(key));
  }

  setState(stateSlice: Partial<T>): void {
    const newState = { ...this.getStateSnapshot(), ...stateSlice };

    if (!isEqual(this.getStateSnapshot(), newState)) {
      this.state$.next(newState);
    }
  }
}

import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import { userInitialState, UserState } from '@shared/modules/event-bus/state/users/user.state';
import { merge, Observable, of, throwError } from 'rxjs';
import { User } from '@shared/modules/auth/classes/User';
import { userActionTypes } from '@pages/users/actions/user.action-types';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mapTo,
  mergeAll,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserApiService } from '@pages/users/services/user-api.service';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { ComponentType } from '@angular/cdk/overlay';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { AvatarUploadResponse } from '@pages/users/classes/AvatarUploadResponse';
import { RoleChangePayload } from '@pages/users/classes/RoleChangePayload';
import { RoleCheckResponse } from '@pages/users/classes/RoleCheckResponse';
import { getStringId } from '@shared/utils/get-string-id.util';
import { ConfirmModalComponent } from '@shared/modules/mat-modal/components/confirm-modal/confirm-modal.component';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { UserDto } from '@pages/users/classes/UserDto';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { flatten, get, head, omit } from 'lodash-es';
import { RelationCheckResponse } from '@pages/users/classes/RelationCheckResponse';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { KeyValue } from '@shared/classes/KeyValue';
import { AppConstants } from '@config/app.constant';
import {
  getUserRoleTranslationKey,
  translateRoleName,
} from '@pages/users/utils/role-to-translation-key.util';
import { SetNewPasswordDto } from '@pages/users/classes/SetNewPasswordDto';
import { UserTableRow } from '@pages/users/classes/UserTableRow';
import { TranslateService } from '@ngx-translate/core';
import { PartnerMinimal } from '@pages/positions/classes/PartnerMinimal';
import { HttpErrorResponse } from '@angular/common/http';
import { userErrorMessages } from '@pages/users/config/user-error-messages.config';
import modalActions from '../../../shared/modules/mat-modal/actions/modal.actions';
import userActions from '../actions/user.actions';

@Injectable({
  providedIn: 'root',
})
export class UserService extends EventBusService<UserState> {
  constructor(
    public readonly userApiService: UserApiService,
    private modalStateService: ModalStateService,
    private modalService: MatModalService,
    private toast: ToastService,
    private translate: TranslateService
  ) {
    super(userInitialState);
  }

  getMe(): Observable<User> {
    return merge(
      of(this.eventBus.on(userActionTypes.getMe), this.eventBus.on(userActionTypes.refreshMe))
    ).pipe(
      mergeAll(),
      switchMap(() => {
        return this.userApiService.getMe().pipe(
          tap((me: User) => {
            this.setState({ me });
          }),
          catchError((err) => of(err))
        );
      })
    );
  }

  openAvatarUploadModal(contentComponent: ComponentType<any>): Observable<any> {
    return this.eventBus.on(userActionTypes.openAvatarUploadModal).pipe(
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          modalTitle: 'users.upload_avatar',
        });

        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });
      })
    );
  }

  uploadAvatar(): Observable<AvatarUploadResponse> {
    return this.eventBus.on(userActionTypes.uploadAvatar).pipe(
      switchMap(({ avatarBase64 }) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.userApiService
          .uploadUserAvatar({
            avatar: avatarBase64,
          })
          .pipe(
            tap((response: AvatarUploadResponse) => {
              this.refreshAvatarState(response?.url);
              this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            }),
            catchError((err: HttpErrorResponse) => {
              return of(err);
            }),
            finalize(() => {
              this.modalStateService.setState({ isSaveButtonDisabled: false });
            })
          );
      })
    );
  }

  updateUserRole(): Observable<any> {
    const confirmModalId = getStringId();

    return this.eventBus.on(userActionTypes.updateUserRole).pipe(
      switchMap((payload: RoleChangePayload) => {
        this.setState({ loadingRowId: payload.user.id });
        return this.userApiService.checkRoleChange(payload.user.id, payload.desiredRole).pipe(
          switchMap((response: RoleCheckResponse) => {
            if (response.hasWarnings) {
              this.modalStateService.setState({
                isModalDataLoading: false,
                isHeaderShown: false,
                approveButtonText: 'common.continue',
                isSaveButtonDisabled: false,
                modalText: 'validation_errors.role_change_warning',
                confirmModalId,
              });

              this.modalService.openDialog({
                width: '460px',
                data: {
                  variant: ModalTypes.DynamicHeight,
                  contentComponent: ConfirmModalComponent,
                },
              });

              return this.waitRoleChangeConfirm(confirmModalId, payload);
            }

            return of(payload);
          }),
          concatMap(() => {
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
            return this.userApiService.updateRole(payload.user.id, payload.desiredRole).pipe(
              tap((user: User) => {
                this.eventBus.dispatch(userActions.roleUpdated(user));
                this.setState({ loadingRowId: 0 });
              })
            );
          }),
          catchError((err) => {
            this.toast.showError('users.role_change_error');
            this.eventBus.dispatch(
              userActions.rollbackRole({
                userId: payload.user.id,
                previousRole: head(payload.user.roles).name,
              })
            );
            this.setState({ loadingRowId: 0 });
            return of(err);
          })
        );
      })
    );
  }

  openManageUserModal(contentComponent: ComponentType<any>): Observable<any> {
    return this.eventBus.on(userActionTypes.openManageUserModal).pipe(
      tap((selectedUser: UserListItem | undefined) => {
        this.modalStateService.setState({
          isModalDataLoading: true,
          modalTitle: !selectedUser ? 'users.add_user' : 'users.edit_user',
        });

        this.modalService.openDialog({
          width: '340px',
          data: {
            contentComponent,
          },
        });

        this.setState({ selectedUser });
      })
    );
  }

  createUser(): Observable<User> {
    return this.eventBus.on(userActionTypes.createUser).pipe(
      switchMap((dto: UserDto) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.userApiService
          .createUser(dto)
          .pipe(this.handleUserManageSideEffects('users.created'));
      })
    );
  }

  updateUser(): Observable<User> {
    return this.eventBus.on(userActionTypes.updateUser).pipe(
      switchMap((dto: UserDto) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.userApiService
          .updateUser(dto)
          .pipe(this.handleUserManageSideEffects('users.modified'));
      })
    );
  }

  refreshUserList(): Observable<UserListItem[]> {
    return this.eventBus.on(userActionTypes.refreshUserList).pipe(
      withLatestFrom(this.select('currentUserRoles')),
      switchMap(([_, roles]: [void, UserRole[]]) => {
        return this.userApiService.getUsersByRole(roles).pipe(
          map((users) => flatten(users)),
          tap((users: UserListItem[]) => {
            this.setState({ users: this.mapUsersToTableRow(flatten(users)) });
          }),
          catchError((err) => throwError(err))
        );
      })
    );
  }

  openUserDeleteConfirm(): Observable<any> {
    const confirmModalId = getStringId();

    return this.eventBus.on(userActionTypes.openUserDeleteConfirmModal).pipe(
      tap(() => {
        this.modalService.openConfirmModal({
          isModalDataLoading: false,
          isHeaderShown: false,
          cancelButtonText: 'common.cancel',
          approveButtonText: 'common.delete_confirm',
          isSaveButtonDisabled: false,
          approveButtonStyle: 'warn',
          modalText: 'users.delete_user_title',
          confirmModalId,
        });
      }),
      switchMap((user: UserListItem) => this.waitForDeleteUserConfirm(confirmModalId, user)),
      switchMap((user: UserListItem) => {
        this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
        return this.userApiService.checkHasRelations(user.id).pipe(
          concatMap((payload: RelationCheckResponse) => {
            const relationModalId = getStringId();

            if (payload.isExists) {
              this.modalService.openConfirmModal({
                isModalDataLoading: false,
                isHeaderShown: false,
                cancelButtonText: 'common.cancel',
                approveButtonText: 'common.continue',
                isSaveButtonDisabled: false,
                modalText: 'users.user_has_relation',
                confirmModalId: relationModalId,
              });

              return this.waitForDeleteUserConfirm(relationModalId, user);
            }

            return of(user);
          }),
          concatMap(() => {
            return this.userApiService
              .deleteUser(user)
              .pipe(this.handleUserManageSideEffects('users.deleted'));
          })
        );
      })
    );
  }

  getTranslatedRoles(mapAllRoles = false): KeyValue<string>[] {
    const roles = mapAllRoles
      ? AppConstants.availableRoles
      : this.getStateSnapshot().currentUserRoles;

    return roles.map((role) => {
      return {
        key: role,
        value: translateRoleName(getUserRoleTranslationKey(role), true),
      };
    });
  }

  openSetNewPasswordModal(contentComponent: ComponentType<any>): Observable<any> {
    return this.eventBus.on(userActionTypes.openSetNewPasswordModal).pipe(
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          modalTitle: 'users.set_new_password',
        });

        this.modalService.openDialog({
          width: '460px',
          data: {
            variant: ModalTypes.DynamicHeight,
            contentComponent,
          },
        });
      })
    );
  }

  setNewPassword(): Observable<any> {
    return this.eventBus.on(userActionTypes.setNewPassword).pipe(
      switchMap((dto: SetNewPasswordDto) => {
        this.modalStateService.setState({ isSaveButtonDisabled: true });
        return this.userApiService.setNewPassword(dto).pipe(
          tap(() => {
            this.toast.showSuccess(getGeneralMessage('users.set_password', true));
            this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          }),
          catchError((err: HttpErrorResponse) => {
            const message = get(
              userErrorMessages,
              err?.error?.errors,
              getGeneralMessage('users.set_password', false)
            );
            this.toast.showError(message);
            return of(err);
          }),
          finalize(() => {
            this.modalStateService.setState({ isSaveButtonDisabled: false });
          })
        );
      })
    );
  }

  mapUsersToTableRow(userList: UserListItem[]): UserTableRow[] {
    return userList.map((item) => {
      return {
        ...item,
        partner: head<PartnerMinimal>(item.partners)?.name || '',
        status: item?.status ? this.translate.instant(`common.${item.status.toLowerCase()}`) : '',
      };
    });
  }

  resetUserState(): void {
    this.setState(omit<UserState>(userInitialState, 'me'));
  }

  private handleUserManageSideEffects(
    toastText: string
  ): (source: Observable<User>) => Observable<User> {
    return (source: Observable<User>): Observable<User> => {
      return source.pipe(
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
          this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
          this.eventBus.dispatch(userActions.refreshUserList());
        }),
        catchError((err) => {
          this.toast.showError(getGeneralMessage(toastText, false));
          return of(err);
        }),
        finalize(() => {
          this.modalStateService.setState({ isSaveButtonDisabled: false });
        })
      );
    };
  }

  private refreshAvatarState(newAvatarUrl: string): void {
    if (newAvatarUrl) {
      const { me } = this.getStateSnapshot();
      this.setState({ me: { ...me, avatarThumb: newAvatarUrl } });
    }
  }

  private waitRoleChangeConfirm(confirmModalId: string, mapToPayload: RoleChangePayload) {
    return this.modalStateService.eventBus
      .on([modalActionTypes.confirmButtonClick, modalActionTypes.dismissButtonClick])
      .pipe(
        tap((data) => {
          if (!data) {
            // dismiss clicked
            this.eventBus.dispatch(
              userActions.rollbackRole({
                userId: mapToPayload.user.id,
                previousRole: head(mapToPayload.user.roles).name,
              })
            );
            this.setState({ loadingRowId: 0 });
          }
        }),
        filter((id) => id === confirmModalId),
        mapTo(mapToPayload)
      );
  }

  private waitForDeleteUserConfirm(confirmModalId: string, mapToPayload: any) {
    return this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
      filter((id) => id === confirmModalId),
      mapTo(mapToPayload)
    );
  }
}

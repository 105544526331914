export enum UserPermission {
  CreatePartner = 'create-partner',
  ReadPartner = 'read-partner',
  UpdatePartner = 'update-partner',
  DeletePartner = 'delete-partner',
  ReSendEmailNotification = 're-send-email-notification',
  ManageHistory = 'manage-histories',
  ReadProject = 'read-project',
  ManageProject = 'manage-project',
  ReadPosition = 'read-position',
  CreatePosition = 'create-position',
  ManageAdvertisement = 'manage-advertisements',
  ManagePositionCandidates = 'manage-position-candidates',
  ExportTable = 'export-table',
  ManageFilters = 'manage-filters',
  BulkEdit = 'bulk-edit',
  CreateCandidate = 'create-candidate',
  DetachCandidatePosition = 'detach-candidate-position',
}

import { Component, OnInit } from '@angular/core';
import { sideNavigationConfig } from '@app/config/side-navigation.config';
import { UserService } from '@pages/users/services/user.service';
import { Observable } from 'rxjs';
import { User } from '@shared/modules/auth/classes/User';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { version } from '../../../../../../../package.json';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit {
  sidenavItems = sideNavigationConfig;
  appVersion = version;
  me$: Observable<User>;
  isLaborHireATS = false;

  constructor(private userService: UserService, private atsConfigService: ATSConfigService) {}

  ngOnInit() {
    this.me$ = this.userService.select('me');
    this.isLaborHireATS = this.atsConfigService.isLaborHireATS;
  }
}

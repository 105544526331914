import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetBackgroundImageDirective } from '@shared/modules/custom-directives/set-background-image.directive';
import { SetBodyBackgroundDirective } from './set-body-background.directive';
import { ImgFallbackDirective } from './img-fallback.directive';
import { NumberInputDirective } from './number-input.directive';

@NgModule({
  declarations: [
    SetBackgroundImageDirective,
    SetBodyBackgroundDirective,
    ImgFallbackDirective,
    NumberInputDirective,
  ],
  imports: [CommonModule],
  exports: [
    SetBackgroundImageDirective,
    SetBodyBackgroundDirective,
    ImgFallbackDirective,
    NumberInputDirective,
  ],
})
export class CustomDirectivesModule {}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HintType } from '@shared/modules/hint/classes/HintType';
import { AbstractControl } from '@angular/forms';
import { has } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-text-field-label',
  templateUrl: './text-field-label.component.html',
  styleUrls: ['./text-field-label.component.scss'],
})
export class TextFieldLabelComponent implements OnChanges {
  @Input() isHintShown = true;
  @Input() hintText: string;
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() fieldId: string;
  hintType = HintType;
  isOptionalField = false;
  private isListening = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.control?.currentValue && !this.isListening) {
      this.control.statusChanges
        .pipe(startWith(this.control.value as string), untilDestroyed(this))
        .subscribe(() => {
          // use getIsFieldOptional in the future to show optional labels
        });
    }
  }

  private getIsFieldOptional(): boolean {
    return (
      !this.control.disabled && !this.control.validator && !has(this.control.errors, 'required')
    );
  }
}

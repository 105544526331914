import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  modalTitle$: Observable<string>;
  iconUrl$: Observable<string>;

  constructor(private modalStateService: ModalStateService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.modalTitle$ = this.modalStateService.select('modalTitle').pipe(delay(50));
    this.iconUrl$ = this.modalStateService.select('iconUrl').pipe(delay(50));
  }
}

<div class="mb-40px mt-20px">
    <app-form-field class="d-block mb-10px">
        <app-select
          [options]="exportTypes"
          label="common.format"
          bindLabel="name"
          bindValue="id"
          [formControl]="exportTypeControl"
          [disabled]="isLoading">
        </app-select>
    </app-form-field>

    <div *ngIf="isLoading" class="export-spinner">
        <app-loading></app-loading>
    </div>
</div>


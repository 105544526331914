import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ATSType } from '@shared/classes/ATSType';

@Injectable({
  providedIn: 'root',
})
export class ATSConfigService {
  get isLaborHireATS(): boolean {
    return environment.atsType === 'labor-hire';
  }

  get isPensionerATS(): boolean {
    return environment.atsType === 'pensioner';
  }

  get isStudentATS(): boolean {
    return environment.atsType === 'student';
  }

  get atsType(): ATSType {
    return environment.atsType;
  }
}

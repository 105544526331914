import { Action } from '@shared/modules/event-bus/classes/Action';

export interface ModalState {
  isSaveButtonDisabled: boolean;
  isModalDataLoading: boolean;
  isHeaderShown: boolean;
  modalTitle: string;
  iconUrl: string;
  approveButtonText: string;
  approveButtonStyle: 'basic' | 'warn';
  cancelButtonText: string;
  modalText: string;
  confirmModalId: string;
  isEditMode: boolean;
  cancelButtonAppear: boolean;
  dismissButtonAction: Action;
}

export const modalInitialState: ModalState = {
  isSaveButtonDisabled: true,
  isModalDataLoading: true,
  isHeaderShown: true,
  modalTitle: '',
  iconUrl: '',
  approveButtonText: 'common.save',
  approveButtonStyle: 'basic',
  cancelButtonText: 'common.dismiss',
  modalText: '',
  confirmModalId: '',
  isEditMode: false,
  cancelButtonAppear: true,
  dismissButtonAction: undefined,
};

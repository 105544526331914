export const userActionTypes = {
  getMe: '[Users] - get personal info',
  openAvatarUploadModal: '[Users] - open avatar upload modal',
  uploadAvatar: '[Users] - upload avatar',
  refreshMe: '[Users] - trigger refresh personal details',
  updateUserRole: '[Users] - update user role',
  rollbackRole: '[Users] - rollback user role',
  roleUpdated: '[Users] - role updated',
  openManageUserModal: '[Users] - open manage user modal',
  createUser: '[Users] - create user',
  updateUser: '[Users] - update user',
  refreshUserList: '[Users] - refresh user list',
  openUserDeleteConfirmModal: '[Users] - confirm delete user',
  deleteUser: '[Users] - delete user',
  openSetNewPasswordModal: '[Users profile] - open set new user password modal',
  setNewPassword: '[Users profile] - set new user password',
};

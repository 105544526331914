import { Injectable } from '@angular/core';
import { AuthService } from '@shared/modules/auth/services/auth.service';

@Injectable()
export class AppInitializer {
  constructor(private authService: AuthService) {}

  public load(): Promise<boolean> {
    sessionStorage.clear();
    return this.authService.checkAuth();
  }
}

import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { userActionTypes } from '@pages/users/actions/user.action-types';
import { RoleChangePayload } from '@pages/users/classes/RoleChangePayload';
import { RollbackRolePayload } from '@pages/users/classes/RollbackRolePayload';
import { User } from '@shared/modules/auth/classes/User';
import { UserDto } from '@pages/users/classes/UserDto';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { SetNewPasswordDto } from '@pages/users/classes/SetNewPasswordDto';

export default {
  getMe: () => createAction(userActionTypes.getMe),
  openAvatarUploadModal: () => createAction(userActionTypes.openAvatarUploadModal),
  uploadAvatar: (payload: { avatarBase64: string }) =>
    createAction(userActionTypes.uploadAvatar, payload),
  refreshMe: () => createAction(userActionTypes.refreshMe),
  updateUserRole: (payload: RoleChangePayload) =>
    createAction(userActionTypes.updateUserRole, payload),
  rollbackRole: (payload: RollbackRolePayload) =>
    createAction(userActionTypes.rollbackRole, payload),
  roleUpdated: (payload: User) => createAction(userActionTypes.roleUpdated, payload),
  openManageUserModal: () => createAction(userActionTypes.openManageUserModal),
  createUser: (dto: UserDto) => createAction(userActionTypes.createUser, dto),
  updateUser: (dto: UserDto) => createAction(userActionTypes.updateUser, dto),
  refreshUserList: () => createAction(userActionTypes.refreshUserList),
  openUserDeleteConfirmModal: (user?: UserListItem) =>
    createAction(userActionTypes.openUserDeleteConfirmModal, user),
  deleteUser: (user: UserListItem) => createAction(userActionTypes.deleteUser, user),
  openSetNewPasswordModal: () => createAction(userActionTypes.openSetNewPasswordModal),
  setNewPassword: (payload: SetNewPasswordDto) =>
    createAction(userActionTypes.setNewPassword, payload),
};

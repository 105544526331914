import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import { appInitialState, AppState } from '@shared/modules/event-bus/state/app/app.state';
import { Observable, of } from 'rxjs';
import { Office } from '@pages/partners/classes/Office';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EndpointsConfig } from '@config/endpoints.config';
import { HttpService } from '@shared/modules/http/http.service';
import { Source } from '@pages/candidates/classes/Source';
import { JobType } from '@pages/candidates/classes/JobType';
import { City, CityMinimal } from '@shared/classes/City';
import { Specialization } from '@pages/candidates/classes/Specialization';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { CandidateDocumentType } from '@pages/candidates/classes/CandidateDocumentType';
import { EmploymentType } from '@pages/positions/classes/EmploymentType';
import { Country } from '@shared/classes/Country';
import { EducationType } from '@pages/positions/classes/EducationType';
import { WorkExperience } from '@pages/positions/classes/WorkExperience';
import { Skill } from '@pages/candidates/classes/Skill';
import { JobPreference } from '@pages/candidates/classes/JobPreference';
import { Shift } from '@shared/classes/Shift';
import { AppConfig } from '@config/app.config';

@Injectable({
  providedIn: 'root',
})
export class AppStateService extends EventBusService<AppState> {
  constructor(private http: HttpService) {
    super(appInitialState);
  }

  getOffices(): Observable<Office[]> {
    return this.getEntities<Office>('isOfficesLoaded', 'offices', EndpointsConfig.offices);
  }

  getCountries(): Observable<Country[]> {
    return this.getEntities<Country>('isCountriesLoaded', 'countries', EndpointsConfig.countries);
  }

  getSources(forceRefresh = false): Observable<Source[]> {
    return this.getEntities<Source>(
      'isSourcesLoaded',
      'sources',
      EndpointsConfig.sources,
      forceRefresh
    );
  }

  getJobTypes(): Observable<JobType[]> {
    return this.getEntities<JobType>('isJobTypesLoaded', 'jobTypes', EndpointsConfig.jobTypes);
  }

  getSpecializations(): Observable<Specialization[]> {
    return this.getEntities<Specialization>(
      'isSpecializationsLoaded',
      'specializations',
      EndpointsConfig.specializations
    );
  }

  getLanguages(): Observable<Language[]> {
    return this.getEntities<Language>('isLanguagesLoaded', 'languages', EndpointsConfig.languages);
  }

  getLanguageLevels(): Observable<LanguageLevel[]> {
    return this.getEntities<LanguageLevel>(
      'isLanguageLevelsLoaded',
      'languageLevels',
      EndpointsConfig.languageLevels
    );
  }

  getDocumentTypes(): Observable<CandidateDocumentType[]> {
    return this.getEntities<CandidateDocumentType>(
      'isDocumentTypesLoaded',
      'documentTypes',
      EndpointsConfig.documentTypes
    );
  }

  getEmploymentTypes(): Observable<EmploymentType[]> {
    return this.getEntities<EmploymentType>(
      'isEmploymentTypesLoaded',
      'employmentTypes',
      EndpointsConfig.employmentTypes
    );
  }

  getEducationTypes(): Observable<EducationType[]> {
    return this.getEntities<EducationType>(
      'isEducationTypesLoaded',
      'educationTypes',
      EndpointsConfig.educationTypes
    );
  }

  getWorkExperiences(): Observable<WorkExperience[]> {
    return this.getEntities<WorkExperience>(
      'isWorkExperiencesLoaded',
      'workExperiences',
      EndpointsConfig.workExperiences
    );
  }

  getSkills(forceRefresh = true): Observable<Skill[]> {
    return this.getEntities<Skill>(
      'isSkillsLoaded',
      'skills',
      EndpointsConfig.skills,
      forceRefresh
    );
  }

  getJobPreferences(): Observable<JobPreference[]> {
    return this.getEntities<JobPreference>(
      'isJobPreferencesLoaded',
      'jobPreferences',
      EndpointsConfig.jobPreferences
    );
  }

  getCityByName(countryId: number, cityName: string): Observable<City[]> {
    return this.http.get(EndpointsConfig.citySearch(countryId), { name: cityName });
  }

  getShifts(): Observable<Shift[]> {
    return this.getEntities<Shift>('isShiftsLoaded', 'shifts', EndpointsConfig.shifts);
  }

  getAllCities(): Observable<CityMinimal[]> {
    return this.getEntities<CityMinimal>(
      'isCitiesLoaded',
      'cities',
      EndpointsConfig.cities(AppConfig.defaultLanguage)
    );
  }

  private getEntities<T>(
    listLoadedStateKey: keyof AppState,
    entitiesStoreKey: keyof AppState,
    endpointUrl: string,
    forceRefresh = false
  ): Observable<T[]> {
    const initialArray: T[] = [];
    return of(initialArray).pipe(
      withLatestFrom(this.select(listLoadedStateKey)),
      switchMap(([_, isLoaded]) => {
        return !forceRefresh && isLoaded
          ? <Observable<T[]>>(<unknown>this.select(entitiesStoreKey))
          : this.http.get(endpointUrl).pipe(
              tap((entities: T[]) =>
                this.setState({
                  [listLoadedStateKey]: true,
                  [entitiesStoreKey]: entities,
                })
              )
            );
      })
    );
  }
}

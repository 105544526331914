<div class="tags-autocomplete">
    <app-form-field>
        <app-select
          (selectionChange)="onTagSelected($event)"
          [label]="label"
          [options]="existingTags"
          bindValue="id"
          bindLabel="name"
          [isHintShown]="true"
          [addTagFn]="onAddTag.bind(this)"
          [searchable]="true"
          [formControl]="addTagControl">
        </app-select>
    </app-form-field>

   <div *ngIf="tagArray.length > 0" class="tag-list">
       <div class="d-inline-block" *ngFor="let tag of tagArray.controls; let i = index">
           <app-tag
             class="d-inline-block"
             (deleteItem)="onRemoveTag(i)"
             [id]="tag.get('id').value"
             [value]="tag.get('name').value">
           </app-tag>
       </div>
   </div>
</div>

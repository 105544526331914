import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { candidateActionTypes } from '@pages/candidates/actions/candidate.action-types';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateAttachPositionDto } from '@pages/candidates/classes/CandidateAttachPositionDto';
import { CandidatePosition } from '@pages/candidates/classes/CandidatePosition';
import { CandidateUpdatePositionDto } from '@pages/candidates/classes/CandidateUpdatePositionDto';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';

export default {
  editCandidate: () => createAction(candidateActionTypes.editCandidate),
  openDocumentModal: () => createAction(candidateActionTypes.openDocumentModal),
  updateDocumentList: () => createAction(candidateActionTypes.updateDocumentList),
  updateDetail: (detail?: CandidateDetail) =>
    createAction(candidateActionTypes.updateDetail, detail),
  saveDocument: (formData: FormData) => createAction(candidateActionTypes.saveDocument, formData),
  candidateUpdated: () => createAction(candidateActionTypes.candidateUpdated),
  bulkEditCandidate: () => createAction(candidateActionTypes.bulkEditCandidate),
  openPositionAttachModal: () => createAction(candidateActionTypes.openPositionAttachModal),
  attachToPosition: (dto: CandidateAttachPositionDto) =>
    createAction(candidateActionTypes.attachToPosition, dto),
  detachPosition: (payload?: CandidatePosition) =>
    createAction(candidateActionTypes.detachPosition, payload),
  refreshPositionList: () => createAction(candidateActionTypes.refreshPositionList),
  updatePositionStatus: (payload: CandidateUpdatePositionDto) =>
    createAction(candidateActionTypes.updatePositionStatus, payload),
  rollbackPositionStatus: (payload: { positionId: number }) =>
    createAction(candidateActionTypes.rollbackPositionStatus, payload),
  positionUpdateSuccess: (payload: { newPosition: BoardCandidate; positionId: number }) =>
    createAction(candidateActionTypes.positionUpdateSuccess, payload),
  refreshHistoryPage: () => createAction(candidateActionTypes.refreshHistoryPage),
  requestAttachedPositions: (candidateId: number) =>
    createAction(candidateActionTypes.requestAttachedPositions, candidateId),
  hideGDPRSumIcons: () => createAction(candidateActionTypes.hideGDPRSumIcons),
};

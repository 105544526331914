import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { filter, map, pluck, tap } from 'rxjs/operators';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { getCandidateName } from '@pages/candidates/utils/get-candidate-name.util';
import { getCandidateAge } from '@pages/candidates/utils/get-candidate-age.util';
import { NavigationService } from '@pages/partners/services/navigation.service';
import { AppConfig } from '@config/app.config';
import { SumIconData } from '@pages/positions/classes/board/SumIconData';
import { getSumIconData } from '@pages/positions/utils/sum-icon.util';
import { candidateActionTypes } from '@pages/candidates/actions/candidate.action-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-candidate-detail-header',
  templateUrl: './candidate-detail-header.component.html',
  styleUrls: ['./candidate-detail-header.component.scss'],
})
export class CandidateDetailHeaderComponent implements OnInit {
  profileImage$: Observable<string>;
  name$: Observable<string>;
  age: number;
  isDetailLoaded$: Observable<boolean>;
  isGDPRSumIconsVisible = true;
  isAgeShown = true;
  icons: SumIconData[];

  constructor(
    private candidateService: CandidatesService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.isDetailLoaded$ = this.candidateService.select('isDetailLoaded');

    this.name$ = this.candidateService.select('candidateDetail').pipe(
      filter((detail: CandidateDetail) => !!detail),
      tap((detail: CandidateDetail) => {
        this.age = getCandidateAge(detail.profile.birthDate);
        this.icons = getSumIconData(detail.icons, true);
      }),
      map((detail: CandidateDetail) => {
        return getCandidateName(detail.firstName, detail.lastName);
      })
    );

    this.profileImage$ = this.candidateService.select('candidateDetail').pipe(
      pluck('avatar'),
      map((avatar: string) => avatar || 'assets/image/person-placeholder.svg')
    );

    this.candidateService.eventBus
      .on(candidateActionTypes.hideGDPRSumIcons)
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.isGDPRSumIconsVisible = false));
  }

  onNavigation(): void {
    this.navigationService.back(AppConfig.candidatesUrl);
  }
}

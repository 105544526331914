<div class="partner-detail-header h-100 d-flex align-items-center">
    <app-back-arrow-button class="back-button d-block" (buttonClick)="onNavigation()"></app-back-arrow-button>
    <div *ngIf="isPartnerImageShown" class="partner-logo">
        <img
          class="logo-img"
          [src]="(partnerLogoUrl$ | async) || placeholder"
          [appImgFallback]="placeholder"
          alt="Partner Logo">
    </div>
    <div class="partner-name">{{partnerName$ | async}}</div>
</div>

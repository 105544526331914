import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { filterActionTypes } from '@shared/modules/filtering/actions/filter.action-types';

export default {
  filterByLetterAction: (letter_filter: string) =>
    createAction(filterActionTypes.filterByLetter, { letter_filter }),
  resetFiltersAction: () => createAction(filterActionTypes.resetFilters),
  manageFilterAction: (isEditMode: boolean, modalText: string) =>
    createAction(filterActionTypes.manageFilter, { isEditMode, modalText }),
  deleteFilterAction: () => createAction(filterActionTypes.deleteFilter),
  filterLoadedOrPretendAction: () => createAction(filterActionTypes.filterLoadedOrPretend),
  firstLettersLoadedAction: () => createAction(filterActionTypes.firstLettersLoaded),
  toggleSideFilter: (isOpen: boolean) => createAction(filterActionTypes.toggleSideFilter, isOpen),
  setFirstLetter: (selectedLetter: string) =>
    createAction(filterActionTypes.setFirstLetter, selectedLetter),
};

<div class="candidate-detail-header h-100 d-flex align-items-center">
    <app-back-arrow-button class="back-button d-block" (buttonClick)="onNavigation()"></app-back-arrow-button>

    <ng-container *ngIf="isDetailLoaded$ | async">
        <div class="profile-image mr-10px" [appSetBackgroundImage]="profileImage$ | async"></div>

        <div class="mr-5px">{{name$ | async}} <span *ngIf="age > 0">({{age}})</span></div>

        <ng-container *ngIf="isGDPRSumIconsVisible">
            <app-hint
                *ngFor="let icon of icons; let i = index"
                [ngClass]="{ 'ml-2': i > 0, 'ml-3': i === 0 }"
                placement="bottom"
                [hintTypes]="icon.hintType"
                [hintText]="icon.tooltipTexts"
                variation="hint">
            </app-hint>
        </ng-container>
    </ng-container>
</div>

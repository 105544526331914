import { PartnerDetail } from '@pages/partners/classes/PartnerDetail';
import { getAddressString } from '@pages/partners/utils/get-address-string.util';
import { KeyValue } from '@shared/classes/KeyValue';
import { AppConstants } from '@config/app.constant';

export function getPartnerKeyValueData(detail: PartnerDetail): KeyValue<string>[] {
  return [
    {
      key: 'partners.company_name',
      value: detail?.name ?? AppConstants.noDataTranslationKey,
    },
    {
      key: 'partners.site',
      value: getAddressString(detail?.address),
    },
    {
      key: 'common.tax_number',
      value: detail?.taxNumber ?? AppConstants.noDataTranslationKey,
    },
    {
      key: 'partners.whc_office',
      value: detail?.office?.name ?? AppConstants.noDataTranslationKey,
    },
  ];
}

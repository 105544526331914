import { AppConstants } from '@config/app.constant';

export interface ListData<T> {
  total: number;
  perPage: number;
  currentPage: number;
  from: number;
  to: number;
  data: T[];
}

export function createEmptyListData<T>(): ListData<T> {
  return {
    total: 0,
    perPage: AppConstants.tableRowsPerPage,
    currentPage: 1,
    from: 0,
    to: 0,
    data: [],
  };
}

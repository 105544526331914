import { User } from '@shared/modules/auth/classes/User';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { UserTableRow } from '@pages/users/classes/UserTableRow';

export interface UserState {
  me: User;
  users: UserTableRow[];
  currentUserRoles: UserRole[];
  loadingRowId: number;
  selectedUser: UserListItem;
}

export const userInitialState: UserState = {
  me: undefined,
  users: [],
  currentUserRoles: [],
  loadingRowId: 0,
  selectedUser: undefined,
};

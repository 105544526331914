<form [formGroup]="form" class="mb-85px mt-20px">

    <app-form-field>
        <app-text-field
          [label]="'common.name' | translate | appFirstLetterUppercase"
          formControlName="filterName"
          type="text">
        </app-text-field>
    </app-form-field>

    <app-form-field>
        <app-select
          [label]="'side_filter.visibility' | translate | appFirstLetterUppercase"
          bindValue="id"
          bindLabel="name"
          bindValueByPropertyNameToControl="id"
          [options]="filterVisibilityOption"
          formControlName="filterVisibility">
            <app-filter-option-template #labelTemplate></app-filter-option-template>
            <app-filter-option-template #optionsTemplate></app-filter-option-template>
        </app-select>
    </app-form-field>

</form>


import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { Observable } from 'rxjs';
import { BoardColumn } from '@pages/positions/classes/board/BoardColumn';
import { EndpointsConfig } from '@config/endpoints.config';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { MoveOrAddPositionDto } from '@pages/positions/classes/board/MoveOrAddPositionDto';
import { CandidateSearchItem } from '@pages/positions/classes/board/CandidateSearchItem';

@Injectable({
  providedIn: 'root',
})
export class CandidateBoardApiService {
  constructor(private http: HttpService) {}

  getColumns(): Observable<BoardColumn[]> {
    return this.http.get(EndpointsConfig.boardColumns);
  }

  getColumnsByPositionId(positionId: number): Observable<BoardColumn[]> {
    return this.http.get(EndpointsConfig.boardColumnsByPositionId(positionId));
  }

  getBoardCandidates(positionId: number): Observable<BoardCandidate[]> {
    return this.http.get(EndpointsConfig.boardCandidates(positionId));
  }

  searchCandidate(positionId: number, name: string): Observable<CandidateSearchItem[]> {
    return this.http.get(EndpointsConfig.searchBoardCandidate(positionId), { name });
  }

  updateCandidatePosition(
    positionId: number,
    candidateId: number,
    dto: MoveOrAddPositionDto
  ): Observable<BoardCandidate> {
    return this.http.put(EndpointsConfig.moveOrAddBoardCandidate(positionId, candidateId), dto);
  }

  addCandidateToPosition(
    positionId: number,
    candidateId: number,
    dto: MoveOrAddPositionDto
  ): Observable<BoardCandidate> {
    return this.http.post(EndpointsConfig.moveOrAddBoardCandidate(positionId, candidateId), dto);
  }
}

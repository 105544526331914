import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  bulkEditInitiateState,
  BulkEditState,
} from '@shared/modules/event-bus/state/bulk-edit/bulk-edit.state';
import { PartnerBulkUpdate } from '@pages/partners/classes/PartnerBulkUpdate';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { Observable, of } from 'rxjs';
import { catchError, delay, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import modalActions from '@shared/modules/mat-modal/actions/modal.actions';
import bulkEditActions from '@shared/modules/bulk-edit-bar/actions/bulk-edit.actions';
import { getStringId } from '@shared/utils/get-string-id.util';
import { PartnerApiService } from '@pages/partners/services/partner-api.service';
import { ApiTypeParams } from '@config/app.constant';
import { bulkEditActionTypes } from '@shared/modules/bulk-edit-bar/actions/bulk-edit.action-types';
import { PositionsApiService } from '@pages/positions/services/positions-api.service';
import { CandidateApiService } from '@pages/candidates/services/candidate-api.service';
import { PositionBulkUpdate } from '@pages/positions/classes/PositionBulkUpdate';
import { CandidateBulkUpdate } from '@pages/candidates/classes/CandidateBulkUpdate';
import { ComponentType } from '@angular/cdk/overlay';
import { Action } from '@shared/modules/event-bus/classes/Action';
import { TableService } from '@shared/services/table.service';

@Injectable({
  providedIn: 'root',
})
export class BulkEditService extends EventBusService<BulkEditState> {
  private readonly bulkEditModalId = getStringId();
  constructor(
    private modalStateService: ModalStateService,
    private modalService: MatModalService,
    private partnerApiService: PartnerApiService,
    private positionApiService: PositionsApiService,
    private candidateApiService: CandidateApiService,
    private tableService: TableService
  ) {
    super(bulkEditInitiateState);
  }

  setPartnerBulkUpdateState(filterObj: Partial<PartnerBulkUpdate>) {
    const newState = {
      ...this.getStateSnapshot(),
      partnerBulkUpdate: { ...this.getStateSnapshot().partnerBulkUpdate, ...filterObj },
    };
    this.setState(newState);
  }

  setPositionBulkUpdateState(filterObj: Partial<PositionBulkUpdate>) {
    const newState = {
      ...this.getStateSnapshot(),
      positionBulkUpdate: { ...this.getStateSnapshot().positionBulkUpdate, ...filterObj },
    };
    this.setState(newState);
  }

  setCandidateBulkUpdateState(filterObj: Partial<CandidateBulkUpdate>) {
    const newState = {
      ...this.getStateSnapshot(),
      candidateBulkUpdate: { ...this.getStateSnapshot().candidateBulkUpdate, ...filterObj },
    };
    this.setState(newState);
  }

  listenBulkEditSaveButtonAction(): Observable<any> {
    return this.modalStateService.eventBus.on(modalActionTypes.saveButtonClick).pipe(
      tap(() => {
        this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
        this.eventBus.dispatch(bulkEditActions.saveBulkEdit());
      })
    );
  }

  openConfirmModal(contentComponent: ComponentType<any>, dismissAction: Action) {
    return this.eventBus.on(bulkEditActionTypes.saveBulkEdit).pipe(
      delay(300),
      tap(() => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          isHeaderShown: false,
          cancelButtonText: 'common.cancel',
          approveButtonText: 'common.yes',
          isSaveButtonDisabled: false,
          approveButtonStyle: 'basic',
          modalText: 'partners.message_delete_confirm',
          confirmModalId: this.bulkEditModalId,
          dismissButtonAction: dismissAction,
        });
      }),
      switchMap(() => {
        return this.openBulkEditModal(contentComponent);
      })
    );
  }

  listenBulkEditConfirmAction(): Observable<any> {
    return this.modalStateService.eventBus.on(modalActionTypes.saveButtonClick).pipe(
      tap(() => {
        this.modalStateService.setState({ isModalDataLoading: true });
        this.modalStateService.eventBus.dispatch(
          modalActions.confirmButtonClickAction(this.bulkEditModalId)
        );
      })
    );
  }

  listenBulkEditDismissAction(): Observable<any> {
    return this.modalStateService.eventBus.on(modalActionTypes.dismissButtonClick).pipe(
      tap(() => {
        this.setState({ isEditMode: true });
        this.tableService.eventBus.dispatch(
          this.modalStateService.getStateSnapshot().dismissButtonAction
        );
      })
    );
  }

  bulkUpdate(contentComponent: ComponentType<any>): Observable<any> {
    return this.modalStateService.eventBus.on(modalActionTypes.confirmButtonClick).pipe(
      filter((id) => this.bulkEditModalId === id),
      map(() => {
        return this.getStateSnapshot();
      }),
      switchMap((state: BulkEditState) => {
        let endpoint: Observable<any>;
        switch (state.type) {
          case ApiTypeParams.PARTNER:
            endpoint = this.partnerApiService.bulkUpdatePartner(state.partnerBulkUpdate);
            break;
          case ApiTypeParams.POSITION:
            endpoint = this.positionApiService.bulkUpdatePosition(state.positionBulkUpdate);
            break;
          case ApiTypeParams.CANDIDATE:
            endpoint = this.candidateApiService.bulkUpdateCandidate(state.candidateBulkUpdate);
            break;
          default:
            break;
        }

        return this.handleResponse(endpoint, contentComponent);
      })
    );
  }

  private handleResponse(endpoint: Observable<any>, contentComponent: ComponentType<any>) {
    return endpoint.pipe(
      tap((resp) => {
        this.setState({ error: resp.errors });
      }),
      catchError((err) => {
        this.setState({ error: err.error.errors });
        return of(err);
      }),
      finalize(() => {
        this.modalStateService.eventBus.dispatch(modalActions.closeModalAction());
        setTimeout(() => {
          this.showResult(contentComponent);
        }, 500);
      })
    );
  }

  showResult(contentComponent: ComponentType<any>): void {
    this.modalStateService.setState({
      modalTitle: 'common.bulk_edit_finalize_title',
      iconUrl: 'assets/image/building.svg',
      isModalDataLoading: false,
      cancelButtonAppear: false,
      isSaveButtonDisabled: false,
      approveButtonText: 'common.finish',
    });
    this.openBulkEditModal(contentComponent);
  }

  bulkEditStateHelper(type: ApiTypeParams): keyof BulkEditState {
    switch (type) {
      case ApiTypeParams.PARTNER:
        return 'partnerBulkUpdate';
      case ApiTypeParams.POSITION:
        return 'positionBulkUpdate';
      case ApiTypeParams.CANDIDATE:
        return 'candidateBulkUpdate';
      default:
        return 'partnerBulkUpdate';
    }
  }

  resetBulkEditState(): void {
    this.setState({ ...bulkEditInitiateState });
  }

  private openBulkEditModal(contentComponent: ComponentType<any>): Observable<any> {
    return this.modalService.openDialog({
      width: '460px',
      disableClose: true,
      data: {
        contentComponent,
      },
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { PositionsService } from '@pages/positions/services/positions.service';
import { Observable } from 'rxjs';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConfig } from '@config/app.config';
import { NavigationService } from '@pages/partners/services/navigation.service';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';
import { AuthService } from '@shared/modules/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-position-detail-header',
  templateUrl: './position-detail-header.component.html',
  styleUrls: ['./position-detail-header.component.scss'],
})
export class PositionDetailHeaderComponent implements OnInit {
  isDetailLoaded$: Observable<boolean>;
  detail: PositionDetail;
  pages = [];
  placeholder = 'assets/image/partner-placeholder.svg';
  logo: string;
  urls = {
    candidates: 'candidates',
    detail: '',
    advertisements: 'vacancies',
  };

  constructor(
    private positionService: PositionsService,
    private navigationService: NavigationService,
    private auth: AuthService
  ) {
    this.logo = this.placeholder;
  }

  ngOnInit(): void {
    this.isDetailLoaded$ = this.positionService.select('isDetailLoaded');
    this.positionService
      .select('positionDetail')
      .pipe(untilDestroyed(this))
      .subscribe((detail) => {
        this.detail = detail;
        if (this.detail?.partner?.logo) {
          this.logo = this.detail.partner.logo;
        }
        this.pages = this.getPages(detail?.id);
      });
  }

  onNavigation(): void {
    this.navigationService.back(AppConfig.positionsUrl);
  }

  onInnerNavigation(): void {
    this.navigationService.popHistory();
  }

  private getPages(positionId: number) {
    if (!positionId) {
      return [];
    }

    const menus = [
      {
        routerLink: [AppConfig.positionsUrl, positionId, this.urls.candidates],
        text: 'positions.header.candidates',
        isActive: this.navigationService.location.path().indexOf(this.urls.candidates) > -1,
        permissions: [UserPermission.ManagePositionCandidates],
      },
      {
        routerLink: [AppConfig.positionsUrl, positionId],
        text: 'positions.header.detail',
        isActive: !this.navigationService.location
          .path()
          .split('/')
          .some((part) => [this.urls.candidates, this.urls.advertisements].indexOf(part) > -1),
        permissions: [UserPermission.ReadPosition],
      },
      {
        routerLink: [AppConfig.positionsUrl, positionId, this.urls.advertisements],
        text: 'positions.header.advertisements',
        isActive: this.navigationService.location.path().indexOf(this.urls.advertisements) > -1,
        permissions: [UserPermission.ManageAdvertisement],
      },
    ];

    return menus.filter((menu) => {
      return (
        this.auth.isSuperAdmin ||
        AuthUtils.isContainUserPermission(menu.permissions, this.auth.getUserRoles())
      );
    });
  }
}

import { createAction } from '@shared/modules/event-bus/utils/create-action.util';
import { tableActionTypes } from '@shared/modules/table/actions/table.action-types';
import { TogglePositionStatus } from '@pages/positions/classes/TogglePositionStatus';

export default {
  resetSelectedItems: () => createAction(tableActionTypes.resetSelectedItems),
  togglePositionStatus: (togglePosition: TogglePositionStatus) =>
    createAction(tableActionTypes.togglePositionStatus, togglePosition),
  showBulkEditBar: (isShown: boolean) => createAction(tableActionTypes.showBulkEditBar, isShown),
};

import { Component, Input } from '@angular/core';
import { IconTableCellData } from '@shared/modules/table/classes/IconTableCellData';

@Component({
  selector: 'app-icon-table-cell-inside',
  templateUrl: './icon-table-cell-inside.component.html',
  styleUrls: ['./icon-table-cell-inside.component.scss'],
})
export class IconTableCellInsideComponent {
  @Input() iconData: IconTableCellData;
}

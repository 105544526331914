import { Directive, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Directive({
  selector: '[appOpenInNewTab]',
})
export class OpenInNewTabDirective implements OnInit, OnDestroy {
  @Input() openInNewTab$: Observable<{ url: string }>;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit() {
    this.openInNewTab$.pipe(untilDestroyed(this)).subscribe(({ url }: { url: string }) => {
      const anchor = this.getAnchorElement();
      this.renderer.setAttribute(
        anchor,
        'href',
        `${window.location.origin}/${this.normalizeUrl(url)}`
      );
      anchor.click();
    });
  }

  ngOnDestroy(): void {
    this.renderer.setAttribute(this.getAnchorElement(), 'href', '#');
  }

  private normalizeUrl(url: string): string {
    if (url.startsWith('/')) {
      return url.substring(1);
    }

    return url;
  }

  private getAnchorElement(): HTMLAnchorElement {
    return this.document.querySelector('#hidden-anchor');
  }
}

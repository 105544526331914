import { HttpErrorResponse } from '@angular/common/http';
import keys from 'lodash-es/keys';
import { ValidationErrors } from '@shared/classes/ValidationErrors';
import { FormGroup } from '@angular/forms';
import { lowerFirst, snakeCase } from 'lodash-es';

export function parseBackendValidationErrors(errorResponse: HttpErrorResponse): ValidationErrors {
  const errors = errorResponse?.error?.errors;

  if (!errors) {
    return {};
  }

  const errorMap: ValidationErrors = {};

  keys(errors).forEach((field) => {
    errorMap[field] = keys(errors[field]).map((key) => snakeCase(lowerFirst(key)));
  });

  return errorMap;
}

export function addValidationErrorsToForm(form: FormGroup, errors: ValidationErrors): void {
  keys(errors).forEach((error: string) => {
    const control = form.get(error);
    if (control) {
      const controlErrorObject = {};
      errors[error].forEach((errorName) => {
        controlErrorObject[errorName] = errorName;
      });
      control.setErrors(controlErrorObject);
    }
  });
  form.markAllAsTouched();
  form.updateValueAndValidity();
}

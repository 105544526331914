<app-form-field>
    <app-select
      [label]="label"
      [isHintShown]="true"
      [hideSelected]="true"
      [options]="options"
      bindLabel="key"
      bindValue="value"
      bindValueByPropertyNameToControl="value"
      [formControl]="control">
    </app-select>
</app-form-field>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@shared/modules/button/button.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { CustomPipesModule } from '@shared/modules/custom-pipes/custom-pipes.module';
import { FormComponentsModule } from '@shared/modules/form-components/form-components.module';
import { SharedModule } from '@shared/shared.module';
import { PhotoUploadModule } from '@shared/modules/photo-upload/photo-upload.module';
import { FilteringModule } from '@shared/modules/filtering/filtering.module';
import { ModalFooterComponent } from './components/modal-frame/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-frame/modal-header/modal-header.component';
import { MatModalBaseComponent } from './mat-modal-base.component';
import { MatModalService } from './mat-modal.service';
import { ModalFrameComponent } from './components/modal-frame/modal-frame.component';
import { ModalGridComponent } from './components/modal-grid/modal-grid.component';
import { ModalSectionHeaderComponent } from './components/modal-section-header/modal-section-header.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { DocumentUploadComponent } from './components/document-upload/document-upload.component';
import { ConfirmBulkEditModalComponent } from './components/confirm-bulk-edit-modal/confirm-bulk-edit-modal.component';
import { FinalizedBulkEditModalComponent } from './components/finalized-bulk-edit-modal/finalized-bulk-edit-modal.component';
import { ExportModalComponent } from './components/export-modal/export-modal.component';
import { DocumentPositionSelectionComponent } from './components/document-upload/document-position-selection/document-position-selection.component';
import { DocumentPositionSelectionOptionTemplateComponent } from './components/document-upload/document-position-selection-option-template/document-position-selection-option-template.component';

@NgModule({
  declarations: [
    MatModalBaseComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalFrameComponent,
    ModalGridComponent,
    ModalSectionHeaderComponent,
    ConfirmModalComponent,
    FilterModalComponent,
    DocumentUploadComponent,
    ConfirmBulkEditModalComponent,
    FinalizedBulkEditModalComponent,
    ExportModalComponent,
    DocumentPositionSelectionComponent,
    DocumentPositionSelectionOptionTemplateComponent,
  ],
  exports: [
    MatModalBaseComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalGridComponent,
    ModalSectionHeaderComponent,
  ],
  providers: [MatModalService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    TranslateModule,
    ButtonModule,
    InlineSVGModule,
    LoaderModule,
    CustomPipesModule,
    FormComponentsModule,
    SharedModule,
    PhotoUploadModule,
    FilteringModule,
  ],
})
export class MatModalModule {}

import { Injectable } from '@angular/core';
import { HttpService } from '@shared/modules/http/http.service';
import { forkJoin, Observable } from 'rxjs';
import { Contact } from '@pages/partners/classes/Contact';
import { UserType } from '@pages/users/classes/UserType';
import { EmailValidatorPayload } from '@pages/users/classes/EmailValidatorPayload';
import { EmailValidatorResponse } from '@pages/users/classes/EmailValidatorResponse';
import { EndpointsConfig } from '@config/endpoints.config';
import { map, pluck } from 'rxjs/operators';
import { User } from '@shared/modules/auth/classes/User';
import { UserAvatarDto } from '@pages/users/classes/UserAvatarDto';
import { AvatarUploadResponse } from '@pages/users/classes/AvatarUploadResponse';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { UserListItem } from '@pages/users/classes/UserListItem';
import { RoleCheckResponse } from '@pages/users/classes/RoleCheckResponse';
import { UserDto } from '@pages/users/classes/UserDto';
import { RelationCheckResponse } from '@pages/users/classes/RelationCheckResponse';
import { SetNewPasswordDto } from '@pages/users/classes/SetNewPasswordDto';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpService) {}

  getUsersByType(type: UserType): Observable<Contact[]> {
    const typeMap = {
      [UserType.Contacts]: 'contacts',
      [UserType.FieldManagers]: 'fieldManagers',
      [UserType.ProjectManager]: 'projectManagers',
      [UserType.Partners]: 'partners',
    };

    return this.http
      .get(EndpointsConfig.usersListByRole, null, {
        key: 'types',
        value: [type],
      })
      .pipe(
        pluck(typeMap[type]),
        map((contacts: Contact[] | undefined) => (Array.isArray(contacts) ? contacts : []))
      );
  }

  checkEmailExists(payload: EmailValidatorPayload): Observable<EmailValidatorResponse> {
    return this.http.post(EndpointsConfig.usersCheckEmail, payload);
  }

  getMe(): Observable<User> {
    return this.http.get(EndpointsConfig.me);
  }

  uploadUserAvatar(avatarDto: UserAvatarDto): Observable<AvatarUploadResponse> {
    return this.http.post(EndpointsConfig.userAvatar, avatarDto);
  }

  getUsersByRole(roles: UserRole[]): Observable<UserListItem[][]> {
    const endpoints = roles.map((role) => this.http.get(EndpointsConfig.users, { role }));
    return forkJoin([...endpoints]);
  }

  checkRoleChange(userId: number, role: UserRole | string): Observable<RoleCheckResponse> {
    return this.http.post(EndpointsConfig.checkRoleChange(userId), {
      roleName: role,
    });
  }

  updateRole(userId: number, role: UserRole | string): Observable<User> {
    return this.http.put(EndpointsConfig.updateUserRole(userId), {
      roleName: role,
    });
  }

  createUser(dto: UserDto): Observable<User> {
    return this.http.post(EndpointsConfig.users, dto);
  }

  updateUser(dto: UserDto): Observable<User> {
    return this.http.put(EndpointsConfig.userById(dto.id), dto);
  }

  deleteUser(dto: UserListItem): Observable<User> {
    return this.http.delete(EndpointsConfig.userById(dto.id));
  }

  checkHasRelations(userId: number): Observable<RelationCheckResponse> {
    return this.http.get(EndpointsConfig.checkHasRelations(userId));
  }

  setNewPassword(dto: SetNewPasswordDto): Observable<unknown> {
    return this.http.put(EndpointsConfig.setNewPassword, dto);
  }
}

export enum BulkEditTranslateHelper {
  partner = 'common.partner',
  officeId = 'partners.whc_office',
  fieldManagerId = 'partners.field_manager',
  projectId = 'positions.projects',
  status = 'positions.status',
  jobTypeId = 'candidates.work_type',
  jobSubTypeId = 'candidates.physical_work_type',
  employmentTypeId = 'positions.employment_type',
  specializations = 'positions.advertisements.categories',
  membershipEndDate = 'candidates.membership_end',
  statusId = 'common.status',
  statusOptionId = 'positions.board.reason',
  positionId = 'common.position',
  subPositionId = 'common.sub_position',
  membershipStartDate = 'candidates.membership_start',
  feorNumber = 'positions.feor_number',
  subPartner = 'positions.sub_partners',
  subProject = 'common.sub_site',
}

import { Injectable } from '@angular/core';
import { EventBusService } from '@shared/modules/event-bus/services/event-bus.service';
import {
  sideFilterInitialState,
  SideFilterState,
} from '@shared/modules/event-bus/state/side-filter/side-filter.state';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import filterActions from '@shared/modules/filtering/actions/filter.actions';

@Injectable({
  providedIn: 'root',
})
export class SideFilterService extends EventBusService<SideFilterState> {
  constructor(private filteringService: FilteringService) {
    super(sideFilterInitialState);
  }

  toggleSideFilter(isOpen?: boolean) {
    this.setState({ isOpen });
    this.filteringService.eventBus.dispatch(filterActions.toggleSideFilter(isOpen));
  }
}

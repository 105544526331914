import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { ApiTypeParams } from '@config/app.constant';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GetUsersFilter } from '@shared/classes/users/GetUsersFilter';
import { UserFilterApiService } from '@shared/services/user-filter-api.service';
import { FormControl } from '@angular/forms';
import { FilteringManageService } from '@shared/modules/filtering/services/filtering-manage.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filterActionTypes } from '@shared/modules/filtering/actions/filter.action-types';
import { FilterModalComponent } from '@shared/modules/mat-modal/components/filter-modal/filter-modal.component';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { MenuService } from '@shared/services/menu.service';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { MatModalService } from '@shared/modules/mat-modal/mat-modal.service';
import { DotMenuItem } from '@shared/modules/dot-menu/classes/DotMenuItem';
import filterActions from '@shared/modules/filtering/actions/filter.actions';
import { CardFilterInterface } from '@shared/modules/filtering/classes/card-filter.interface';
import { AppConfig } from '@config/app.config';
import { UserPermission } from '@shared/modules/auth/classes/UserPermission';
import { AuthUtils } from '@shared/modules/auth/utils/auth.utils';
import { AuthService } from '@shared/modules/auth/services/auth.service';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { AppStateService } from '@shared/services/app-state.service';
import { ATSConfigService } from '@shared/services/ats-config.service';

enum SuperRequired {
  ON_INIT = 'OnInit',
  ON_DESTROY = 'OnDestroy',
}

@UntilDestroy()
@Component({
  template: '',
})
export class FilterContainerBaseComponent implements OnInit, OnDestroy {
  savedFilters$ = new BehaviorSubject<GetUsersFilter[]>([]);
  filter = new FormControl();
  filters$ = new BehaviorSubject<CardFilterInterface[]>([]);
  numberOfActiveFilter: number;
  isLaborHireATS = false;

  saveMenuConfig: DotMenuItem = {
    id: 'save',
    textValue: 'common.save',
    actionOnClick: filterActions.manageFilterAction(true, 'common.filter_save'),
    permissions: [UserPermission.ManageFilters],
  };
  saveAsMenuConfig: DotMenuItem = {
    id: 'save_as',
    textValue: 'common.save_as',
    actionOnClick: filterActions.manageFilterAction(false, 'common.filter_save_as'),
    permissions: [UserPermission.ManageFilters],
  };
  saveNewMenuConfig: DotMenuItem = {
    id: 'save_new',
    textValue: 'common.save',
    actionOnClick: filterActions.manageFilterAction(false, 'common.filter_save'),
    permissions: [UserPermission.ManageFilters],
  };
  deleteMenuConfig: DotMenuItem = {
    id: 'delete',
    textValue: 'common.delete',
    actionOnClick: filterActions.deleteFilterAction(),
    permissions: [UserPermission.ManageFilters],
  };

  menuConfig = new BehaviorSubject<DotMenuItem[]>([]);
  encodedFilter: string;
  readonly cdr: ChangeDetectorRef;
  protected readonly appStateService: AppStateService;
  protected readonly atsConfigService: ATSConfigService;
  private readonly auth: AuthService;
  private readonly userFilterApiService: UserFilterApiService;
  private readonly menuService: MenuService;

  constructor(
    readonly filteringService: FilteringService,
    readonly filteringManageService: FilteringManageService,
    readonly modalStateService: ModalStateService,
    readonly modalService: MatModalService,
    readonly injector: Injector
  ) {
    this.auth = this.injector.get<AuthService>(AuthService);
    this.userFilterApiService = this.injector.get<UserFilterApiService>(UserFilterApiService);
    this.menuService = this.injector.get<MenuService>(MenuService);
    this.appStateService = this.injector.get<AppStateService>(AppStateService);
    this.atsConfigService = this.injector.get<ATSConfigService>(ATSConfigService);
    this.cdr = this.injector.get<ChangeDetectorRef>(ChangeDetectorRef);
  }

  ngOnInit(skip?: boolean): SuperRequired.ON_INIT {
    this.listenConfirmDeleteFilterModal();
    this.listenDeleteFilterAction();
    this.listenSavedFilterChange();
    this.listenFilterOptionsChange();
    if (!skip) {
      // common onInit logic
    }
    return SuperRequired.ON_INIT;
  }

  onSelectedClicked(event) {
    if (event.target.classList.contains('ng-option-selected')) {
      this.filter.patchValue(this.filter.value);
    }
  }

  setSelectedFilterState(selectedFilter: string) {
    this.filteringService.setSelectedFilterState(selectedFilter);
  }

  dotMenuConfig(filterObj: any): void {
    const selectedFilter = this.filter?.value?.filter || null;
    const isEmptyFilter = Object.keys(filterObj).length === 0;
    if (!isEmptyFilter) {
      if (
        this.filter.value?.isOwn ||
        AuthUtils.isContainUserRole([UserRole.Admin], this.auth.getUserRoles())
      ) {
        this.menuConfig.next(
          selectedFilter
            ? [this.saveMenuConfig, this.saveAsMenuConfig, this.deleteMenuConfig]
            : [this.saveNewMenuConfig]
        );
      } else {
        this.menuConfig.next(selectedFilter ? [this.saveAsMenuConfig] : [this.saveNewMenuConfig]);
      }
    } else {
      this.menuConfig.next([]);
    }
  }

  getSavedFilters(filterType: ApiTypeParams): void {
    of(null)
      .pipe(
        filter(() => !this.auth.getUserRoles().includes(UserRole.Candidate)),
        switchMap(() => {
          return this.userFilterApiService.getUsersFilters(filterType).pipe(
            tap((value) => {
              this.filteringManageService.setState({ savedFilters: value });
              this.getSelectedFilterFromStorage(filterType, value);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  openSaveFilterModal(): Observable<any> {
    return this.menuService.eventBus.on(filterActionTypes.manageFilter).pipe(
      tap(({ isEditMode, modalText }) => {
        this.modalStateService.setState({
          isModalDataLoading: false,
          isHeaderShown: true,
          cancelButtonText: 'common.dismiss',
          approveButtonText: 'common.save',
          modalTitle: modalText,
          iconUrl: 'assets/image/building.svg',
          isSaveButtonDisabled: true,
          approveButtonStyle: 'basic',
          modalText: 'partners.message_delete_confirm',
          isEditMode,
        });
        this.modalService.openDialog({
          width: '460px',
          data: {
            contentComponent: FilterModalComponent,
            variant: ModalTypes.DynamicHeight,
          },
        });
      })
    );
  }

  listenFilterValueChange(): void {
    this.filter.valueChanges
      .pipe(
        tap((userFilter: GetUsersFilter) => {
          if (userFilter) {
            this.setSelectedFilterState(userFilter.filter);
            this.menuConfig.next([this.saveAsMenuConfig, this.deleteMenuConfig]);
            this.setSelectedFilterToStorage(userFilter);
          }
          this.filteringManageService.setState({ filter: userFilter });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  listenConfirmDeleteFilterModal(): void {
    this.filteringManageService.openDeleteFilterModal().pipe(untilDestroyed(this)).subscribe();
  }

  private setSelectedFilterToStorage(userFilter: GetUsersFilter): void {
    switch (userFilter.type) {
      case ApiTypeParams.CANDIDATE:
        sessionStorage.setItem(
          AppConfig.sessionStorage.prevSelectedCandidateFilter,
          userFilter.id.toString()
        );
        break;
      case ApiTypeParams.POSITION:
        sessionStorage.setItem(
          AppConfig.sessionStorage.prevSelectedPositionFilter,
          userFilter.id.toString()
        );
        break;
      case ApiTypeParams.PARTNER:
        sessionStorage.setItem(
          AppConfig.sessionStorage.prevSelectedPartnerFilter,
          userFilter.id.toString()
        );
        break;
      default:
        break;
    }
  }

  private getSelectedFilterFromStorage(filterType: ApiTypeParams, value: GetUsersFilter[]): void {
    let prevFilter;
    let selectedFilter;
    switch (filterType) {
      case ApiTypeParams.CANDIDATE:
        prevFilter = sessionStorage.getItem(AppConfig.sessionStorage.prevSelectedCandidateFilter);
        break;
      case ApiTypeParams.POSITION:
        prevFilter = sessionStorage.getItem(AppConfig.sessionStorage.prevSelectedPositionFilter);
        break;
      case ApiTypeParams.PARTNER:
        prevFilter = sessionStorage.getItem(AppConfig.sessionStorage.prevSelectedPartnerFilter);
        break;
      default:
        break;
    }
    if (prevFilter) {
      selectedFilter = value.find((f) => +prevFilter === f.id);
      this.filter.patchValue(selectedFilter, { emitEvent: false });
    }
  }

  private listenFilterOptionsChange(): void {
    this.savedFilters$
      .pipe(
        map((filtersOptions) => {
          if (this.filter.value) {
            const updatedFilterOption = filtersOptions.filter(
              (filtersOption) => filtersOption.id === this.filter.value.id
            );
            if (updatedFilterOption.length > 0 && this.filter.value !== updatedFilterOption[0]) {
              this.filter.patchValue({ ...updatedFilterOption[0] }, { emitEvent: false });
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private listenSavedFilterChange(): void {
    this.filteringManageService
      .select('savedFilters')
      .pipe(
        tap((savedFilters: GetUsersFilter[]) => {
          this.savedFilters$.next(savedFilters);
          this.cdr.detectChanges();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  listenDeleteFilterAction(): void {
    this.filteringManageService.deleteFilter().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(skip?: boolean): SuperRequired.ON_DESTROY {
    if (!skip) {
      // common onDestroy logic
    }
    return SuperRequired.ON_DESTROY;
  }
}

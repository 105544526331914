import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormInputBaseComponent } from '@shared/modules/text-field/form-input-base.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent extends FormInputBaseComponent {
  @Input() isActive = false;
  @Input() beforeChange$: Observable<boolean>;
  @Input() disabled = false;
  @Output() valueChange = new EventEmitter<any>();
}

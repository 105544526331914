<ng-select
  #select
  [ngClass]="customClass"
  [labelForId]="textFieldId"
  [items]="options"
  [bindValue]="bindValue"
  [bindLabel]="bindLabel"
  [placeholder]="placeholder | translate"
  [multiple]="multiple"
  [disabled]="disabled"
  [(ngModel)]="value"
  [dropdownPosition]="dropdownPosition"
  [searchable]="searchable"
  [clearable]="clearable"
  [addTag]="addTagFn || null"
  [typeahead]="useTypeahead ? typeahead$ : null"
  [hideSelected]="hideSelected"
  [closeOnSelect]="closeOnSelect"
  [notFoundText]="notFoundText | translate"
  [typeToSearchText]="typeaheadText | translate"
  [addTagText]="addTagText | translate"
  [searchFn]="searchFn || null"
  [appendTo]="appendTo"
  [virtualScroll]="virtualScroll"
  (search)="onSearchChange($event)"
  (close)="onDropdownClose()"
  (open)="opened.emit()"
  (change)="onChange($event); selectionChange.emit($event)"
  (focus)="onTouched()"
  (click)="clicked.emit($event)">
    <ng-container *ngIf="labelTemplate">
        <ng-template ng-label-tmp let-item="item">
            <ng-template
              [ngTemplateOutlet]="labelTemplate"
              [ngTemplateOutletContext]="{ item: item }">
            </ng-template>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="optionsTemplate">
        <ng-template ng-option-tmp let-item="item">
            <ng-template
              [ngTemplateOutlet]="optionsTemplate"
              [ngTemplateOutletContext]="{ item: item }">
            </ng-template>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="footerTemplate">
        <ng-template ng-footer-tmp let-item="item">
            <ng-template
              [ngTemplateOutlet]="footerTemplate"
              [ngTemplateOutletContext]="{ searchResults: searchResults, select: select, addTagText: addTagText}">
            </ng-template>
        </ng-template>
    </ng-container>
</ng-select>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared/modules/base-component/base.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { tap } from 'rxjs/operators';
import { filterActionTypes } from '@shared/modules/filtering/actions/filter.action-types';

@Component({
  selector: 'app-abc-filtering',
  templateUrl: './abc-filtering.component.html',
  styleUrls: ['./abc-filtering.component.scss'],
})
export class AbcFilteringComponent extends BaseComponent implements OnInit {
  @Input() selectedLetter: string;
  @Input() firstLetters: string[];
  @Output() filterLetterChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private filteringService: FilteringService) {
    super();
  }

  ngOnInit() {
    this.listenFilterResetAction();
    this.listenSavedFilterChange();
    this.listenSelectedLetterChanges();
    return super.ngOnInit();
  }

  private listenSelectedLetterChanges() {
    this.filteringService.eventBus
      .on(filterActionTypes.setFirstLetter)
      .pipe(untilDestroyed(this))
      .subscribe((selectedLetter: string) => {
        this.selectedLetter = selectedLetter;
      });
  }

  private listenFilterResetAction(): void {
    this.filteringService
      .handleFiltersReset()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onButtonClick(null);
      });
  }

  private listenSavedFilterChange(): void {
    this.filteringService
      .getSavedFilter('letter_filter')
      .pipe(
        tap((letter: string | null) => {
          this.filterLetterChange.emit(letter);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onButtonClick(letter) {
    this.filterLetterChange.emit(letter);
  }
}

export class AppConfig {
  static loginUrl = '/auth-user';
  static partnersUrl = '/partners';
  static positionsUrl = '/positions';
  static candidatesUrl = '/candidates';

  static homeUrl = AppConfig.partnersUrl;

  static timeFormat = 'hh:mm:ss';

  static browsersNotSupported = {
    ie11: true,
    ie10: true,
    edge: false,
    safari: false,
  };

  static sessionStorage = {
    prevPartnerFilter: 'prevPartnerFilter',
    prevCandidateFilter: 'prevCandidateFilter',
    prevPositionFilter: 'prevPositionFilter',
    prevSelectedPartnerFilter: 'prevSelectedPartnerFilter',
    prevSelectedPositionFilter: 'prevSelectedPositionFilter',
    prevSelectedCandidateFilter: 'prevSelectedCandidateFilter',
  };

  static languages: string[] = ['hu', 'en'];
  static defaultLanguage: string = AppConfig.languages[0];
  static headerTitleTranslateMap = {
    [AppConfig.partnersUrl]: 'common.partner',
    [AppConfig.positionsUrl]: 'common.position',
    [AppConfig.candidatesUrl]: 'common.candidate',
  };
}

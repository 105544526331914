import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import values from 'lodash-es/values';

enum SuperRequired {
  ON_INIT = 'OnInit',
  ON_DESTROY = 'OnDestroy',
}

@UntilDestroy()
@Component({
  template: '',
})
export class CardFilteringBaseComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() filterKey: string;
  transformedData: any;
  @Input() set data(data: any) {
    this.transformedData = data;
  }
  @Output() filterValueChange = new EventEmitter<Record<string, any>>();
  @ViewChild('dropDown', { static: false }) dropDown;
  @ViewChild('filterTag', { static: false }) filterTag;
  checkBox = new FormControl();
  isOpen = false;
  isChecked = false;
  isDisabled = true;
  targetClassList = [
    'mat-calendar-body-selected',
    'mat-calendar-spacer',
    'mat-focus-indicator',
    'mat-calendar-arrow',
    'mat-button-wrapper',
    'mat-calendar-body-label',
    'mat-calendar',
    'mat-calendar-header',
    'mat-calendar-content',
    'mat-calendar-table',
    'mat-calendar',
  ];

  constructor(
    readonly eRef: ElementRef,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService
  ) {}

  ngOnInit(skip?: boolean): SuperRequired.ON_INIT {
    if (!skip) {
      // common onInit logic
    }
    return SuperRequired.ON_INIT;
  }

  ngOnDestroy(skip?: boolean): SuperRequired.ON_DESTROY {
    if (!skip) {
      // common onDestroy logic
    }
    return SuperRequired.ON_DESTROY;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (values(event.target.classList).length > 0) {
      if (
        typeof this.dropDown === 'undefined' &&
        !values(event.target.classList).some((c: string) => this.targetClassList.includes(c))
      ) {
        this.isOpen = !!this.eRef.nativeElement.contains(event.target);
      } else if (this.eRef.nativeElement.contains(event.target)) {
        this.isOpen = true;
      } else if (event.target.classList.contains('ng-option')) {
        this.isOpen = this.isOpen ?? this.isOpen;
      } else if (
        values(event.target.classList).some((c: string) => this.targetClassList.includes(c))
      ) {
        this.isOpen = this.isOpen ?? this.isOpen;
      } else {
        this.isOpen = false;
      }
    } else {
      this.isOpen = this.isOpen ?? this.isOpen;
    }
  }

  listenFilterResetAction(): Observable<any> {
    return this.filteringService.handleFiltersReset().pipe(untilDestroyed(this));
  }

  listenSavedFilterChange(filterKey: string): Observable<any> {
    return this.filteringService.getSavedFilter(filterKey).pipe(untilDestroyed(this));
  }
}

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IMatModal } from '@shared/modules/mat-modal/classes/IMatModal';
import { CustomModalConfig } from '@shared/modules/mat-modal/classes/CustomModalConfig';
import { ModalTypes } from '@shared/modules/mat-modal/classes/ModalTypes';
import { ModalFrameComponent } from '@shared/modules/mat-modal/components/modal-frame/modal-frame.component';
import { ModalState } from '@shared/modules/event-bus/state/modal/modal.state';
import { ConfirmModalComponent } from '@shared/modules/mat-modal/components/confirm-modal/confirm-modal.component';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { tap } from 'rxjs/operators';
import { DeviceService } from '../../services/device.service';

@Injectable({
  providedIn: 'root',
})
export class MatModalService {
  private _isModalOpened = false;

  constructor(
    public dialog: MatDialog,
    private deviceService: DeviceService,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private modalStateService: ModalStateService
  ) {}

  get isModalOpened(): boolean {
    return this._isModalOpened;
  }

  openDialog(customModalConfig: CustomModalConfig = null): Observable<any> {
    const modalConfig = this.getConfig(ModalFrameComponent, customModalConfig);
    const dialogRef = this.dialog.open(ModalFrameComponent, modalConfig);
    this._isModalOpened = true;
    return dialogRef.afterClosed().pipe(tap(() => (this._isModalOpened = false)));
  }

  private getConfig(modalComponent, customModalConfig: CustomModalConfig): MatDialogConfig {
    const componentFactory = this.cfr.resolveComponentFactory<IMatModal>(modalComponent);
    const instance = componentFactory.create(this.injector).instance;
    let curConfig = instance.modalConfig || {};

    const panelClasses = ['modal-reset'];

    if (customModalConfig) {
      if (customModalConfig.data?.variant !== ModalTypes.DynamicHeight) {
        panelClasses.push('reset-modal-border');
      }

      curConfig = {
        ...curConfig,
        ...customModalConfig,
        data: { ...curConfig.data, ...customModalConfig.data },
        panelClass: panelClasses,
      };
    }

    return curConfig;
  }

  openConfirmModal(modalState: Partial<ModalState>): Observable<MatDialogRef<any>> {
    this.modalStateService.setState({ ...modalState });

    return this.openDialog({
      width: '460px',
      data: {
        contentComponent: ConfirmModalComponent,
        variant: ModalTypes.DynamicHeight,
      },
    });
  }
}

import { getAllRoles, UserRole } from '@shared/modules/auth/classes/UserRole';

export const sideNavigationConfig = [
  {
    icon: 'assets/image/common/whc-icon.svg',
    navigateTo: null,
    roles: getAllRoles,
  },
  {
    icon: 'assets/image/common/partner-icon.svg',
    navigateTo: 'partners',
    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.FieldManager, UserRole.ProjectManager],
  },
  {
    icon: 'assets/image/common/position-icon.svg',
    navigateTo: 'positions',
    roles: getAllRoles,
  },
  {
    icon: 'assets/image/common/applicant-icon.svg',
    navigateTo: 'candidates',
    roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.FieldManager, UserRole.ProjectManager],
  },
];

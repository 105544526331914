import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@shared/services/app-state.service';
import { CandidateDocumentType } from '@pages/candidates/classes/CandidateDocumentType';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DocumentTypeNamePipe } from '@shared/modules/key-value-detail/pipes/document-type-name.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import { modalActionTypes } from '@shared/modules/mat-modal/actions/modal.action-types';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import candidateActions from '@pages/candidates/actions/candidate.actions';
import { keys } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppConstants } from '@config/app.constant';
import { isNonFalsyValue } from '@shared/utils/check-falsy-value.util';

@UntilDestroy()
@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  providers: [DocumentTypeNamePipe],
})
export class DocumentUploadComponent implements OnInit, OnDestroy {
  types$: Observable<CandidateDocumentType[]>;
  types: CandidateDocumentType[] = [];
  form: FormGroup;
  isExpiryDateFieldShown = true;
  isPositionSelectionFieldShown = false;
  documentTypesWithExpiryDate = AppConstants.documentTypesWithExpiryDate;
  today = new Date();
  documentTypeSelectedHint = '';

  constructor(
    private appService: AppStateService,
    private documentNamePipe: DocumentTypeNamePipe,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private modalStateService: ModalStateService,
    private candidateService: CandidatesService
  ) {}

  ngOnInit(): void {
    this.form = this.initForm();
    this.types$ = this.appService.getDocumentTypes().pipe(
      tap((types: CandidateDocumentType[]) => {
        this.types = types;
      }),
      map((types) => {
        return types.map((type) => {
          return {
            ...type,
            name: this.translateService.instant(this.documentNamePipe.transform(type.name)),
          };
        });
      })
    );

    this.listenFormChanges();
    this.listenSaveButtonClick();
  }

  ngOnDestroy() {
    this.modalStateService.resetModalStateOnDestroy();
    this.candidateService.resetPositionListStateInDocumentUploadModal();
  }

  private initForm(): FormGroup {
    return this.formBuilder.group({
      documentTypeId: [null, [Validators.required]],
      file: [null, [Validators.required]],
      expiryDate: [null, [Validators.required]],
    });
  }

  private listenFormChanges(): void {
    this.form
      .get('documentTypeId')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        const documentId = this.form.get('documentTypeId').value;
        const documentType = this.types.find((document) => document.id === documentId);

        if (documentType) {
          this.handleDocumentWithExpiryDate(documentType);
          this.handleDocumentWithCVType(documentType);
          this.handleDocumentWithPositionSelection(documentType);
        }
      });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.modalStateService.setState({
        isSaveButtonDisabled: this.form.invalid,
      });
    });
  }

  private listenSaveButtonClick(): void {
    this.modalStateService.eventBus
      .on(modalActionTypes.saveButtonClick)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const formData = new FormData();
        const controls = keys(this.form.getRawValue());
        controls.forEach((controlName: string) => {
          const value = this.form.get(controlName).value;
          if (isNonFalsyValue(value).allowFile().check()) {
            formData.append(controlName, this.form.get(controlName).value);
          }
        });
        this.candidateService.eventBus.dispatch(candidateActions.saveDocument(formData));
      });
  }

  private handleDocumentWithPositionSelection(documentType: CandidateDocumentType): void {
    this.form.removeControl('positionId');
    this.isPositionSelectionFieldShown = false;

    const isPositionRequiredTypeSelected = AppConstants.documentTypesWithPositionSelection.includes(
      documentType.name
    );

    if (isPositionRequiredTypeSelected) {
      const validatorMap = {
        [AppConstants.privacyAppStatementDocumentKey]: [Validators.required],
        [AppConstants.parentStatementDocumentKey]: [],
      };

      this.form.addControl('positionId', new FormControl(null, validatorMap[documentType.name]));
      this.form.get('positionId').markAsTouched();

      this.isPositionSelectionFieldShown = true;
    }
  }

  private handleDocumentWithExpiryDate(documentType: CandidateDocumentType): void {
    this.isExpiryDateFieldShown = this.documentTypesWithExpiryDate.indexOf(documentType.name) > -1;

    const expiryDateControl = this.form.get('expiryDate');
    expiryDateControl.setValidators(this.isExpiryDateFieldShown ? [Validators.required] : []);
    expiryDateControl.setValue(null);
    expiryDateControl.updateValueAndValidity();
  }

  private handleDocumentWithCVType(documentType: CandidateDocumentType): void {
    if (documentType?.name !== AppConstants.cvDocumentKey) {
      this.documentTypeSelectedHint = '';
      return;
    }

    this.documentTypeSelectedHint = 'candidates.cv_parse_hint';
  }
}

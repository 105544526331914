import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PartnersService } from '@pages/partners/services/partners.service';
import { Observable } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import partnerActions from '@pages/partners/actions/partner.actions';
import { AppStateService } from '@shared/services/app-state.service';

@Component({
  selector: 'app-partner-detail-header',
  templateUrl: './partner-detail-header.component.html',
  styleUrls: ['./partner-detail-header.component.scss'],
})
export class PartnerDetailHeaderComponent implements OnInit {
  partnerName$: Observable<string>;
  partnerLogoUrl$: Observable<string>;
  isPartnerImageShown = true;
  placeholder = 'assets/image/partner-placeholder.svg';

  constructor(
    private partnerService: PartnersService,
    private appService: AppStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.partnerName$ = this.partnerService.select('headerText').pipe(
      tap(() => {
        const { currentUrl } = this.appService.getStateSnapshot();
        this.isPartnerImageShown = currentUrl.indexOf('projects') === -1;
        this.cdr.detectChanges();
      })
    );
    this.partnerLogoUrl$ = this.partnerService.select('partnerDetail').pipe(pluck('logoThumb'));
  }

  onNavigation(): void {
    this.partnerService.eventBus.dispatch(partnerActions.navigateBack());
  }
}

import { ValidatorFn, Validators } from '@angular/forms';
import { AppConstants } from '@config/app.constant';

export function getBasicInputLengthValidators(isRequired = true): ValidatorFn[] {
  const validators = [
    Validators.minLength(AppConstants.minInputLength),
    Validators.maxLength(AppConstants.maxInputLength),
  ];

  return isRequired ? [Validators.required, ...validators] : validators;
}

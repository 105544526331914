import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ModalStateService } from '@shared/modules/mat-modal/services/modal-state.service';
import modalActions from '../../../actions/modal.actions';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<Component>;
  @Input() primaryButtonText: string;
  @Input() primaryButtonDisabled: boolean;
  @Input() hidePrimaryButton: boolean;
  @Input() secondaryButtonText: string;
  @Input() secondaryButtonDisabled: boolean;
  @Input() hideSecondaryButton: boolean;
  @Output() primaryButtonClicked = new EventEmitter();
  @Output() secondaryButtonClicked = new EventEmitter();
  buttonColor$: Observable<'basic' | 'warn'>;
  secondaryButtonAppear$: Observable<boolean>;

  constructor(private modalStateService: ModalStateService) {}

  ngOnInit() {
    this.buttonColor$ = this.modalStateService.select('approveButtonStyle');
    this.secondaryButtonAppear$ = this.modalStateService.select('cancelButtonAppear');
  }

  onPrimaryClicked() {
    if (this.primaryButtonClicked) {
      this.primaryButtonClicked.emit();
    }
  }

  onSecondaryClicked() {
    if (this.secondaryButtonClicked) {
      this.secondaryButtonClicked.emit();
    }

    if (this.dialogRef) {
      this.modalStateService.eventBus.dispatch(modalActions.dismissButtonClick());
      setTimeout(() => {
        this.dialogRef.close();
      }, 50);
    }
  }
}

import { SaveUsersFilter } from '@shared/classes/users/SaveUsersFilter';
import { GetUsersFilter } from '@shared/classes/users/GetUsersFilter';

export interface FilterManageState {
  filter: SaveUsersFilter;
  savedFilters: GetUsersFilter[];
  selectedPartnerFilter: GetUsersFilter;
  selectedCandidateFilter: GetUsersFilter;
  selectedPositionFilter: GetUsersFilter;
}

export const filterManageInitialState = {
  filter: undefined,
  savedFilters: undefined,
  selectedPartnerFilter: undefined,
  selectedCandidateFilter: undefined,
  selectedPositionFilter: undefined,
};

<div class="d-flex justify-content-center align-items-center">
    <app-svg-hint
        *ngIf="hintsCurrentlyShownMap.isInfoHintShown"
        [placement]="placement"
        filePath="assets/image/info-icon.svg"
        [hintText]="messageListTemplate">
    </app-svg-hint>

    <app-svg-hint
        *ngIf="hintsCurrentlyShownMap.isErrorHintShown"
        [placement]="placement"
        filePath="assets/image/alert-icon.svg"
        [hintText]="errors ? errorList : messageListTemplate">
    </app-svg-hint>

    <app-svg-hint
        *ngIf="hintsCurrentlyShownMap.isSuccessHintShown"
        [placement]="placement"
        filePath="assets/image/tick.svg"
        [hintText]="messageListTemplate">
    </app-svg-hint>

    <app-svg-hint
        *ngIf="hintsCurrentlyShownMap.isClockHintShown"
        [placement]="placement"
        filePath="assets/image/clock-icon.svg"
        [hintText]="messageListTemplate">
    </app-svg-hint>

    <app-image-hint
        *ngIf="hintsCurrentlyShownMap.isNoDataHintShown"
        [placement]="placement"
        filePath="assets/image/no-data.png"
        [hintText]="messageListTemplate">
    </app-image-hint>

    <app-image-hint
        *ngIf="hintsCurrentlyShownMap.isLimitedDataHintShown"
        [placement]="placement"
        filePath="assets/image/limited-data.png"
        [hintText]="messageListTemplate">
    </app-image-hint>

    <app-image-hint
        *ngIf="hintsCurrentlyShownMap.isTwoYearDataHintShown"
        [placement]="placement"
        filePath="assets/image/two-year-data.png"
        [hintText]="messageListTemplate">
    </app-image-hint>

    <app-image-hint
        *ngIf="hintsCurrentlyShownMap.isDeleteInProgressHintShown"
        [placement]="placement"
        filePath="assets/image/delete-in-progress.png"
        [hintText]="messageListTemplate">
    </app-image-hint>
</div>

<ng-template #errorList>
    <app-hint-error-list [errors]="errors"></app-hint-error-list>
</ng-template>

<ng-template #messageListTemplate>
    <ng-container [ngSwitch]="hintTemplateType">
        <ng-container *ngSwitchCase="hintTemplateTypes.TextList">
            <div *ngFor="let message of hintText">
                {{ message | translate }}
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="hintTemplateTypes.Text">
            {{ hintText | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="hintTemplateTypes.Template">
            <ng-container *ngTemplateOutlet="hintText"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>

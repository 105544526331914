import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/table/classes/ICustomCellComponent';

@Component({
  selector: 'app-icon-table-cell',
  templateUrl: './icon-table-cell.component.html',
  styleUrls: ['./icon-table-cell.component.scss'],
})
export class IconTableCellComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<any>;
}

<app-form-field>
    <app-datepicker
      [isHintShown]="true"
      [clearable]="!isEditMode"
      [label]="minDateLabel"
      [maxDate]="maxDate"
      [formControl]="minDateControl">
    </app-datepicker>
</app-form-field>

<app-form-field>
    <app-datepicker
      [isHintShown]="true"
      [clearable]="!isEditMode"
      [label]="maxDateLabel"
      [minDate]="minDate"
      [formControl]="maxDateControl">
    </app-datepicker>
</app-form-field>

import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getBasicInputLengthValidators } from '@shared/utils/form/form-input-length-validator.util';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { Language } from '@pages/candidates/classes/Language';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';
import { Address } from '@shared/classes/Address';
import { CandidateParent } from '@pages/candidates/classes/CandidateParent';
import { UserApiService } from '@pages/users/services/user-api.service';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { CandidateBulkUpdate } from '@pages/candidates/classes/CandidateBulkUpdate';
import { atLeastOneArrayItem } from '@shared/utils/at-least-one-array-item.util';
import { JobType } from '@pages/candidates/classes/JobType';

@Injectable({
  providedIn: 'root',
})
export class CandidateFormService {
  constructor(
    public readonly atsConfigService: ATSConfigService,
    private formBuilder: FormBuilder,
    private userService: UserApiService
  ) {}

  initCandidateForm(detail?: CandidateDetail): FormGroup {
    const emailAsyncValidators = !detail
      ? [FormCustomValidators.emailExistsAsyncValidator(this.userService)]
      : [];

    const group = this.formBuilder.group({
      id: [detail?.id || null],
      firstName: [detail?.firstName || null, [...getBasicInputLengthValidators()]],
      lastName: [detail?.lastName || null, [...getBasicInputLengthValidators()]],
      email: [
        { value: detail?.email || null, disabled: !!detail },
        [...getBasicInputLengthValidators(), FormCustomValidators.emailValidator()],
        emailAsyncValidators,
      ],
      phone: [
        detail?.phone || null,
        [Validators.required, FormCustomValidators.phoneNumberValidator(true)],
      ],
      needSendTermsNotification: [
        {
          value: this.getTermsAndConditionsNotificationValue(detail),
          disabled: !!detail,
        },
        [Validators.required],
      ],
      preferredLanguageId: [detail?.preferredLanguage?.id || null, [Validators.required]],
      cityPreferences: this.getIdArray(detail?.cityPreferences, false),
      jobPreferences: this.getJobPreferenceArray(detail),
      jobTypePreferences: this.getJobTypePreferencesArray(detail?.jobTypePreferences),
      languages: this.getLanguagesArray(detail?.languages),
      profile: this.getProfileGroup(detail),
    });

    if (this.atsConfigService.isLaborHireATS) {
      group.addControl('skills', this.getTagArray(detail?.skills));
      group.addControl('shifts', this.getTagArray(detail?.shifts));
    }

    return group;
  }

  initBulkEditCandidateForm(bulkEditState: CandidateBulkUpdate): FormGroup {
    return this.formBuilder.group({
      membershipEndDate: [bulkEditState?.membershipEndDate?.name || null],
      statusId: [bulkEditState?.statusId || null, []],
      statusOptionId: [bulkEditState?.statusOptionId || null],
      positionId: [bulkEditState?.positionId || null, []],
    });
  }

  initBulkEditCandidateFormForLaborHire(bulkEditState: CandidateBulkUpdate): FormGroup {
    return this.formBuilder.group({
      statusId: [bulkEditState?.statusId || null, [Validators.required]],
      statusOptionId: [bulkEditState?.statusOptionId || null],
      positionId: [bulkEditState?.positionId || null, [Validators.required]],
      membershipStartDate: [
        bulkEditState?.membershipStartDate?.name || null,
        bulkEditState?.membershipStartDate?.name ? [Validators.required] : null,
      ],
      feorNumber: [
        bulkEditState?.feorNumber?.name || null,
        bulkEditState?.feorNumber?.name
          ? [Validators.required, FormCustomValidators.feorNumberValidator()]
          : null,
      ],
      subPositionId: [
        bulkEditState?.subPositionId || null,
        bulkEditState?.subPositionId ? [Validators.required] : null,
      ],
    });
  }

  getLanguagesGroup(
    language?: { language: Language; languageLevel: LanguageLevel },
    isRequired = false
  ): FormGroup {
    const validators = isRequired ? [Validators.required] : [];

    return this.formBuilder.group({
      languageId: [language?.language?.id || null, validators],
      languageLevelId: [language?.languageLevel?.id || null, validators],
    });
  }

  getParentGroup(parent?: CandidateParent): FormGroup {
    let baseValidators = [];
    let emailValidators = [];
    if (this.atsConfigService.isStudentATS) {
      baseValidators = getBasicInputLengthValidators();
      emailValidators = [...baseValidators, FormCustomValidators.emailValidator()];
    }

    return this.formBuilder.group({
      firstName: [parent?.firstName || null, baseValidators],
      lastName: [parent?.lastName || null, baseValidators],
      email: [parent?.email || null, emailValidators],
    });
  }

  private getJobPreferenceArray(detail?: CandidateDetail): FormArray {
    const jobPreferences =
      Array.isArray(detail?.jobPreferences) && detail.jobPreferences.length > 0
        ? detail.jobPreferences
        : [];

    return this.formBuilder.array(jobPreferences.map((tag) => this.getTagGroup(tag)));
  }

  private getProfileGroup(detail?: CandidateDetail): FormGroup {
    const identifierValidators = [];
    const isPensionerValidators = this.atsConfigService.isPensionerATS ? [Validators.required] : [];
    const birthDateValidators = this.atsConfigService.isStudentATS ? [Validators.required] : [];

    if (this.atsConfigService.isStudentATS) {
      identifierValidators.push(FormCustomValidators.omIdentifierValidator(false));
    } else if (this.atsConfigService.isPensionerATS) {
      identifierValidators.push(FormCustomValidators.pensionerIdentifierValidator(false));
    }

    const group = this.formBuilder.group({
      id: [detail?.profile?.id || null],
      birthDate: [detail?.profile?.birthDate || '', birthDateValidators],
      providerType: this.getProviderGroup(detail),
      parents: this.getParentsArray(detail?.profile?.parents),
      address: this.getAddressGroup(detail?.profile?.address),
      identifier: [detail?.profile?.identifier, identifierValidators],
      membershipStartDate: [
        {
          value: detail?.profile?.membershipStartDate,
          disabled: this.atsConfigService.isLaborHireATS,
        },
      ],
      membershipEndDate: [
        {
          value: detail?.profile?.membershipEndDate,
          disabled: this.atsConfigService.isLaborHireATS,
        },
      ],
    });

    if (this.atsConfigService.isLaborHireATS) {
      [
        { value: detail?.profile?.educationType?.id, controlName: 'educationTypeId' },
        { value: detail?.profile?.requiresAccommodation, controlName: 'requiresAccommodation' },
        { value: detail?.profile?.workExperience?.id, controlName: 'workExperienceId' },
      ].forEach(({ controlName, value }) =>
        group.addControl(controlName, new FormControl(value ?? null))
      );
      group.removeControl('identifier');
    }

    if (this.atsConfigService.isPensionerATS) {
      group.addControl(
        'isPensioner',
        new FormControl(detail?.profile?.isPensioner ?? false, isPensionerValidators)
      );
    }

    return group;
  }

  getProviderGroup(detail: CandidateDetail): FormGroup {
    const group = this.formBuilder.group({
      id: [detail?.profile?.providerType?.id || null, [Validators.required]],
      name: [detail?.profile?.providerType?.name || null, [Validators.required]],
    });

    if (detail) {
      group.disable();
    }

    return group;
  }

  private getTagArray(detailArray: Partial<{ id: number | string; name: string }>[]): FormArray {
    return this.formBuilder.array(
      detailArray ? detailArray.filter((tag) => !!tag).map((tag) => this.getTagGroup(tag)) : []
    );
  }

  private getTagGroup(tag: Partial<{ id: number | string; name: string }>): FormGroup {
    return this.formBuilder.group({
      id: [tag?.id || null, [Validators.required]],
      name: [tag?.name || null, [Validators.required]],
    });
  }

  private getParentsArray(parents?: CandidateParent[]): FormArray {
    const parentsArray = Array.isArray(parents) && parents.length > 0 ? parents : [null];
    return this.formBuilder.array(
      parentsArray.map((parent) => {
        return this.getParentGroup(parent);
      })
    );
  }

  private getAddressGroup(address?: Address): FormGroup {
    return this.formBuilder.group({
      id: [address?.id || null],
      cityId: [address?.city?.id || null, [Validators.required]],
      street: [address?.street || '', getBasicInputLengthValidators(false)],
    });
  }

  private getJobTypePreferencesArray(
    jobTypeArray: {
      type: JobType;
      subType: JobType;
    }[]
  ): FormArray {
    const jobTypes = atLeastOneArrayItem(jobTypeArray);

    return this.formBuilder.array(
      jobTypes.map((jobType) => {
        return this.formBuilder.group({
          typeId: [jobType?.type?.id || null],
          subTypeId: [jobType?.subType?.id || null],
        });
      })
    );
  }

  private getIdArray(array: Partial<{ id: number }>[], isRequired = true): FormArray {
    const dataArray = atLeastOneArrayItem<Partial<{ id: number }>>(array);

    const validators = isRequired ? [Validators.required] : [];
    return this.formBuilder.array(
      dataArray.map((data) => {
        return this.formBuilder.group({
          id: [data?.id || null, validators],
        });
      })
    );
  }

  private getLanguagesArray(
    languages: {
      language: Language;
      languageLevel: LanguageLevel;
    }[]
  ): FormArray {
    const languagesArray = atLeastOneArrayItem(languages);

    return this.formBuilder.array(
      languagesArray.map((language) => this.getLanguagesGroup(language))
    );
  }

  private getTermsAndConditionsNotificationValue(detail: CandidateDetail): boolean | null {
    if (!detail) {
      return null;
    }

    return typeof detail.needSendTermsNotification === 'boolean'
      ? detail.needSendTermsNotification
      : false;
  }
}

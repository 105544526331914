<div class="finalized-bulk-edit-content">
    <div class="mb-30px">
        <p class="basic-black-normal-text mb-0">{{'common.successfully_modified' | translate}}</p>
        <p
          class="basic-green-normal-text">{{(content$ | async).successful}} {{(content$ | async).type | translate}}</p>
        <p class="basic-black-normal-text mb-0">{{'common.unsuccessfully_modified' | translate}}</p>
        <p
          class="basic-red-normal-text">{{(content$ | async).unsuccessful}} {{(content$ | async).type | translate}}</p>
    </div>
    <hr>
</div>

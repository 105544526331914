import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilteringService } from '@shared/modules/filtering/services/filtering.service';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { CandidateState } from '@shared/modules/event-bus/state/candidates/candidate.state';
import {
  CityDistanceFilter,
  CityDistanceFilterFormGroupObj,
} from '@shared/modules/filtering/classes/CityDistanceFilter';

@UntilDestroy()
@Component({
  selector: 'app-distance-range-filter',
  templateUrl: './distance-range-filter.component.html',
  styleUrls: ['./distance-range-filter.component.scss'],
})
export class DistanceRangeFilterComponent implements OnInit {
  @Input() distanceRangeGroup: FormGroup;
  @Input() isOpen: boolean;
  @Input() valueEmitter: EventEmitter<void>;
  @Input() filterKey: string;
  @Input() data: any[];
  @Output() resetCity = new EventEmitter<void>();
  @Output() isChecked = new EventEmitter<boolean>();
  kmValueTagText: string;

  constructor(
    private filterService: FilteringService,
    private candidateFilterService: CandidatesService
  ) {}

  ngOnInit(): void {
    const {
      intValue,
      id,
    } = this.distanceRangeGroup.getRawValue() as CityDistanceFilterFormGroupObj;
    this.kmValueTagText = this.getTagText(intValue);
    this.handleKMRangeInputStates(id);

    this.distanceRangeGroup
      .get('id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((cityId: number) => {
        this.distanceRangeGroup.get('intValue').setValue('');
        this.handleKMRangeInputStates(cityId);
      });

    this.distanceRangeGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: CityDistanceFilterFormGroupObj) => {
        this.kmValueTagText = this.getTagText(value.intValue);
        this.valueEmitter.emit();
      });

    this.filterService
      .getSavedFilter(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((cityDistanceObj: CityDistanceFilter) => {
        if (typeof cityDistanceObj !== 'undefined') {
          const cityName = this.data.find((city) => city.id === cityDistanceObj.id)?.name;
          const cityId = cityDistanceObj?.id;
          const kmValue = cityDistanceObj?.intValue;
          this.isChecked.emit(cityId > 0 && kmValue > 0);
          this.distanceRangeGroup.setValue({ name: cityName, id: cityId, intValue: kmValue });
          this.distanceRangeGroup.get('intValue').markAsDirty();
          this.candidateFilterService.setState({
            ...this.getStateWithCityDistanceObj(cityDistanceObj),
          });
        }
      });

    this.filterService
      .handleFiltersReset()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.candidateFilterService.setState({
          ...this.getStateWithCityDistanceObj(undefined),
        });
        this.distanceRangeGroup.reset({ name: null, id: null, intValue: null });
        this.isChecked.emit(false);
      });
  }

  onResetKMValue(): void {
    this.distanceRangeGroup.get('intValue').setValue('');
  }

  private getTagText(intValue: string | number): string {
    return intValue ? `${intValue} km` : '';
  }

  private getStateWithCityDistanceObj(
    cityDistanceObj: CityDistanceFilter
  ): Partial<CandidateState> {
    const currentState = this.candidateFilterService.getStateSnapshot().candidateFilters;

    return {
      ...this.candidateFilterService.getStateSnapshot(),
      candidateFilters: { ...currentState, city_distance: cityDistanceObj },
    };
  }

  private handleKMRangeInputStates(cityId: number): void {
    const intValueControl = this.distanceRangeGroup.get('intValue');

    if (cityId) {
      intValueControl.enable();
      return;
    }

    intValueControl.disable();
  }
}
